/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_SHIPMENTS_API_GET_SHIPMENTS = gql`
  query GetCoproUsShipmentsApiDataQuery(
    $offset: Int
    $pageSize: Int
    $dateRange: [String]
    $filtersList: [BioLcCoproUsShipmentsApiDataRequestShipmentFiltersList]
    $historical: Boolean
    $discarded: Boolean
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "get_shipments"
        offset: $offset
        page_size: $pageSize
        shipment_filters: {
          date_range: $dateRange
          filters_list: $filtersList
          historical: $historical
          discarded: $discarded
        }
      }
    ) {
      body {
        shipments {
          parts {
            batch_id
            batch_allocation_id
            batch_shipment_type
            total_actual_volume
            total_allocated_volume
            allocated_shipment_id
            renewable_shipment_flag
            credits_qualified
            sub_type
            notes
            shipment_status
            shipment_start_date
            shipment_end_date
            destination_batch_id
            batch_committed
          }
          shipment_id
          shipment_date
          total_estimated_volume
          shipment_type
          parcel_grade
          days_to_delete
        }
      }
      statusCode
      error
    }
  }
`;

// Story 399833 / Sprint 66 - Comment out emts and state statuses until further notice
export const COPRO_US_SHIPMENTS_API_GET_BATCHES = gql`
  query GetCoproUsShipmentsApiDataQuery(
    $offset: Int
    $pageSize: Int
    $tankCertifiedDateRange: [String]
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "get_batches"
        offset: $offset
        page_size: $pageSize
        date_range: $tankCertifiedDateRange
      }
    ) {
      body {
        batches {
          actual_ending_inventory
          batch_id
          batch_status
          estimated_ending_inventory
          remaining_volume
          total_volume
          tank_number
          missing_c14
          shipments {
            shipment_id
            shipment_volume
            batch_allocation_id
            shipment_status
            shipment_date
          }
          transfers {
            shipment_name
            shipment_volume
            shipment_start_date
            source_batch_code
          }
          tank_certified_date
          details {
            batch_created_status
            shipment_linked_status
            c14_linked_status
            feedstock_allocated_status
            # emts_submitted_status
            # state_submitted_status
            # state_final_uploaded_status
            completion_percentage
          }
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_GET_BOL_DATA = gql`
  query GetCoproUsShipmentsBolApiDataQuery {
    bioLcCoproUsShipmentsApi(event: {op: "get_bols"}) {
      statusCode
      error
      body {
        bols {
          dtn_bol_layer_id
          start_load_date
          sap_material_name
          sap_material_id
          truck_lifting_volume
          truck_lifting_volume_gal
          credits_qualified
          outgoing_bol_number
        }
      }
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SPLIT_BOL = gql`
  query GetCoproUsShipmentsSplitBolApiDataQuery(
    $request: BioLcCoproUsShipmentsApiDataRequestSplitBol
  ) {
    bioLcCoproUsShipmentsApi(event: {op: "split_bol", split_bol: $request}) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_ALLOCATE_BOL = gql`
  query GetCoproUsShipmentsAllocateBolsApiDataQuery(
    $request: BioLcCoproUsShipmentsApiDataRequestAllocateBol
  ) {
    bioLcCoproUsShipmentsApi(
      event: {op: "allocate_bol", allocate_bol: $request}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SAVE_DTN = gql`
  query SaveDTNShipment(
    $dtn_shipment_details: BioLcCoproUsShipmentsApiDataRequestSavedDTNShipment
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "save_dtn_shipment"
        dtn_shipment_details: $dtn_shipment_details
      }
    ) {
      error
      statusCode
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsCheckShipmentIdApiDataQuery(
    $shipmentId: String
    $batchId: String
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "check_shipment_id"
        shipment_id: $shipmentId
        batch_id: $batchId
      }
    ) {
      statusCode
      error
      body {
        shipment_volume
      }
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_GET_PREVIOUS_DTN_BTU_IDS = gql`
  query GetCoproUsShipmentsApiGetPreviousDtnBtuIdsDataQuery {
    bioLcCoproUsShipmentsApi(event: {op: "get_previous_dtn_btu_ids"}) {
      statusCode
      error
      body {
        previous_dtn_btu_ids {
          shipment_type
          previous_id
        }
      }
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID = gql`
  query GetCoproUsShipmentsCheckBatchIdApiDataQuery($batchId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "check_batch_id", batch_id: $batchId}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsShipmentIdApiDataQuery(
    $shipmentId: String
    $update_field: BioLcCoproUsShipmentsApiDataRequestUpdateField
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "edit_shipment_field"
        shipment_id: $shipmentId
        update_field: $update_field
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_EDIT_MULTI_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsShipmentIdApiDataQuery(
    $shipmentId: String
    $update_field_list: BioLcCoproUsShipmentsApiDataRequestUpdateFieldMulti
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "edit_shipment_field_multi"
        shipment_id: $shipmentId
        update_field_list: $update_field_list
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_SHIPMENT_ID = gql`
  query GetCoproUsShipmentsShipmentIdApiDataQuery($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "get_shipment_details", shipment_id: $shipmentId}
    ) {
      body {
        shipment_details {
          shipment_id
          shipment_date
          total_estimated_volume
          shipment_type
          destination
          source_tank
          parts {
            batch_shipment_type
            batch_id
            total_actual_volume
            total_allocated_volume
            allocated_shipment_id
            sub_type
            credits_qualified
            renewable_shipment_flag
            notes
            shipment_status
            shipment_start_date
            shipment_end_date
          }
        }
      }
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_DELETE_SHIPMENT = gql`
  query CoproUsShipmentsDeleteShipmentApiDataQuery($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "delete_shipment"
        delete_shipment: {dtn_shipment_id: $shipmentId}
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_ALLOCATE_BATCHES_AND_SHIPMENTS = gql`
  query CoproUsShipmentsApiAllocateMutation(
    $batches_list: [BatchesUsAllocationDetails]
  ) {
    bioLcCoproUsShipmentsApi(
      event: {op: "allocate_batches_and_shipments", batches_list: $batches_list}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT = gql`
  query CoproUsShipmentsAllocateBatchAllocation(
    $batch_shipment_info: [BioLcCoproUsShipmentsApiDataRequestBatchShipmentInfo]
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "allocate_batch_to_shipment"
        batch_shipment_info: $batch_shipment_info
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_RESET_ALLOCATE_BOL = gql`
  query CoproUsShipmentsApiResetAllocateBol($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "reset_allocate_bol"
        reset_allocate_bol: {dtn_shipment_id: $shipmentId}
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS = gql`
  query CoproUsShipmentsApiCheckShipmentIdExists($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "check_shipment_id_exists", dtn_shipment_id: $shipmentId}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_REMOVE_SPLIT = gql`
  query CoproUsShipmentsRemoveSplitApiDataQuery($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "remove_split_shipment"
        remove_split_shipment: {shipment_id: $shipmentId}
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_BATCH_LINKED_SHIPMENTS = gql`
  query GetCoproBatchDetailsShipments($batchId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "get_batch_shipments", batch_id: $batchId}
    ) {
      body {
        batch_shipments {
          shipment_id
          shipment_type
          shipment_date
          shipment_volume
        }
      }
      error
    }
  }
`;

export const COPRO_US_SHIPMENTS_API_UNLINK_SHIPMENT = gql`
  query RemoveBatchAllocations($allocation_ids: [String]) {
    bioLcCoproUsShipmentsApi(
      event: {op: "remove_allocations", allocation_ids: $allocation_ids}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_GET_WATERFALL_BATCHES = gql`
  query GetWaterfallBatches($offset: Int) {
    bioLcCoproUsShipmentsApi(
      event: {op: "get_waterfall_batches", offset: $offset}
    ) {
      statusCode
      body {
        waterfall_batches {
          batch_id
          remaining_volume
          tank_certified_date
        }
      }
    }
  }
`;

export const COPRO_US_GET_SELECTED_WATERFALL_BATCHES = gql`
  query GetSelectedBatches($batch_ids: [String]) {
    bioLcCoproUsShipmentsApi(
      event: {op: "fetch_batches", batch_ids: $batch_ids}
    ) {
      body {
        batches {
          batch_id
          tank_number
          remaining_volume
          total_volume
          actual_ending_inventory
          shipments {
            parent_shipment_id
            shipment_id
            shipment_volume
            shipment_type
            shipment_status
            estimated_volume
            total_allocated_volume
            notes
            shipment_date
          }
          details {
            completion_percentage
          }
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_GET_SHIPMENT_BOLS = gql`
  query GetShipmentBOLs($shipmentIds: [String]) {
    bioLcCoproUsShipmentsApi(
      event: {op: "fetch_shipment_bols", shipment_ids: $shipmentIds}
    ) {
      body {
        shipment_bols {
          shipment_id
          bols {
            dtn_bol_layer_id
            allocated_bol_out_qty
            outgoing_bol_number
            truck_lifting_volume
            credits_qualified
            sap_material_name
            sap_material_id
            start_load_date
          }
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_GET_WATERFALL_TRUCK_RACK_SHIPMENTS = gql`
  query GetWaterfallTruckrack($dtnShipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "get_waterfall_truck_rack_shipments"
        dtn_shipment_id: $dtnShipmentId
      }
    ) {
      body {
        shipments {
          shipment_id
          shipment_date
          total_estimated_volume
          shipment_type
          parts {
            shipment_start_date
            shipment_end_date
            batch_id
            batch_allocation_id
            batch_shipment_type
            allocated_shipment_id
            total_actual_volume
            total_allocated_volume
          }
        }
      }
      statusCode
      error
    }
  }
`;

export const COPRO_US_RESET_TRUCK_RACK_ALLOCATION = gql`
  query CoproUsShipmentsResetTruckRackAllocation(
    $allocationInfo: [BioLcCoproUsShipmentsApiDataRequestAllocationInfo]
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "reset_truck_rack_allocation"
        allocation_info: $allocationInfo
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_COMMIT_ALLOCATION = gql`
  query CoproUsShipmentsCommitAllocation(
    $allocation_ids: [String]
    $is_batch_update: Boolean
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "update_shipments_committed_status"
        allocation_ids: $allocation_ids
        is_batch_update: $is_batch_update
      }
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_RECALL_COMMITTED = gql`
  query CoproUsShipmentsRecallCommitted($batchId: [String]) {
    bioLcCoproUsShipmentsApi(
      event: {op: "recall_committed", batch_ids: $batchId}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_DELETE_DISCARDED = gql`
  query CoproUsShipmentsDeleteDiscarded($shipment_id: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "delete_discarded_shipment", shipment_id: $shipment_id}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_RETURN_DISCARDED = gql`
  query CoproUsShipmentsReturnDiscarded($shipment_id: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "return_shipment_to_active", shipment_id: $shipment_id}
    ) {
      statusCode
      error
    }
  }
`;

export const COPRO_US_DISCARD_SHIPMENT = gql`
  query CoproUsDiscardShipment($shipmentId: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "discard_shipment", shipment_id: $shipmentId}
    ) {
      statusCode
      error
    }
  }
`;

export const GET_BATCH_ID_BY_TANK = gql`
  query GetBatchIDByTank($tankNumber: String) {
    bioLcCoproUsShipmentsApi(
      event: {op: "get_batches_for_tank", tank_number: $tankNumber}
    ) {
      body {
        tank_batch_info {
          batch_code
          batch_resource_id
          batch_status
        }
      }
      statusCode
      error
    }
  }
`;

export const GENERATE_SHIPMENT_ID = gql`
  query GenerateShipmentID(
    $sourceResourceBatchId: String
    $destinationResourceBatchId: String
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "get_transfer_shipment_name"
        batch_id: $sourceResourceBatchId
        destination_batch_id: $destinationResourceBatchId
      }
    ) {
      body {
        transfer_shipment_name
      }
      statusCode
      error
    }
  }
`;

export const SAVE_INVENTORY_TRANSFER = gql`
  query SaveInventoryTransfer(
    $inventory_transfer_details: BioLcCoproUsShipmentsApiDataRequestInventoryTransfer
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "save_inventory_transfer_shipment"
        inventory_transfer_details: $inventory_transfer_details
      }
    ) {
      body {
        saved_inventory_transfer {
          batch_id
        }
      }
      statusCode
      error
    }
  }
`;

export const VALIDATE_INVENTORY_TRANSFER_VOLUME = gql`
  query ValidateInventoryTransferVolume(
    $volume_to_validate: Float
    $batch_id: String
  ) {
    bioLcCoproUsShipmentsApi(
      event: {
        op: "validate_inventory_transfer_volume"
        volume_to_validate: $volume_to_validate
        batch_id: $batch_id
      }
    ) {
      statusCode
      error
    }
  }
`;
