import moment from "moment";
import {useMemo} from "react";
import {Datepicker} from "@bphxd/ds-core-react";
import {
  useDateRange,
  INITIAL_RANGE,
} from "modules/co-processing/context/DateRangeContext";
import StickyHeader from "./StickyHeader";

const formatDateToDisplay = (date) =>
  moment(date, "YYYY-MM-DD").format("MMM D, YYYY");

const mapRangeToDateDisplayed = (dateRange) => {
  if (dateRange.from === dateRange.to) {
    return `${formatDateToDisplay(dateRange.from)}`;
  }

  return Object.values(dateRange).map(formatDateToDisplay).join(" thru ");
};

const StickyDatePicker = () => {
  const {dateRange, dateChangeHandler} = useDateRange();

  const effectiveDateRange =
    dateRange?.from && dateRange?.to ? dateRange : INITIAL_RANGE;

  const options = useMemo(
    () => ({
      allowInput: false,
      altInput: true,
      allowInvalidPreload: false,
      altFormat: "M j, Y", // M Textual three letters Jan–Dec | j Numeric day, w/o leading zeros 1–31
      dateFormat: "Y-n-j",
      defaultDate: [effectiveDateRange.from, effectiveDateRange.to],
      enableTime: false,
      onChange: (selectedDates, dateStr) =>
        selectedDates?.length === 2 ? dateChangeHandler(selectedDates) : null,
      onClose: (selectedDates, dateStr, instance) => {
        // restricts user from unselecting dates
        if (selectedDates?.length === 1) {
          instance.setDate([effectiveDateRange.from, effectiveDateRange.to]);
          return dateChangeHandler([
            effectiveDateRange.from,
            effectiveDateRange.to,
          ]);
        }
        return null;
      },
      mode: "range",
    }),
    [effectiveDateRange, dateChangeHandler],
  );

  return (
    <StickyHeader>
      <div className="col-span-full h-14 receipts-header__dateRange">
        <div className="receipts-header__datePicker">
          <Datepicker
            className="receipts-header__dateInput hover:cursor-pointer focus:text-white focus:border-none"
            options={options}
            name="Datepicker"
            data-test="sticky-date-picker"
          />
        </div>
      </div>
    </StickyHeader>
  );
};

export default StickyDatePicker;
