import * as DATEFORMATS from "constants/dateformats";
import moment from "moment";

const getCurrentDate = () => {
  const dd = String(new Date().getDate()).padStart(2, "0");
  const mm = String(new Date().getMonth() + 1).padStart(2, "0"); // January is 0
  const yyyy = new Date().getFullYear();

  const today = yyyy + "/" + mm + "/" + dd;
  return moment(today, DATEFORMATS.DATE_FORMAT_YYYYMMDD_SLASH);
};

const convertDateFormat_YYYY_MM_DD = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_YYYYMMDD_SLASH,
  );
  return formattedDate;
};

const convertDateTimeFormat_MM_DD_YYYY_hh_mm_A = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
  );
  return formattedDate;
};

const convertDateFormat_MM_DD_YYYY = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
  );
  return formattedDate;
};

const isDateMoreThanOneYear = (date) => {
  const currentDate = moment().add(-1, "M");
  const oldDate = moment(date);

  const diff = currentDate.diff(oldDate, "months", true);
  return diff > 12 || diff < 0;
};

const convertDateFormattoUser = (dateFormat) => {
  const dateFormatMap = {
    "MM/DD/YYYY": "m/d/Y",
    "DD/MM/YYYY": "d/m/Y",
    "YYYY/MM/DD": "Y/m/d",
  };
  return dateFormatMap[dateFormat] ?? dateFormat;
};

export const formatDatePickerDate = (date) =>
  moment(date).format("YYYY-MM-DD HH:MM:SS+HH:MM");

export const parseDateString = (dateStr) => {
  if (!dateStr) return null;
  const cleanStr = dateStr.toLowerCase().trim();
  const monthMap = {
    january: 0,
    jan: 0,
    "01": 0,
    1: 0,
    february: 1,
    feb: 1,
    "02": 1,
    2: 1,
    march: 2,
    mar: 2,
    "03": 2,
    3: 2,
    april: 3,
    apr: 3,
    "04": 3,
    4: 3,
    may: 4,
    "05": 4,
    5: 4,
    june: 5,
    jun: 5,
    "06": 5,
    6: 5,
    july: 6,
    jul: 6,
    "07": 6,
    7: 6,
    august: 7,
    aug: 7,
    "08": 7,
    8: 7,
    september: 8,
    sep: 8,
    sept: 8,
    "09": 8,
    9: 8,
    october: 9,
    oct: 9,
    10: 9,
    november: 10,
    nov: 10,
    11: 10,
    december: 11,
    dec: 11,
    12: 11,
  };

  try {
    let day;
    let month;
    let year;
    const parts = cleanStr.split(/[-\s/]+/);
    if (parts.length === 2) {
      if (parts[0].toLowerCase().startsWith("q")) {
        const quarter = parseInt(parts[0].substring(1), 10);
        year = parseInt(parts[1], 10);
        if (
          !Number.isNaN(quarter) &&
          !Number.isNaN(year) &&
          quarter >= 1 &&
          quarter <= 4
        ) {
          const quarterMonths = {
            1: "january",
            2: "april",
            3: "july",
            4: "october",
          };
          month = monthMap[quarterMonths[quarter]];
          day = 1;
        }
      } else {
        const potentialMonth = parts[0];
        const potentialYear = parseInt(parts[1], 10);
        if (
          Object.hasOwn(monthMap, potentialMonth) &&
          !Number.isNaN(potentialYear)
        ) {
          month = monthMap[potentialMonth];
          year = potentialYear;
          day = 1;
        }
      }
    } else if (parts.length === 3) {
      const [first, second, third] = parts;
      if (!Number.isNaN(parseInt(first, 10))) {
        day = parseInt(first, 10);
        month = monthMap[second];
        year = parseInt(third, 10);
      } else {
        month = monthMap[first];
        day = parseInt(second, 10);
        year = parseInt(third, 10);
      }
    }
    if (month === undefined || year === undefined || Number.isNaN(year)) {
      throw new Error("Invalid date format");
    }
    day = day || 1;
    if (year < 100) {
      year += 2000;
    }
    const date = new Date(year, month, day);
    if (Number.isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    return date;
  } catch (error) {
    return null;
  }
};

export default {
  isDateMoreThanOneYear,
  getCurrentDate,
  convertDateFormat_YYYY_MM_DD,
  convertDateFormat_MM_DD_YYYY,
  convertDateTimeFormat_MM_DD_YYYY_hh_mm_A,
  convertDateFormattoUser,
  formatDatePickerDate,
};
