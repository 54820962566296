import {gql} from "@apollo/client";

export const ALLOCATION_OUTGOING_FETCH_API = gql`
  query bioLcGetAllocationSalesApi(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetAllocationSalesApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      documents {
        deliveryDate
        outboundType
        recipient
        sustainableQtyM3
        fuelQtyM3
        shipToLocation
        dispatchLocation
      }
    }
  }
`;

export default ALLOCATION_OUTGOING_FETCH_API;
