import {DIV_CODE_COPRO, DIV_CODE_GF} from "constants/divisionDetails";
import getColumnsLtpCopro from "./getColumnsLtpCopro";
import getColumnsLtpGF from "./getColumnsLtpGF";
import getColumnsLtpSaf from "./getColumnsLtpSaf";

const getColumns = (
  division,
  dateFormat,
  site_reference_id,
  division_id,
  preSelectedContractNumber,
  preSelectedBalanceGroup,
  preSelectedLocationGroup,
  preSelectedDocNumberSplit,
  preSelectedSdNumber,
  preSelectedType,
  preSelectedDateFrom,
  preSelectedDateTo,
  preSelectedDateLabel,
) => {
  switch (division) {
    case DIV_CODE_COPRO:
      return getColumnsLtpCopro(
        dateFormat,
        site_reference_id,
        division_id,
        preSelectedContractNumber,
        preSelectedBalanceGroup,
        preSelectedLocationGroup,
        preSelectedDocNumberSplit,
        preSelectedSdNumber,
        preSelectedType,
        preSelectedDateFrom,
        preSelectedDateTo,
        preSelectedDateLabel,
      );
    case DIV_CODE_GF:
      return getColumnsLtpGF(
        dateFormat,
        site_reference_id,
        division_id,
        preSelectedContractNumber,
        preSelectedBalanceGroup,
        preSelectedLocationGroup,
        preSelectedDocNumberSplit,
        preSelectedSdNumber,
        preSelectedType,
        preSelectedDateFrom,
        preSelectedDateTo,
        preSelectedDateLabel,
      );
    default:
      return getColumnsLtpSaf(
        dateFormat,
        site_reference_id,
        division_id,
        preSelectedContractNumber,
        preSelectedBalanceGroup,
        preSelectedLocationGroup,
        preSelectedDocNumberSplit,
        preSelectedSdNumber,
        preSelectedType,
        preSelectedDateFrom,
        preSelectedDateTo,
        preSelectedDateLabel,
      );
  }
};

export default getColumns;
