import FinlandFlag from "assets/images/countryFlags/finland.png";
import FRFlag from "assets/images/countryFlags/france.png";
import GYFlag from "assets/images/countryFlags/germany.png";
import GlobalSvg from "assets/images/countryFlags/global.png";
import NLFlag from "assets/images/countryFlags/netherlands.png";
import NWFlag from "assets/images/countryFlags/norway.png";
import PLFlag from "assets/images/countryFlags/poland.png";
import ESFlag from "assets/images/countryFlags/spain.png";
import SwedenFlag from "assets/images/countryFlags/sweden.png";
import UKFlag from "assets/images/countryFlags/uk.png";
import USFlag from "assets/images/countryFlags/us.png";

export const countries = [
  {
    img: GlobalSvg,
    countryName: "GLOBAL",
  },
  {
    img: UKFlag,
    countryName: "United Kingdom",
  },
  {
    img: GYFlag,
    countryName: "Germany",
  },
  {
    img: NWFlag,
    countryName: "Norway",
  },
  {
    img: FRFlag,
    countryName: "France",
  },

  {
    img: NLFlag,
    countryName: "Netherlands",
  },
  {
    img: PLFlag,
    countryName: "Poland",
  },
  {
    img: ESFlag,
    countryName: "Spain",
  },
  {
    img: USFlag,
    countryName: "United States",
  },
  {
    img: SwedenFlag,
    countryName: "Sweden",
  },
  {
    img: FinlandFlag,
    countryName: "Finland",
  },
];

export default {countries};
