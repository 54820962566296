import {FilePdf24, Info24} from "@bphxd/ds-core-react/lib/icons";
import outboundTypes, {ALL_MANDATES} from "constants/allocation";
import {NUMBER_OF_DECIMALS} from "constants/common";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import {getFilterValue} from "modules/GlobalMassBalance/utils";
import moment from "moment";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import getIncomingColumnsGfTrsSpain from "./Columns/incomingColumnsGfTrsSpain";
import getOutgoingColumnsGfTrsSpain from "./Columns/outgoingColumnsGfTrsSpain";

function parseDeliveryDate(deliveryDate) {
  const dateArr = deliveryDate?.split("-");
  const year = dateArr[0];
  const month = dateArr[1];
  const day = 1;
  const date = new Date(year, month - 1, day);
  return date;
}

export const INCOMING_COLUMNS_SAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          return (
            <>
              <Link
                to={{
                  pathname: `/document-manager/incoming/${country}/detailedView/${encodeURIComponent(
                    row?.original?.certificateInboundEuId,
                  )}`,
                  search: `?siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMj?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "sustainableQuantityM3",
        key: "sustainableQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.sustainableQuantityM3?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyM3?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyMt?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLossGain",
        key: "transportLossGain",
        visible: false,
        meta: {
          filterVariant: "search",
        },
      },
      {
        header: "Receiving location",
        accessorKey: "receivingLocation",
        key: "receivingLocation",
        visible: false,
        meta: {
          filterVariant: "search",
        },
        cell: ({row}) => {
          return row.original.receivingLocation || "";
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMT",
        key: "receivedQuantityMT",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.receivedQuantityMT?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity MT",
        accessorKey: "sustainableQuantityMT",
        key: "sustainableQuantityMT",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.sustainableQuantityMT?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavings",
        key: "ghgSavings",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgEmissionSaving?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
];

export const OUTGOING_COLUMNS_SAF = (dateFormat, decimalFormat, country) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Dispatch date",
        accessorKey: "deliveryDate",
        key: "deliveryDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return parseDeliveryDate(row?.deliveryDate);
        },
        cell: ({cell}) => {
          const month = moment(cell.getValue()).format("MMMM");
          const year = moment(cell.getValue()).format("YYYY");
          return cell.getValue() && `${month} ${year}`;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        cell: ({row}) => {
          const tooltipId = `recipient${row?.id}`;
          const sustainableQtyM3 = row?.original?.sustainableQtyM3;
          const recipient = row?.original?.recipient;
          const outboundType = row?.original?.outboundType;
          const isMandate = ALL_MANDATES.includes(outboundType);

          const previousDay = moment().subtract(1, "day");
          const currentYear = previousDay.format("YYYY");
          const periodYear = moment(row?.original?.deliveryDate).format("YYYY");
          const isPreviousYear = currentYear !== periodYear;
          const day = isPreviousYear ? "31" : previousDay.format("DD");
          const month = isPreviousYear
            ? "December"
            : previousDay.format("MMMM");
          const year = isPreviousYear ? periodYear : currentYear;

          return (
            <>
              <div id={tooltipId} className="flex">
                {recipient}
                {isMandate && <Info24 className="ml-[5px] text-black" />}
              </div>
              {isMandate && (
                <UncontrolledTooltip
                  placement="bottom"
                  target={tooltipId}
                  style={{borderRadius: "5px"}}
                >
                  <div className="text-[14px] mt-[10px] mb-[12px] text-left">
                    {recipient + " obligation"}
                  </div>
                  <div className="text-[13px] text-[#ffffffa3] text-left">
                    {outboundType} accrued total remaining as of {day} {month}{" "}
                    {year} is {sustainableQtyM3} m3
                  </div>
                </UncontrolledTooltip>
              )}
            </>
          );
        },
      },
      {
        header: "Ship to location",
        accessorKey: "shipToLocation",
        key: "shipToLocation",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "sustainableQtyM3",
        key: "sustainableQtyM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.sustainableQtyM3?.toString();
        },
        cell: ({cell, row}) => {
          const outboundType = row?.original?.outboundType;
          return outboundType === outboundTypes.VOLUNTARY
            ? getFormattedNumberWithCheck(cell.getValue(), NUMBER_OF_DECIMALS)
            : "";
        },
      },
      {
        header: "Fuel quantity m³",
        accessorKey: "fuelQtyM3",
        key: "fuelQtyM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.fuelQtyM3?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },

      {
        header: "Sustainable quantity MT",
        accessorKey: "sustainableQuantityMTOutgoing",
        key: "sustainableQuantityMTOutgoing",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.sustainableQuantityMTOutgoing?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Fuel quantity MT",
        accessorKey: "fuelQuantityMT",
        key: "fuelQuantityMT",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.fuelQuantityMT?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Product",
        accessorKey: "productOutgoing",
        key: "productOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },

      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMjOutgoing",
        key: "ghgTotalMjOutgoing",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMjOutgoing?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Dispatch location",
        accessorKey: "dispatchLocation",
        key: "dispatchLocation",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        cell: ({row}) => {
          return row?.original?.dispatchLocation || "";
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavingsPercentOutgoing",
        key: "ghgSavingsPercentOutgoing",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgSavingsPercentOutgoing?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
];

export const getColumnsSAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => {
  return {
    INCOMING_COLUMNS: INCOMING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
    OUTGOING_COLUMNS: OUTGOING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
  };
};

export const getColumnsGF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => {
  return {
    INCOMING_COLUMNS: getIncomingColumnsGfTrsSpain(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
    OUTGOING_COLUMNS: getOutgoingColumnsGfTrsSpain(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
  };
};
export const getColumnsCPP = (dateFormat, decimalFormat, country) => {
  return {
    // INCOMING_COLUMNS: INCOMING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
    // OUTGOING_COLUMNS: OUTGOING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
  };
};
