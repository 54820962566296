import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {isEmpty} from "lodash";
import {getFilterValue} from "modules/SAF-dashboard/utils";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import ColumnHighlight from "../ColumnHighlight";

const INCOMING_COLUMNS = (
  dateFormat,
  site_reference_id,
  division_id,
  preSelectedContractNumber,
  preSelectedBalanceGroup,
  preSelectedLocationGroup,
  preSelectedDocNumberSplit,
  preSelectedSdNumber,
  preSelectedType,
  preSelectedDateFrom,
  preSelectedDateTo,
  preSelectedDateLabel,
) => [
  {
    header: "Incoming document",
    id: "incoming_document_header",
    columns: [
      {
        header: "Contract number",
        accessorKey: "contract_number",
        key: "contract_number",
        visible: true,
        disabled: true,
        size: 50,
        accessorFn: (row) => {
          return row?.contract_number?.toString() || "";
        },
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "contract_number")}
            />
          );
        },
      },
      {
        header: "Document",
        accessorKey: "doc_number_split",
        key: "doc_number_split",
        visible: true,
        disabled: true,
        size: 200,
        cell: ({cell, row}) => {
          const {country} = useParams();
          const locationDocKey = country + "DocumentPage";
          const certi_id = row?.original?.certificate_inbound_eu_id;
          const LINK_TO_PURCHASE_PAGE = "Link to purchase Page";
          const preSelectionParams =
            `&ltpContractNumber=${preSelectedContractNumber}` +
            `&ltpBalanceGroup=${preSelectedBalanceGroup}` +
            `&ltpLocationGroup=${preSelectedLocationGroup}` +
            `&ltpSdNumber=${preSelectedSdNumber}` +
            `&ltpDocNumberSplit=${preSelectedDocNumberSplit}` +
            `&ltpBalanceType=${preSelectedType}` +
            `&dateFrom=${preSelectedDateFrom}` +
            `&dateTo=${preSelectedDateTo}` +
            `&dateLabel=${preSelectedDateLabel}` +
            `&backButtonSource=${LINK_TO_PURCHASE_PAGE}`;
          const DetailDocURL =
            `/document-manager/incoming/${country}/detailedView/${certi_id}?pageNo=0&siteReferenceId=${site_reference_id}&divisionId=${division_id}` +
            preSelectionParams;
          const tooltipId = `incomingLtp${row?.id}`;

          return (
            <>
              <Link to={DetailDocURL} className="link-dark" id={tooltipId}>
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "doc_number_split")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Dispatch date",
        key: "material_dispatch_datez",
        accessorKey: "material_dispatch_datez",
        filterFn: "dateRangeFilterFn",
        visible: true,
        size: 200,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Location",
        accessorKey: "incoming_location_group_name",
        key: "incoming_location_group_name",
        size: 200,
        visible: true,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "product_qty_m3",
        key: "product_qty_m3",
        size: 200,
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row.product_qty_uom === "M3"
            ? row?.product_qty?.toString()
            : "";
        },
        cell: ({row}) => {
          return row?.original?.product_qty_uom === "M3"
            ? getFormattedNumberWithCheck(
                row?.original?.product_qty,
                NUMBER_OF_DECIMALS,
              )
            : "";
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "product_qty_mt",
        key: "product_qty_mt",
        size: 200,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row.product_qty_uom === "MT"
            ? row?.product_qty?.toString()
            : "";
        },
        cell: ({row}) => {
          return row?.original?.product_qty_uom === "MT"
            ? getFormattedNumberWithCheck(
                row?.original?.product_qty,
                NUMBER_OF_DECIMALS,
              )
            : "";
        },
      },
      {
        header: "Dispatch address",
        accessorKey: "supplier_dispatch_address",
        key: "supplier_dispatch_address",
        footer: (props) => props.column.id,
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Product",
        accessorKey: "product_type",
        key: "product_type",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Raw material",
        accessorKey: "raw_material",
        key: "raw_material",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "country_of_origin",
        key: "country_of_origin",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier_name",
        key: "supplier_name",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Receiving point",
        accessorKey: "recipient_address",
        key: "recipient_address",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_incoming",
        key: "energy_mj_incoming",
        size: 200,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.energy_mj_incoming?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_incoming",
        key: "ghg_gco2eq_incoming",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return (row.ghg_gco2eq_incoming / 1000)?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Balance",
        accessorKey: "incoming_balance_group_name",
        key: "incoming_balance_group_name",
        size: 200,
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
    ],
  },
];

const PURCHASE_COLUMNS = (dateFormat) => [
  {
    header: "Purchase",
    columns: [
      {
        header: "Deal number",
        accessorKey: "deal_number",
        key: "deal_number",
        visible: true,
        disabled: true,
        accessorFn: (row) => {
          return row?.deal_number?.toString() || "";
        },
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "deal_number")}
            />
          );
        },
      },
      {
        header: "Deal date",
        key: "deal_done_date",
        accessorKey: "deal_done_date",
        filterFn: "dateRangeFilterFn",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Location",
        accessorKey: "purchase_location_group_name",
        key: "purchase_location_group_name",
        size: 200,
        visible: true,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Actualizable quantity m³",
        accessorKey: "quantity_actual",
        key: "quantity_actual",
        size: 100,
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_actual?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Actualizable quantity MT",
        accessorKey: "quantity_mt_actualized",
        key: "quantity_mt_actualized",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_mt_actualized?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_purchase",
        key: "energy_mj_purchase",
        visible: false,
        size: 80,
        dataType: "number",
        accessorFn: (row) => {
          return row?.energy_mj_purchase?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Purchased sustainable quantity m³",
        accessorKey: "quantity_m3_sbc",
        key: "quantity_m3_sbc",
        size: 250,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_m3_sbc?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Purchased sustainable quantity MT",
        accessorKey: "quantity_mt_sbc",
        key: "quantity_mt_sbc",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_mt_sbc?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Purchased quantity m³",
        accessorKey: "quantity_m3_hdr",
        key: "quantity_m3_hdr",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_m3_hdr?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Purchased quantity MT",
        accessorKey: "quantity_mt_hdr",
        key: "quantity_mt_hdr",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_mt_hdr?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Received quantity m³",
        accessorKey: "received_qty_m3",
        key: "received_qty_m3",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.received_qty_m3?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "quantity_mt_in",
        key: "quantity_mt_in",
        size: 100,
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.quantity_mt_in?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Purchase order",
        accessorKey: "po_number",
        key: "po_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "po_number")}
            />
          );
        },
      },
      {
        header: "Purchase date",
        key: "purchase_date",
        accessorKey: "purchase_date",
        filterFn: "dateRangeFilterFn",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Physical receipt date",
        key: "discharge_date",
        accessorKey: "discharge_date",
        filterFn: "dateRangeFilterFn",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoice_number",
        key: "invoice_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "invoice_number")}
            />
          );
        },
      },
      {
        header: "Material number",
        accessorKey: "material_number",
        key: "material_number",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Raw material",
        accessorKey: "material_description",
        key: "material_description",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Supplier",
        accessorKey: "vendor_name",
        key: "vendor_name",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Shipping point",
        accessorKey: "source_location_name",
        key: "source_location_name",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Receiving point",
        accessorKey: "destination_location_name",
        key: "destination_location_name",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Discharge plant code",
        accessorKey: "discharge_plant_code",
        key: "discharge_plant_code",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Purchase plant code",
        accessorKey: "purchase_plant_code",
        key: "purchase_plant_code",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total kgCO2eq",
        accessorKey: "ghg_kgco2eq_mj",
        key: "ghg_kgco2eq_mj",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghg_kgco2eq_mj?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_mj",
        key: "ghg_gco2eq_mj",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return (
            (row.ghg_kgco2eq_mj / row.energy_mj_purchase) *
            1000
          )?.toString();
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Balance",
        accessorKey: "purchase_balance_group_name",
        key: "purchase_balance_group_name",
        visible: false,
        filterFn: "selectFilterFn",
        meta: {
          filterVariant: "select",
        },
      },
    ],
  },
];

const getColumnsLtpSaf = (
  dateFormat,
  site_reference_id,
  division_id,
  preSelectedContractNumber,
  preSelectedBalanceGroup,
  preSelectedLocationGroup,
  preSelectedDocNumberSplit,
  preSelectedSdNumber,
  preSelectedType,
  preSelectedDateFrom,
  preSelectedDateTo,
  preSelectedDateLabel,
) => {
  return {
    incomingCols: INCOMING_COLUMNS(
      dateFormat,
      site_reference_id,
      division_id,
      preSelectedContractNumber,
      preSelectedBalanceGroup,
      preSelectedLocationGroup,
      preSelectedDocNumberSplit,
      preSelectedSdNumber,
      preSelectedType,
      preSelectedDateFrom,
      preSelectedDateTo,
      preSelectedDateLabel,
    ),
    purchaseCols: PURCHASE_COLUMNS(dateFormat),
  };
};

export default getColumnsLtpSaf;
