import {useLazyQuery} from "@apollo/client";
import {COPRO_US_COMMIT_ALLOCATION} from "graphql/coprocessing/shipments";
import {
  COMMIT_POPOVER,
  ERRORS,
} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useMemo} from "react";
import {toast} from "react-toastify";
import DeletePopover from "../Shared/DeletePopover";

const CommitAllocationPopover = ({
  target,
  showPopover,
  setShowPopover,
  commitData,
  endingInventory,
  remainingVolume,
  isActiveBatches,
  parts,
  refreshData,
  checklistItems,
  handleErrors,
}) => {
  const commitMsg = useMemo(
    () =>
      remainingVolume === 0 && endingInventory == null
        ? COMMIT_POPOVER.MISSING_ENDING_INVENTORY_MESSAGE
        : COMMIT_POPOVER.MESSAGE,
    [endingInventory, remainingVolume],
  );

  const [commitBatchesAndShipments, {data: commitDataRes}] = useLazyQuery(
    COPRO_US_COMMIT_ALLOCATION,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (commitDataRes?.error) {
          return toast.error(ERRORS.FAILED_COMMIT);
        }
        return refreshData();
      },
    },
  );

  const handleCommit = () => {
    commitBatchesAndShipments({
      variables: {
        allocation_ids: commitData,
        ...(isActiveBatches ? {is_batch_update: true} : {}),
      },
    });
    setShowPopover(false);
  };

  const isValidActiveBatch = () => {
    if (checklistItems?.completion_percentage === 100) {
      return true;
    }

    if (checklistItems?.shipment_linked_status === 0) {
      handleErrors("shipment_linked_status", ERRORS.SHIPMENTS_LINK_REQUIRED);
    }

    if (checklistItems?.shipment_linked_status === 1) {
      handleErrors("shipment_linked_status", ERRORS.SHIPMENT_UNLINKED_VOLUME);
    }

    if (checklistItems?.c14_linked_status === 0) {
      handleErrors("c14_linked_status", ERRORS.C_14_LINK_REQUIRED);
    }

    if (checklistItems?.feedstock_allocated_status === 0) {
      handleErrors(
        "feedstock_allocated_status",
        ERRORS.FEEDSTOCK_ALLOCATION_REQUIRED,
      );
    }
    return false;
  };

  const isValidShipment = () => {
    let missingVolume = false;
    let missingBatchId = false;

    const allPartsValid = parts.every((part) => {
      const hasActualVolume = part.total_actual_volume !== null;
      const hasBatchId = part.batch_id !== null;

      if (!hasActualVolume) {
        missingVolume = true;
      }

      if (!hasBatchId) {
        missingBatchId = true;
      }

      return hasActualVolume && hasBatchId;
    });

    if (missingVolume) {
      handleErrors("missing_total_volume", ERRORS.VOLUME_UNLINKED);
    }

    if (missingBatchId) {
      handleErrors("missing_batch_id", ERRORS.MISSING_BATCH_ID);
    }

    return allPartsValid;
  };

  const validateCommit = () => {
    if (isActiveBatches && isValidActiveBatch()) {
      handleCommit();
    }
    if (!isActiveBatches && isValidShipment()) {
      handleCommit();
    }
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={validateCommit}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget={target}
      header={COMMIT_POPOVER.HEADER}
      message={commitMsg}
      cancel={COMMIT_POPOVER.CANCEL}
      confirm={COMMIT_POPOVER.YES}
      idToDelete=""
    />
  );
};

CommitAllocationPopover.propTypes = {
  target: PropTypes.string,
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  commitData: PropTypes.array,
  refreshData: PropTypes.func,
  endingInventory: PropTypes.number,
  remainingVolume: PropTypes.number,
  isActiveBatches: PropTypes.bool,
  parts: PropTypes.array,
  checklistItems: PropTypes.shape({
    batch_created_status: PropTypes.number,
    c14_linked_status: PropTypes.number,
    completion_percentage: PropTypes.number,
    feedstock_allocated_status: PropTypes.number,
    shipment_linked_status: PropTypes.number,
  }),
  handleErrors: PropTypes.func,
};

export default CommitAllocationPopover;
