import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";

const ResponseModal = ({showModal, header, body, footerArr, setShowModal}) => {
  const isOnlyHeader = !body;
  const toggleModal = () => setShowModal(!showModal);
  return (
    <Modal
      size="sm"
      isOpen={showModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      backdrop
    >
      <ModalBody className="text-center">
        <p className={`mb-[2px] ${isOnlyHeader && "leading-7"}`}>{header}</p>
        <p className="text-[13px] text-gray-800 mb-0">{body} </p>
      </ModalBody>
      {footerArr.map((footer) => (
        <ModalFooter key={footer.id} className="justify-center p-0">
          <Button
            color="darker-tertiary"
            size="lg"
            className={`border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full ${footer.buttonClassName}`}
            onClick={footer.action}
            disabled={footer.disabled}
          >
            {footer.text}
          </Button>
        </ModalFooter>
      ))}
    </Modal>
  );
};

ResponseModal.propTypes = {
  showModal: PropTypes.bool,
  header: PropTypes.string,
  body: PropTypes.string,
  footerArr: PropTypes.array,
  setShowModal: PropTypes.func,
};

export default ResponseModal;
