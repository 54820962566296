import {useQuery, useSubscription} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  Add24,
  Alert24,
  CircleCheck24,
  Remove24,
} from "@bphxd/ds-core-react/lib/icons";
import GET_NABISY_ACCOUNTS from "graphql/docManager/bioLcGetNabisyAccounts";
import {NabisyRetrievalMutationAPI} from "graphql/docManager/bioLcIngestNabisyDocument";
import NABISY_RETRIEVAL_SUBSCRIPTION from "graphql/docManager/onBiolcingestnabisydocumentcomplete";
import {useAppSetting} from "providers/appSetting";
import React, {useEffect, useMemo, useState} from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const NabisyForm = () => {
  const {appSetting} = useAppSetting();
  const {country, division} = useParams();
  const navigate = useNavigate();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const [userId, setUserId] = useState();
  const [isSendAnother, setIsSendAnother] = useState(false);
  const [allDocumentIdPopulated, setAllDocumentIdPopulated] = useState(false);
  const [accountNumberSelected, setAccountNumberSelected] = useState(false);
  const [documentIds, setDocumentIds] = useState([
    {documentId: ""},
    {documentId: ""},
    {documentId: ""},
  ]);
  const [nabisyAccountNumber, setNabisyAccountNumber] = useState("");

  const {data: subscriptionData, error: subscriptionError} = useSubscription(
    NABISY_RETRIEVAL_SUBSCRIPTION,
    {
      variables: {userId},
      skip: !userId,
    },
  );

  const defaultValues = {
    accountId: "",
    documents: [{documentId: ""}, {documentId: ""}, {documentId: ""}],
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = methods;

  const {data, loading, refetch} = useQuery(GET_NABISY_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.bioLcGetNabisyAccounts?.accounts?.length === 1) {
        setNabisyAccountNumber(data.bioLcGetNabisyAccounts?.accounts[0]);
        setValue("accountId", data.bioLcGetNabisyAccounts?.accounts[0]);
        setAccountNumberSelected(true);
      }
    },
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const accountsList = data?.bioLcGetNabisyAccounts?.accounts ?? [];

  const {fields, append, remove} = useFieldArray({
    control,
    name: "documents",
  });

  const [loadingStates, setLoadingStates] = useState(fields.map(() => false));
  const [successStates, setSuccessStates] = useState(fields.map(() => null));
  const [errorStates, setErrorStates] = useState(fields.map(() => null));
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (subscriptionError) {
      setIsError(true);
      setIsSendAnother(false);
      setLoadingStates((prevLoadingStates) => {
        const updatedLoadingStates = [...prevLoadingStates];
        updatedLoadingStates.fill(false);
        return updatedLoadingStates;
      });
    } else if (subscriptionData?.onBiolcingestnabisydocumentcomplete) {
      const {statusCode, sdNumberIndex, message} =
        subscriptionData.onBiolcingestnabisydocumentcomplete;

      if (statusCode === 500) {
        setIsError(true);
      }

      if (statusCode === 400) {
        setErrorStates((prevErrorStates) => {
          const updatedErrorStates = [...prevErrorStates];
          updatedErrorStates[sdNumberIndex] = message;
          return updatedErrorStates;
        });
      }

      if (statusCode === 201) {
        setSuccessStates((prevSuccessStates) => {
          const updatedSuccessStates = [...prevSuccessStates];
          updatedSuccessStates[sdNumberIndex] = message;
          return updatedSuccessStates;
        });
      }
      setLoadingStates((prevLoadingStates) => {
        const updatedLoadingStates = [...prevLoadingStates];
        updatedLoadingStates[sdNumberIndex] = false;
        return updatedLoadingStates;
      });
    }
  }, [subscriptionData, subscriptionError]);

  useEffect(() => {
    if (loadingStates.every((state) => !state)) {
      setUserId((prevUserId) => {
        if (prevUserId && !isError) setIsSendAnother(true);
        return null;
      });
    }
  }, [loadingStates, isError]);

  const incomingDocUrl = useMemo(() => {
    return `/doc-manager/${country}?page=incoming&divisionId=${divisionData?.divisionId}`;
  }, [country, divisionData?.divisionId]);

  const validateDuplicate = (value, index) => {
    const values = getValues("documents");
    const duplicate = values.some(
      (doc, i) => doc.documentId === value && value !== "" && i !== index,
    );
    return duplicate
      ? "A document with the same ID has already been entered"
      : true;
  };

  const isAtleastOneDocumentIdPopulated = () => {
    const values = getValues("documents");
    return values.some((doc) => doc.documentId !== "");
  };

  const onSubmit = async (data) => {
    setUserId(account?.username);
    setDocumentIds(data?.documents);
    setNabisyAccountNumber(data?.accountId);
    const updatedLoadingStates = new Array(fields.length).fill(false);
    setLoadingStates(
      updatedLoadingStates.map(
        (_, index) => data.documents[index].documentId !== "",
      ),
    );
    const updatedSuccessStates = new Array(fields.length).fill(null);
    setSuccessStates(updatedSuccessStates);
    const updatedErrorStates = new Array(fields.length).fill(null);
    setErrorStates(updatedErrorStates);
    const nabisyData = {
      event: {
        divisionId: divisionData?.divisionId,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        documentNumbers: data.documents
          .filter((doc) => doc.documentId !== "")
          .map((doc) => doc.documentId),
        nabisyAccountNumber: data.accountId,
        sdNumberIndices: data.documents
          .map((_, index) => index)
          .filter((index) => data.documents[index].documentId !== ""),
        userId: account?.username,
      },
    };
    const response = await NabisyRetrievalMutationAPI(nabisyData);
    if (response.data.bioLcIngestNabisyDocument.statusCode === 500) {
      setIsError(true);
    }
  };

  const isLoading =
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const isAccountsDataAvailable = !isError && accountsList.length > 0;

  const disableInputs = isSendAnother || loadingStates.some((state) => state);

  return (
    <div className="mt-[32px]">
      <h1 className="text-[20px] font-light">
        Log into your Nabisy account to retrieve documents
      </h1>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isLoading && (
            <div className="text-center mt-[120px] mb-[312px]">
              <Spinner />
            </div>
          )}
          {!isLoading && isAccountsDataAvailable && (
            <>
              <FormGroup>
                <Label for="accountId" className="fw-normal">
                  Account ID
                </Label>
                <Controller
                  name="accountId"
                  control={control}
                  rules={{required: "Account ID is required"}}
                  disabled={userId}
                  render={({field: {onChange, ...field}}) => (
                    <Input
                      {...field}
                      value={nabisyAccountNumber}
                      onChange={(e) => {
                        onChange(e);
                        setAccountNumberSelected(e.target.value !== "");
                        setNabisyAccountNumber(e.target.value);
                      }}
                      type="select"
                      id="accountId"
                      data-test="accountId"
                      placeholder="Select account ID"
                      disabled={disableInputs}
                      className="!w-[218px] !placeholder-[#111111a3] bg-white  disabled:filter disabled:brightness-95 disabled:opacity-70 disabled:text-gray-700 disabled:!placeholder-gray-700"
                    >
                      <option value="">Select account ID</option>
                      {accountsList.map((account) => (
                        <option key={account} value={account}>
                          {account}
                        </option>
                      ))}
                    </Input>
                  )}
                />
                {errors.accountId && (
                  <FormFeedback className="mt-0 !block">
                    {errors.accountId.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="mt-[56px]">
                <Label for="documentId" className="fw-normal">
                  Document
                </Label>
                {fields.map((field, index) => (
                  <div key={field.id} className="mb-[32px]">
                    <div className="flex items-center">
                      <Controller
                        name={`documents.${index}.documentId`}
                        control={control}
                        rules={{
                          validate: (value) => validateDuplicate(value, index),
                        }}
                        disabled={userId}
                        render={({field: {onChange, ...field}}) => (
                          <Input
                            {...field}
                            value={documentIds[index]?.documentId}
                            onChange={(e) => {
                              onChange(e);
                              const values = getValues("documents");
                              setAllDocumentIdPopulated(
                                isAtleastOneDocumentIdPopulated(),
                              );
                              setDocumentIds(values);
                            }}
                            type="text"
                            id={`documentId-${index}`}
                            invalid={!!errors.documents?.[index]?.documentId}
                            disabled={disableInputs}
                            className="bg-white !placeholder-[#111111a3] !w-[600px] disabled:filter disabled:brightness-95 disabled:opacity-70 disabled:text-gray-700 disabled:!placeholder-gray-700"
                            placeholder="Enter document ID"
                            autoComplete="off"
                          />
                        )}
                      />
                      {index > 0 &&
                        loadingStates.every((state) => !state) &&
                        !isSendAnother && (
                          <div
                            className="ml-[11px] cursor-pointer"
                            onClick={() => {
                              remove(index);
                              const values = getValues("documents");
                              setAllDocumentIdPopulated(
                                isAtleastOneDocumentIdPopulated(),
                              );
                              setDocumentIds(values);
                            }}
                            // This is a temporary fix, will remove in future commits
                            onKeyDown={() => {}}
                          >
                            <Remove24 color="#cccccc" />
                          </div>
                        )}
                      {loadingStates[index] && (
                        <div className="ml-[11px]">
                          <Spinner size="sm" className="!border-[0.15rem]" />
                        </div>
                      )}
                      {successStates[index] && (
                        <div className="ml-[11px]">
                          <CircleCheck24 />
                        </div>
                      )}
                      {errorStates[index] && (
                        <div className="ml-[11px]">
                          <Alert24 color="#e64949" />
                        </div>
                      )}
                    </div>
                    {errors.documents?.[index]?.documentId && (
                      <FormFeedback className="mt-0 !block">
                        {errors.documents[index].documentId.message}
                      </FormFeedback>
                    )}
                    {(errorStates[index] || successStates[index]) && (
                      <FormFeedback
                        className={`mt-0 !block ${
                          successStates[index] && "!text-[#111111a3]"
                        }`}
                      >
                        {errorStates[index] || successStates[index]}
                      </FormFeedback>
                    )}
                  </div>
                ))}

                <Button
                  type="button"
                  color="transparent"
                  className="mt-[-12px] !border-none focus:border-none"
                  onClick={() => {
                    setDocumentIds([...documentIds, {documentId: ""}]);
                    append({documentId: ""});
                  }}
                  disabled={disableInputs}
                >
                  <Add24 className="mr-[6px]" /> Add document
                </Button>
              </FormGroup>
            </>
          )}
          {!isLoading && !isAccountsDataAvailable && (
            <div className="mt-[120px] mb-[300px]">
              <p className="text-left">
                We are currently unable to retrieve documents from Nabisy.
                Please try again later.
                <br /> If the issue persists, contact our support team for
                assistance.
              </p>
            </div>
          )}

          <div className="mt-[40px] flex justify-between w-[600px]">
            <Button
              color="tertiary"
              outline
              type="secondary"
              size="md"
              className="show link-btn rounded-0"
              onClick={() => navigate(incomingDocUrl)}
            >
              Back to incoming documents
            </Button>
            {!isLoading && !isAccountsDataAvailable ? (
              <Button
                type="button"
                color="primary"
                className="!rounded-none"
                onClick={() => {
                  refetch();
                  setIsError(false);
                  setLoadingStates(documentIds?.map(() => false));
                  setErrorStates(documentIds?.map(() => null));
                  setSuccessStates(documentIds?.map(() => null));
                  setUserId(null);
                  setIsSendAnother(false);
                  setValue("documents", documentIds);
                  setValue("accountId", nabisyAccountNumber);
                }}
              >
                Try again
              </Button>
            ) : isSendAnother ? (
              <Button
                type="button"
                color="primary"
                className="!rounded-none"
                onClick={() => {
                  reset(defaultValues, {
                    keepErrors: false, // Clear the errors to avoid validation trigger
                    keepDirty: false, // Reset the dirty fields
                    keepTouched: false, // Reset the touched fields
                    keepIsSubmitted: false, // Reset the isSubmitted state
                    keepSubmitCount: false, // Reset the submit count
                    keepValues: false, // Reset the values to default
                    keepDefaultValues: true, // Keep the default values
                  });
                  setLoadingStates(defaultValues.documents.map(() => false));
                  setErrorStates(defaultValues.documents.map(() => null));
                  setSuccessStates(defaultValues.documents.map(() => null));
                  setIsSendAnother(false);
                  setAccountNumberSelected(false);
                  setAllDocumentIdPopulated(false);
                  setDocumentIds(defaultValues.documents);
                  if (accountsList?.length === 1) {
                    setValue("accountId", accountsList[0]);
                    setAccountNumberSelected(true);
                  } else {
                    setNabisyAccountNumber(defaultValues.accountId);
                  }
                }}
              >
                Send another
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                data-test="submit"
                className="!rounded-none"
                disabled={
                  isLoading ||
                  !allDocumentIdPopulated ||
                  !accountNumberSelected ||
                  !isAccountsDataAvailable ||
                  userId
                }
              >
                Send request
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default NabisyForm;
