import getRequiredCheckboxPattern from "utils/checkboxUtil";
import {
  getDDVTDVNumberInputPattern,
  getRequiredNumberInputPattern,
} from "utils/numberUtil";

export const tabFields = {
  supplier: [
    "dateOfIssuance",
    "supplierName",
    "supplierAddress",
    "supplierDispatchAddress",
    "certificationSystem",
    "certificateNumber",
    "materialDispatchDatez",
    "recipientName",
    "recipientAddress",
    "recipientReceiptAddress",
    "contractNumber",
  ],
  general: [
    "productType",
    "rawMaterialType",
    "additionalInfo",
    "rawMaterialOriginCountryName",
    "feedstockQty",
    "feedstockQtyUom",
    "isccCompliantFlag",
    "euRedCompliantFlag",
    "chainOfCustodyOption",
    "productEnergyContent",
  ],
  emission: [
    "ghgEec",
    "ghgEl",
    "ghgEp",
    "ghgEtd",
    "ghgEu",
    "ghgEsca",
    "ghgEccs",
    "ghgEccr",
    "ghgTotal",
    "motFromRefinery",
    "motToRefinery",
    "motDistanceKm",
    "physicalReceiptDate",
    "splitDetails",
    "ghgNoValue",
  ],
  certification: [
    "materialSustainabilityCriteriaFlag",
    "agriculturalBiomassIntermediateCropFlag",
    "agriculturalBiomassLowRiskFlag",
    "rawMaterialWasteOrResidueFlag",
    "wasteOrAnimalByProductPermitNumber",
    "supportForFuelProductionOrFuelPrecursorReceivedFlag",
    "ifYesSpecifySupportNatureAndScheme",
  ],
  additionalInfo: [
    "emissionForTransport",
    "mbLocation",
    "invoiceNumber",
    "vesselName",
    "observationNotes",
    "mbBalanceGroupId",
    "mbLocationGroupId",
  ],
};

export const rejectReasons = [
  {
    key: "info_missing",
    value: "Required information missing or incorrect",
    message:
      "This certificate will be kept and deactivated. A new certificate needs to be reissued",
  },
  {
    key: "out_of_date",
    value: "Out of date certificate",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
  {key: "invalid", value: "Invalid file", message: "This file will be deleted"},
  {
    key: "other",
    value: "Other",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
];

const fieldToRequiredMessageMap = {
  sdNumber: "Please enter a sd number",
  dateOfIssuance: "Please enter date of issuance",
  supplierName: "Please enter name of supplier",
  supplierAddress: "Please enter address of supplier",
  certificationSystem: "Please enter a certification system",
  recipientAddress: "Please enter address of recipient",
  supplierDispatchAddress: "Please enter address of supplier",
  recipientReceiptAddress:
    "Please enter address of receipt/receiving point of the sustainable material",
  materialDispatchDatez: "Please enter date of dispatch",
  productType: "Please enter type of product",
  rawMaterialType: "Please enter type of raw material",
  rawMaterialOriginCountryName: "Please enter country of origin",
  productQty: "Please enter quantity",
  productQtyUom: "Please select unit of measure",
  feedstockQty: "Please enter quantity",
  feedstockQtyUom: "Please select unit of measure",
  productEnergyContent: "Please enter energy content",
  isccCompliantFlag: "Please select an option",
  euRedCompliantFlag: "Please select an option",
  ghgEmissionPercentageIn: "Please enter GHG emission savings",
  producerInstallationDateFlag: "Please select an option",
  incentiveFlag: "Please select an option",
  originCountryName: "Please enter country of origin",
  splitPercentage: "Please enter a percentage",
  transportationEmissionRoute: "Please select transportation type and route",
  mbLocationGroupId: "Please select location",
  mbBalanceGroupId: "Please select balance",
  previousVersionId: "Please select a previous version",
  rejectionReason: "Please select reject reason",
  rejectionReasonNotes: "Please enter notes",
  default: "This field is required",
};

export const getRequiredMessageForField = (field) => {
  return Object.hasOwn(fieldToRequiredMessageMap, field)
    ? fieldToRequiredMessageMap[field]
    : fieldToRequiredMessageMap.default;
};

const customCheckBoxValidation = (_, validationSchema) => {
  const isRequired = validationSchema?.required ?? false;
  return isRequired ? getRequiredCheckboxPattern() : {};
};

const fieldToCustomValidationMap = {
  productEnergyContent: ({decimalFormat}, validationSchema) => {
    if (!decimalFormat) return {};

    return {
      ...validationSchema,
      ...getRequiredNumberInputPattern(decimalFormat),
    };
  },
  isccCompliantFlag: customCheckBoxValidation,
  euRedCompliantFlag: customCheckBoxValidation,
  ghgEmissionPercentageIn: ({decimalFormat}, validationSchema) => {
    if (!decimalFormat) return {};

    return {
      ...validationSchema,
      ...getDDVTDVNumberInputPattern(decimalFormat),
    };
  },
  default: undefined,
};

export const getCustomValidationForField = (field) => {
  return Object.hasOwn(fieldToCustomValidationMap, field)
    ? fieldToCustomValidationMap[field]
    : fieldToCustomValidationMap.default;
};

export default {tabFields, rejectReasons};
