import {useAccount, useMsal} from "@azure/msal-react";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {AwsRum} from "aws-rum-web";
import {createBrowserHistory} from "history";
import useGenerateCertificateSubscription from "hooks/useGenerateCertificateSubscription";
import useGenerateCertificateSubscriptionNew from "hooks/useGenerateCertificateSubscriptionNew";
import DivisionDashboard from "modules/DivisionDashboard/pages/DivisionDashboard";
import OutgoingDocumentPageLatest from "modules/DocManager/components/OutgoingDocuments/OutgoingDocumentPage";
import DocumentDetailedViewPage from "modules/DocManager/pages/DocumentDetailedView";
import DocumentGeneratedViewPage from "modules/DocManager/pages/DocumentGeneratedView";
import DocumentManagerDashboard from "modules/DocManager/pages/DocumentManagerDashboard";
import NabisyDocumentRetrievalPage from "modules/DocManager/pages/NabisyDocumentRetrievalPage";
import OutgoingDetailedDocumentViewPage from "modules/DocManager/pages/OutgoingDetailedDocumentViewPage";
import Allocation from "modules/GlobalAllocation/pages/Allocation";
import {
  BusinessPartnerAddressConfigPage,
  BusinessPartnerConfigPage,
  BusinessPartnerLinkLmConfigPage,
  CertificateIdConfigPage,
  CertificateSchemeConfigPage,
  CertificateSchemeVariationConfigPage,
  CountryCodeConfigPage,
  CountryVariationConfigPage,
  LocationDetailsConfigPage,
  LocationYieldDetailsConfigPage,
  MBConfigPage,
  MassBalanceMaterialsConfigPage,
  MaterialMasterConfigPage,
  MbBalanceGroupConfigPage,
  MbBalanceTypeConfigPage,
  MbLgLocationPage,
  ProcessingUnitConfigPage,
  ProductBaselineEmissionConfigPage,
  ProductDefaultValuesConfigPage,
  ProductionEmissionNewConfigPage,
  TaxPlantRegistryConfigPage,
  TransportationEmissionsConfigPage,
} from "modules/GlobalConfigCenter";
import NationalRegistryConfigPage from "modules/GlobalConfigCenter/pages/CountryCodeConfiguration/NationalRegistryConfigPage";
import LinkToPurchaseLandingPage from "modules/GlobalLinkToPurchase/pages/LinkToPurchaseLandingPage";
import LinkToPurchaseLandingPageOld from "modules/GlobalLinkToPurchase/pages/LinkToPurchaseLandingPageOld";
import MandateAllocation from "modules/GlobalMandateCertificates/pages/MandateAllocation";
import MandateCertificate from "modules/GlobalMandateCertificates/pages/MandateCertificate";
import MandateCertificateGeneratePOC from "modules/GlobalMandateCertificates/pages/MandateCertificateGeneratePOC";
import MandateCertificateGeneratePTD from "modules/GlobalMandateCertificates/pages/MandateCertificateGeneratePTD";
import MBGenerateCertificateDetails from "modules/GlobalMassBalance/components/Actions/CPP/GenerateCertificate/GenerateCertificateDetails";
import ViewCertificatePage from "modules/GlobalMassBalance/components/Actions/Outgoing/ViewCertificatePage";
import MBList from "modules/GlobalMassBalance/pages/MBList";
import MassBalance from "modules/GlobalMassBalance/pages/MassBalance";
import MassBalanceDetailViewPage from "modules/SAF-dashboard/pages/MassBalanceDetailViewPage";
import PowerBiReport from "modules/SAF-dashboard/pages/PowerBi";
import PowerBiActualization from "modules/SAF-dashboard/pages/PowerBiActualization";
import SAFDocumentViewPage from "modules/SAF-dashboard/pages/SAFDocumentViewPage";
import SAFDocumentPage from "modules/SAF-dashboard/pages/SAFDocumentsPage";
import SAFLandingPage from "modules/SAF-dashboard/pages/SAFLandingPage";
import SAFLinkToPurchasePage from "modules/SAF-dashboard/pages/SAFLinkToPurchasePage";
import SAFMassBalancePage from "modules/SAF-dashboard/pages/SAFMassBalancePage";
import SafDashboard from "modules/SAF-dashboard/pages/reports/SafDashboard";
import CoProcessing from "modules/co-processing";
import APIErrorPage from "modules/common/ErrorPages/APIErrorPage";
import NotFoundPage from "modules/common/ErrorPages/NotFoundPage";
import Feature from "modules/common/FeatureFlag/Feature";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import ComplianceRulesLandingPage from "modules/compliance-rules-engine/pages/ComplianceRulesLandingPage";
import RulesEditorLandingpage from "modules/compliance-rules-engine/pages/RulesEditorLandingpage";
import RulesVersionPage from "modules/compliance-rules-engine/pages/RulesVersionPage";
import RuleVersionTestPage from "modules/compliance-rules-engine/pages/ruleVersionTestPage";
import ComplianceDashboard from "modules/compliance/components";
import ComplianceMassBalance from "modules/compliance/components/massBalance/ComplianceMassBalance";
import CoprocessingSettingLandingPage from "modules/config-center/pages/CoprocessingSettingLandingPage";
import CostCalculationLanding from "modules/cost-calculation/pages/CostCalculationLanding";
import CostFeedstockParameters from "modules/cost-calculation/pages/CostFeedstockParameters";
import Dashboard from "modules/dashboard";
import FeatureCountryDashboard from "modules/dashboard/FeatureCountryDashboard";
import GenerateCertificateDetails from "modules/es-co-processing/components/GenerateCertificate/GenerateCertificateDetails";
import EsLandingPage from "modules/es-co-processing/pages/EsLandingPage";
import IncomingDocumentPage from "modules/es-co-processing/pages/IncomingDocumentPage";
import ManualEntryPage from "modules/es-co-processing/pages/ManualEntryPage";
import OutgoingDocumentPage from "modules/es-co-processing/pages/OutgoingDocumentPage";
import ViewDocumentPage from "modules/es-co-processing/pages/ViewDocumentPage";
import ViewOutgoingDocumentPage from "modules/es-co-processing/pages/ViewOutgoingDocumentPage";
import FeedstockLanding from "modules/fifo/pages/FeedstockLanding";
import Forecasting from "modules/forecasting/index";
import ManualAdjustmentDashboard from "modules/gy-enablement/components/MOT/ManualAdjustmentDashboard";
import CeemasValidation from "modules/gy-enablement/components/MOT/motSummary/CeemasValidation";
import CeemasValidationBalanceGroupLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationBalanceGroupLevel";
import CeemasValidationDayLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationDayLevel";
import CeemasValidationEDGroupLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationEDGroupLevel";
import CeemasValidationMaterialLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationMaterialLevel";
import CeemasValidationPlantLevel from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationPlantLevel";
import CeemasValidationTransactionDetails from "modules/gy-enablement/components/MOT/motSummary/CeemasValidationTransactionDetails";
import GYMasterNETSCO2CostsDashboard from "modules/gy-enablement/components/masterDetails/NETSCO2Costs/NETSCO2CostsDashBoard";
import GYMasterTaxPlantDashboard from "modules/gy-enablement/components/masterDetails/TaxPlantRegistry/TaxPlantRegistryDashboard";
import MassBalanceForLocation from "modules/mass-balancing/pages/MassBalanceForLocation";
import PoSDashboard from "modules/pos-management/PoSDashboard";
import PoSLandingPage from "modules/pos-management/PoSLandingPage";
import PoSManagement from "modules/pos-management/PoSManagement";
import PoSNLNabisyDashboard from "modules/pos-management/PoSNLNabisyDashboard";
import PoSPolandDashboard from "modules/pos-management/PoSPolandDashboard";
import UploadPOSCertificate from "modules/pos-management/UploadPOSCertificate";
import ProductMetrices from "modules/reports/components/ProductMetrices";
import ProductMetCeemasMOTValidation from "modules/reports/components/ProductMetrices/CeemasMOTValidation";
import ProductMetMOTManualAdjustment from "modules/reports/components/ProductMetrices/MOTManualAdjustment";
import TankVolume from "modules/reports/components/ProductMetrices/TankVolume";
import BVCReport from "modules/reports/powerBIReports/BVCReport";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {removeUnUsedLocalStorageforLogin} from "utils/helpers/removeUnUsedCache";

const App = () => {
  removeUnUsedLocalStorageforLogin();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  if (
    ["bioverse.bpglobal.com", "dev.bioverse.bpglobal.com"].includes(
      window.location.hostname,
    )
  ) {
    // Send Data to Azure app insights for TMA report
    const browserHistory = createBrowserHistory({basename: ""});
    const {REACT_APP_InstrumentationKey} = process.env;
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: `InstrumentationKey=${REACT_APP_InstrumentationKey};IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/`,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {history: browserHistory},
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.setAuthenticatedUserContext(account?.username);
    appInsights.context.user.id = account?.username;
  }

  if (window.location.hostname !== "localhost") {
    // Send data to AWS RUM
    try {
      const {
        REACT_APP_POOL_ID,
        REACT_APP_AWS_SPOKE,
        REACT_APP_AWS_ACCOUNT_ID,
        REACT_APP_AWS_REGION,
        REACT_APP_RUM_ID,
      } = process.env;

      const config = {
        sessionSampleRate: 1,
        guestRoleArn: `arn:aws:iam::${REACT_APP_AWS_ACCOUNT_ID}:role/${REACT_APP_AWS_SPOKE}-role_RUM`,
        identityPoolId: `${REACT_APP_AWS_REGION}:${REACT_APP_POOL_ID}`,
        endpoint: `https://dataplane.rum.${REACT_APP_AWS_REGION}.amazonaws.com`,
        telemetries: ["errors", "performance", "http"],
        allowCookies: true,
        enableXRay: true,
        sessionAttributes: {
          userId: account?.localAccountId,
        },
      };

      const APPLICATION_ID = `${REACT_APP_RUM_ID}`;
      const APPLICATION_VERSION = "1.0.0";
      const APPLICATION_REGION = `${REACT_APP_AWS_REGION}`;

      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  // List of all app level subscriptions can be triggered here
  useGenerateCertificateSubscription(); // subscription handled for certificate generation
  useGenerateCertificateSubscriptionNew();

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/forecasting/*" element={<Forecasting />} />
      <Route path="/co-processing/*" element={<CoProcessing />} />
      {/* <Route path="/pos-management/nl" element={<PoSNLDashboard />} /> */}
      <Route path="/pos-management/uk" element={<PoSDashboard />} />
      <Route path="/pos-management/pl" element={<PoSPolandDashboard />} />
      {/* <Route path="/pos-management/lux" element={<LuxembourgDashboard />} /> */}
      <Route path="/pos-management/landing" element={<PoSLandingPage />} />
      <Route
        path="/pos-management/nl/nabisy"
        element={<PoSNLNabisyDashboard />}
      />
      <Route
        path="/pos-management/upload-documents/:country"
        element={<PoSManagement />}
      />
      <Route
        path="/document-manager/incoming/:country/detailedView/:docId"
        element={<DocumentDetailedViewPage />}
      />
      <Route
        path="/document-manager/outgoing/:country/:division/:docId"
        element={<OutgoingDetailedDocumentViewPage />}
      />
      <Route
        path="/pos-management/upload-documents/pos-certificate/:country"
        element={<UploadPOSCertificate />}
      />
      <Route path="/product-metrics" element={<ProductMetrices />} />
      <Route path="/product-metrics/tank-volume" element={<TankVolume />} />
      <Route
        path="/product-metrics/ceemas-mot-validation"
        element={<ProductMetCeemasMOTValidation />}
      />
      <Route
        path="/product-metrics/mot-manual-adjustment"
        element={<ProductMetMOTManualAdjustment />}
      />
      <Route path="/reporting/bvc-report" element={<BVCReport />} />

      {/* START GY-Enablement */}
      <Route
        path="/gy-enablement"
        element={<Navigate to="/gy-enablement/mot-manual-adjustment" replace />}
      />
      <Route
        path="/gy-enablement/mot-manual-adjustment"
        element={<ManualAdjustmentDashboard />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation/:filterDetails"
        element={<CeemasValidation />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-plant-level/:filterDetails"
        element={<CeemasValidationPlantLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-edgroup-level/:filterDetails"
        element={<CeemasValidationEDGroupLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-balancegroup-level/:filterDetails"
        element={<CeemasValidationBalanceGroupLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-material-level/:filterDetails"
        element={<CeemasValidationMaterialLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-day-level/:filterDetails"
        element={<CeemasValidationDayLevel />}
      />
      <Route
        path="/gy-enablement/ceemas-mot-validation-transaction-level/:filterDetails"
        element={<CeemasValidationTransactionDetails />}
      />
      {/* END GY-Enablement */}

      {/* Compliance */}
      <Route path="/compliance" element={<ComplianceDashboard />}>
        <Route path="monitor/:country" element={<ComplianceMassBalance />} />
        <Route index element={<Navigate to="monitor/:country" />} />
      </Route>
      <Route
        path="/gy-enablement/master/tax-plant-registry"
        element={<GYMasterTaxPlantDashboard />}
      />
      <Route
        path="/gy-enablement/master/NETS-CO2-Costs"
        element={<GYMasterNETSCO2CostsDashboard />}
      />
      {/* Spain Co-processing */}
      <Route path="/copro-spain/:country/" element={<EsLandingPage />} />
      <Route
        path="/copro-spain/:country/document-manager/incoming-documents"
        element={<IncomingDocumentPage />}
      />
      <Route
        path="/copro-spain/:country/document-manager/outgoing-documents"
        element={<OutgoingDocumentPage />}
      />
      <Route
        path="/copro-spain/:country/document-manager/outgoing-documents/view"
        element={<ViewOutgoingDocumentPage />}
      />
      <Route
        path="/copro-spain/:country/document-manager/incoming-documents/:docId"
        element={<ViewDocumentPage />}
      />
      <Route
        path="/copro-spain/:country/document-manager/incoming-documents/manual/:fileName/:countryCode"
        element={<ManualEntryPage />}
      />
      <Route
        path="/copro-spain/:country/document-manager/generate-certificate"
        element={<GenerateCertificateDetails />}
      />

      <Route
        path="/config-center/coprocessing-spain"
        element={<CoprocessingSettingLandingPage />}
      />
      <Route
        path="/copro-spain/:country/mass-balance/:locationCode/:locationName/:materialCode?/:quarter?/:year?"
        element={<MassBalanceForLocation />}
      />

      <Route
        path="/copro-spain/:country/fifo-feedstock"
        element={<FeedstockLanding />}
      />

      <Route
        path="/copro-spain/:country/cost-calculation"
        element={<CostCalculationLanding />}
      />

      <Route
        path="/copro-spain/:country/cost-calculation/add-cost-parameters"
        element={<CostFeedstockParameters />}
      />
      <Route
        path="/link-to-purchase/spain/copro"
        element={
          <Feature
            flag={FEATURE_FLAGS.COPRO_LTP_NEW_FLOW}
            fallback={<LinkToPurchaseLandingPageOld />}
          >
            <LinkToPurchaseLandingPage />
          </Feature>
        }
      />
      <Route
        path="/link-to-purchase/:country/:division"
        element={<LinkToPurchaseLandingPage />}
      />
      {/* SAF PowerBi Routes */}

      <Route path="/saf/dashboard" element={<SafDashboard />} />
      <Route path="/powerbireport" element={<PowerBiReport />} />
      <Route path="/powerbiactualization" element={<PowerBiActualization />} />

      {/* SAF Routes */}

      <Route path="/saf/:country/dashboard/" element={<SAFLandingPage />} />
      <Route
        path="/saf/:country/document-manager/"
        element={<SAFDocumentPage />}
      />
      <Route
        path="/saf/:country/document-manager/:docId"
        element={<SAFDocumentViewPage />}
      />
      <Route
        path="/saf/:country/mass-balance/"
        element={<SAFMassBalancePage />}
      />
      <Route
        path="/saf/:country/mass-balance/:docId"
        element={<MassBalanceDetailViewPage />}
      />
      <Route
        path="/saf/:country/link-to-purchase/"
        element={<SAFLinkToPurchasePage />}
      />

      {/* Doc manager routes  START */}
      <Route
        path="/doc-manager/:country"
        element={<DocumentManagerDashboard />}
      />
      <Route
        path="/doc-manager/outgoing/:country"
        element={<OutgoingDocumentPageLatest />}
      />
      <Route
        path="/document-manager/nabisy/:country/:division"
        element={<NabisyDocumentRetrievalPage />}
      />
      <Route
        path="/document-manager/generated-view/:country/:division/:generatedFileName"
        element={<DocumentGeneratedViewPage />}
      />
      {/* Doc manager routes  END */}

      {/* Mass balance routes  START */}
      <Route
        path="/mass-balance/:country/:division/:type/:location/:period/:balance"
        element={<MassBalance />}
      />
      <Route
        path="/mass-balance/generate-certificate/:country/:division"
        element={<MBGenerateCertificateDetails />}
      />
      <Route
        path="/mass-balance/list/:country/:division"
        element={<MBList />}
      />
      <Route
        path="/mass-balance/outgoing-view/:country/:division/:outId"
        element={<ViewCertificatePage />}
      />
      {/* Mass balance routes  END */}

      {/* Mandate certificate routes  START */}
      <Route
        path="/mandate-certificate/:country/:division"
        element={<MandateCertificate />}
      />
      <Route
        path="/mandate-certificate/:country/:division/poc/:mandateAllocationEuId"
        element={<MandateCertificateGeneratePOC />}
      />
      <Route
        path="/mandate-certificate/:country/:division/ptd/:mandateAllocationEuId/:allocationGroupId/:customerName"
        element={<MandateCertificateGeneratePTD />}
      />
      <Route
        path="/mandate-allocation/:country/:division/:location/:period/:outboundType"
        element={<MandateAllocation />}
      />
      {/* Mandate certificate routes  END */}

      {/* Module dashboard routers START */}

      <Route
        path="/dashboard/:country/:division"
        element={<DivisionDashboard />}
      />

      {/* Module dashboard routers END */}

      {/* Compliance engine routes */}
      <Route
        path="/compliance-rules-engine"
        element={<ComplianceRulesLandingPage />}
      />

      <Route
        path="/compliance-rules-engine/versions/:ruleMasterId"
        element={<RulesVersionPage />}
      />

      <Route
        path="/compliance-rules-engine/rule-editor/:ruleVersionId"
        element={<RulesEditorLandingpage />}
      />

      <Route
        path="/compliance-rules-engine/:country/rule-version-test-page/:version"
        element={<RuleVersionTestPage />}
      />
      {/* Allocation routes  START */}
      <Route
        path="/allocation/:country/:division/:type/:location/:period/:balance"
        element={<Allocation />}
      />
      {/* Mass balance routes  END */}

      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/feature-country-selection/:module"
        element={<FeatureCountryDashboard />}
      />
      <Route path="/api-error-page/:errorMessage" element={<APIErrorPage />} />

      {/* Global Config Center Routes START */}
      <Route path="/configuration-center">
        <Route index element={<Navigate to="material-master" />} />

        {/* Materials paths */}
        <Route path="material-master" element={<MaterialMasterConfigPage />} />
        <Route
          path="national-registry"
          element={<NationalRegistryConfigPage />}
        />

        {/* Business Partner paths */}
        <Route
          path="business-partner"
          element={<BusinessPartnerConfigPage />}
        />
        <Route
          path="business-partner-link-lm"
          element={<BusinessPartnerLinkLmConfigPage />}
        />

        {/* Location details paths */}
        <Route path="country-code" element={<CountryCodeConfigPage />} />
        <Route
          path="country-variation"
          element={<CountryVariationConfigPage />}
        />
        <Route path="address" element={<BusinessPartnerAddressConfigPage />} />
        <Route path="locations" element={<LocationDetailsConfigPage />} />
        <Route path="processing-units" element={<ProcessingUnitConfigPage />} />

        {/* Emissions & Yield paths */}
        <Route
          path="product-default-values"
          element={<ProductDefaultValuesConfigPage />}
        />
        <Route
          path="product-baseline-emission"
          element={<ProductBaselineEmissionConfigPage />}
        />
        <Route
          path="product-emissions"
          element={<ProductionEmissionNewConfigPage />}
        />
        <Route
          path="transport-emissions"
          element={<TransportationEmissionsConfigPage />}
        />
        <Route
          path="location-yield-details"
          element={<LocationYieldDetailsConfigPage />}
        />

        {/* Mass balance paths */}
        <Route path="mb-balance-type" element={<MbBalanceTypeConfigPage />} />
        <Route path="mb-balance-group" element={<MbBalanceGroupConfigPage />} />
        <Route
          path="mass-balance-materials"
          element={<MassBalanceMaterialsConfigPage />}
        />
        <Route path="mb-config" element={<MBConfigPage />} />
        <Route path="mb-lg-location" element={<MbLgLocationPage />} />

        {/* Certification paths */}
        <Route path="certificate-id" element={<CertificateIdConfigPage />} />
        <Route
          path="certificate-scheme"
          element={<CertificateSchemeConfigPage />}
        />
        <Route
          path="certificate-scheme-variation"
          element={<CertificateSchemeVariationConfigPage />}
        />

        {/* Other paths */}
        <Route
          path="tax-plant-registry"
          element={<TaxPlantRegistryConfigPage />}
        />
      </Route>
      {/* Global Config Center Routes END */}
    </Routes>
  );
};

export default App;
