// FeatureFlagContext.js
import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({flags: initialFlags, children}) => {
  const [flags, setFlags] = useState(initialFlags);

  const updateFlag = useCallback((key, value) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      [key]: value,
    }));
  }, []);

  const contextValue = useMemo(() => {
    return {flags, setFlags: updateFlag};
  }, [flags, updateFlag]);

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagProvider.propTypes = {
  flags: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export const useFeatureFlag = (flag) => {
  const {flags} = useContext(FeatureFlagContext);
  return flags[flag];
};

export const useFeatureFlags = () => {
  const {flags} = useContext(FeatureFlagContext);
  return flags;
};

export const useSetFeatureFlag = () => {
  const {setFlags} = useContext(FeatureFlagContext);
  return setFlags;
};

export const handleStrategies = (strategies, attributeData = {}) => {
  let result = null;

  strategies.some((strategy) => {
    // Currently only handles the first attribute (can be improved to handle multiple attibutes)
    const attribute = strategy?.attributes[0];
    const attributeKey = attribute?.fieldName?.toLowerCase();
    const attributeValues = attribute?.values.map((value) =>
      value.toLowerCase(),
    );
    if (
      Object.hasOwn(attributeData, attributeKey) &&
      attributeValues.includes(attributeData[attributeKey].toLowerCase())
    ) {
      result = strategy.value;
    }

    return result;
  });

  return result;
};

export default FeatureFlagContext;
