import {Close} from "@bphxd/ds-core-react";
import outboundTypes from "constants/allocation";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import {NABISY} from "constants/massBalance";

import {ReversalMutationAPI} from "graphql/MassBalance/Actions/reversalMutation";
import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import {
  actionItems,
  massBalanceType,
  reversalEventNames,
  status,
} from "modules/GlobalMassBalance/constants";
import {reversalConfig} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const ReversalModal = ({
  modal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState();
  const [isFormBody, setIsFormBody] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {headerText, bodyTextArr, actionButtonText} = eventData || {
    headerText: "",
    bodyTextArr: [],
    actionButtonText: "",
    eventName: "",
    eventType: "",
  };

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);
  const [eventId, setEventId] = useState();

  const rowStatus = rowData?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    rowData?.original.inboundRecord?.fromMbLocationGroupId ?? null;

  const finalRowStatus =
    rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null
      ? status.AVAILABLE_FROM_TRANSFER
      : rowStatus;

  const linkError = rowData?.original.outboundRecords?.linkError;

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const backToMassBalanceButton = useMemo(
    () => ({
      id: 1,
      text: "Back to mass balance",
      buttonClassName: "!text-[#111]",
      action: () => {
        setShowResponseModal(false);
        setModal("");
      },
    }),
    [setModal],
  );

  const handleResponse = useCallback(
    (action) => {
      switch (action) {
        case finalRowStatus + actionItems.UNLINK_DOCUMENT:
          setResponseHeader("Purchase cannot be unlinked");
          setResponseBody(
            "An outgoing transaction has already been allocated to a part of this purchase. You need to ensure that the total purchase quantity is available before you can unlink it from the document.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.REMOVE_FROM_BALANCE:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            "Cannot reverse carry forward as outbound transaction exists",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.REMOVE_FROM_BALANCE + NABISY:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            "You need to remove document first from the sending location's mass balance before removing the allocation.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.UNRETIRE_FOR_MANDATE:
          setResponseHeader("Allocation cannot be unretired");
          setResponseBody(
            "The retired purchase has been already used for a customer mandate. Ensure that the retired purchase quantity is made available before unretiring it.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.UNRETIRE_FOR_VOLUME_SNS:
          setResponseHeader("Allocation cannot be unretired");
          setResponseBody(
            "The retired purchase has been already used for a customer mandate. Ensure that the retired purchase quantity is made available before unretiring it.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case status.TRANSFERRED + actionItems.REMOVE_DOCUMENT:
          setResponseHeader("Document cannot be removed");
          setResponseBody(
            "The transferred purchase has already been used for a customer allocation. Please ensure the transfer purchase quantity is made available before removing it.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case status.RETIRED + actionItems.REMOVE_DOCUMENT:
          setResponseHeader("Document cannot be removed");
          setResponseBody(
            "The retired purchase has already been used for a customer mandate. Ensure that the retired purchase quantity is made available before removing it.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        default:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            <>
              The purchase has been transferred to another mass balance
              location. You need to transfer it back from{" "}
              <u>the receiving location mass balance.</u>
            </>,
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
      }
    },
    [backToMassBalanceButton, finalRowStatus],
  );

  const isDocumentGenerated = useMemo(() => {
    return (
      rowData?.original?.outboundRecords?.document !== null &&
      rowData?.original?.outboundRecords?.document !== "IN PROGRESS"
    );
  }, [rowData?.original?.outboundRecords?.document]);

  const isIncomingDocumentGenerated = useMemo(() => {
    return rowData?.original?.inboundRecord?.incomingDocument !== null;
  }, [rowData?.original?.inboundRecord?.incomingDocument]);

  const setEventDataCallback = useCallback(
    (config, linkError, rowStatusValue) => {
      let customEventName = config?.eventName;

      if (!linkError) {
        return {...config};
      }
      if (
        rowStatusValue === status.AVAILABLE &&
        (linkError === "PURCHASE" || linkError === "PURCHASE_SALES")
      ) {
        customEventName = reversalEventNames.INVALID_PURCHASE;
      }
      if (
        rowStatusValue === status.ALLOCATED &&
        !isDocumentGenerated &&
        (linkError === "SALES" || linkError === "PURCHASE_SALES")
      ) {
        customEventName =
          divisionCode === DIV_CODE_SAF
            ? reversalEventNames.INVALID_SALE
            : config?.eventName;
      }

      return {
        ...config,
        eventName: customEventName,
      };
    },
    [divisionCode, isDocumentGenerated],
  );

  const {
    actualizationId,
    certificateInboundEuId,
    mbOutboundQtyEuId,
    certificateOutboundEuId,
    outboundType,
    allocationEventId,
    showFormData,
    mbInboundQtyEuId,
    mbBalanceTypeCode,
    isNabisy,
    isRemoveBalanceNotPossible,
  } = useMemo(() => {
    const actualizationId = rowData?.original?.inboundRecord?.actualizationId;
    const certificateInboundEuId =
      rowData?.original?.inboundRecord?.certificateInboundEuId;
    const mbOutboundQtyEuId =
      rowData?.original?.outboundRecords?.mbOutboundQtyEuId;
    const certificateOutboundEuId =
      rowData?.original?.outboundRecords?.certificateOutboundEuId;

    let outboundType = rowData?.original?.outboundRecords?.outboundType;
    if (outboundType === outboundTypes.SALE) {
      outboundType = outboundTypes.VOLUNTARY;
    }

    const allocationEventId =
      finalRowStatus === status.ALLOCATED_AND_SENT || isDocumentGenerated
        ? certificateOutboundEuId
        : outboundType === outboundTypes.LOCATION_TRANSFER
        ? rowData?.original?.inboundRecord?.mbInboundQtyEuId
        : rowData?.original?.outboundRecords?.mbOutboundQtyEuId;

    const showFormData = outboundType === outboundTypes.LOCATION_TRANSFER;

    const mbInboundQtyEuId = rowData?.original?.inboundRecord?.mbInboundQtyEuId;
    const mbBalanceTypeCode =
      currentFilterValues?.mbBalanceTypeCode?.toLowerCase();
    const isNabisy =
      rowData?.original?.outboundRecords?.isReceipientPPOSExist !== null;

    const isRemoveBalanceNotPossible =
      finalRowStatus === status.AVAILABLE_FROM_TRANSFER &&
      isNabisy &&
      isIncomingDocumentGenerated;

    return {
      actualizationId,
      certificateInboundEuId,
      mbOutboundQtyEuId,
      certificateOutboundEuId,
      outboundType,
      allocationEventId,
      showFormData,
      mbInboundQtyEuId,
      mbBalanceTypeCode,
      isNabisy,
      isRemoveBalanceNotPossible,
    };
  }, [
    rowData?.original?.inboundRecord?.actualizationId,
    rowData?.original?.inboundRecord?.certificateInboundEuId,
    rowData?.original?.inboundRecord?.mbInboundQtyEuId,
    rowData?.original?.outboundRecords?.mbOutboundQtyEuId,
    rowData?.original?.outboundRecords?.certificateOutboundEuId,
    rowData?.original?.outboundRecords?.outboundType,
    rowData?.original?.outboundRecords?.isReceipientPPOSExist,
    finalRowStatus,
    isDocumentGenerated,
    isIncomingDocumentGenerated,
    currentFilterValues?.mbBalanceTypeCode,
  ]);

  useEffect(() => {
    switch (modal) {
      case divisionCode + actionItems.UNLINK_DOCUMENT:
        setShowModal(true);
        setEventData(
          setEventDataCallback(
            reversalConfig[finalRowStatus + actionItems.UNLINK_DOCUMENT],
            linkError,
            finalRowStatus,
          ),
        );
        handleResponse(finalRowStatus + actionItems.UNLINK_DOCUMENT);
        setEventId(
          linkError === "PURCHASE" || linkError === "PURCHASE_SALES"
            ? actualizationId
            : certificateInboundEuId,
        );
        break;
      case divisionCode + actionItems.REMOVE_FROM_BALANCE:
        setShowModal(!isRemoveBalanceNotPossible);
        setEventData(
          reversalConfig[
            isNabisy
              ? finalRowStatus + actionItems.REMOVE_FROM_BALANCE + NABISY
              : finalRowStatus + actionItems.REMOVE_FROM_BALANCE
          ],
        );
        setIsFormBody(true);
        setEventId(mbInboundQtyEuId);
        handleResponse(
          isRemoveBalanceNotPossible
            ? finalRowStatus + actionItems.REMOVE_FROM_BALANCE + NABISY
            : finalRowStatus + actionItems.REMOVE_FROM_BALANCE,
        );
        setShowResponseModal(isRemoveBalanceNotPossible);
        break;
      case divisionCode + actionItems.REMOVE_DOCUMENT:
        setShowModal(true);
        setEventData(
          reversalConfig[
            isNabisy
              ? finalRowStatus + actionItems.REMOVE_DOCUMENT + NABISY
              : finalRowStatus + actionItems.REMOVE_DOCUMENT
          ],
        );
        setEventId(isNabisy ? mbInboundQtyEuId : certificateOutboundEuId);
        handleResponse(finalRowStatus + actionItems.REMOVE_DOCUMENT);
        break;
      case divisionCode + actionItems.REMOVE_ALLOCATION:
        setShowModal(true);
        setEventData(
          setEventDataCallback(
            reversalConfig[
              finalRowStatus === status.ALLOCATED_AND_SENT ||
              isDocumentGenerated
                ? finalRowStatus + actionItems.REMOVE_ALLOCATION
                : finalRowStatus + actionItems.REMOVE_ALLOCATION + outboundType
            ],
            linkError,
            finalRowStatus,
          ),
        );
        setEventId(
          linkError === "SALES" || linkError === "PURCHASE_SALES"
            ? mbOutboundQtyEuId
            : allocationEventId,
        );
        handleResponse(finalRowStatus + actionItems.REMOVE_ALLOCATION);
        setIsFormBody(showFormData);
        break;
      case divisionCode + actionItems.UNRETIRE_FOR_VOLUME_SNS:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.UNRETIRE_FOR_VOLUME_SNS],
        );
        setEventId(mbOutboundQtyEuId);
        handleResponse(finalRowStatus + actionItems.UNRETIRE_FOR_VOLUME_SNS);
        break;
      case divisionCode + actionItems.REMOVE_WRITE_OFF:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_WRITE_OFF],
        );
        setEventId(mbOutboundQtyEuId);
        handleResponse();
        break;
      case divisionCode + actionItems.REMOVE_RESERVATION:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_RESERVATION],
        );
        setEventId(mbOutboundQtyEuId);
        handleResponse();
        break;
      case divisionCode + actionItems.UNRETIRE_FOR_MANDATE:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.UNRETIRE_FOR_MANDATE],
        );
        setEventId(mbOutboundQtyEuId);
        handleResponse(finalRowStatus + actionItems.UNRETIRE_FOR_MANDATE);
        break;
      case divisionCode + actionItems.REMOVE_TRANSFER:
        handleResponse(finalRowStatus + actionItems.REMOVE_TRANSFER);
        setShowResponseModal(true);
        break;
      case divisionCode + actionItems.REMOVE_PURCHASE:
        setShowModal(true);
        setEventData(
          reversalConfig[
            mbBalanceTypeCode === massBalanceType.TRS
              ? finalRowStatus +
                actionItems.REMOVE_PURCHASE +
                massBalanceType.TRS
              : finalRowStatus + actionItems.REMOVE_PURCHASE
          ],
        );
        setEventId(actualizationId);
        handleResponse();
        break;
      case divisionCode + actionItems.REMOVE_OUTGOING:
        setShowModal(true);
        setEventData(
          reversalConfig[
            showFormData
              ? finalRowStatus + actionItems.REMOVE_OUTGOING + outboundType
              : finalRowStatus + actionItems.REMOVE_OUTGOING
          ],
        );
        setEventId(mbOutboundQtyEuId);
        handleResponse();
        break;
      default:
        setShowModal(false);
        break;
    }
  }, [
    divisionCode,
    handleResponse,
    modal,
    finalRowStatus,
    linkError,
    actualizationId,
    certificateInboundEuId,
    mbOutboundQtyEuId,
    certificateOutboundEuId,
    outboundType,
    showFormData,
    setEventDataCallback,
    allocationEventId,
    mbInboundQtyEuId,
    isDocumentGenerated,
    mbBalanceTypeCode,
    isNabisy,
    isRemoveBalanceNotPossible,
  ]);

  const onSubmit = async () => {
    setIsLoading(true);
    const {data} = await ReversalMutationAPI({
      event: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
        mbPeriodId: currentFilterValues?.mbPeriodId,
        eventName: eventData.eventName,
        eventType: eventData.eventType,
        eventId,
        reason: isFormBody ? getValues("reason") : undefined,
      },
    });

    const statusCode = data?.bioLcGlobalMassBalanceReversal?.statusCode;

    // Need to check with yuri
    if (statusCode === 400) {
      setShowResponseModal(true);
    }
    if (statusCode === 500) {
      toast.error("An error occurred. Please try again later.");
    }
    if (statusCode === 200) {
      toast.success("Successfully reversed.");
      client.refetchQueries({
        include: [
          "bioLcGetMassBalanceTransactionData",
          "bioLcGetMassBalanceCounts",
        ],
      });
      setModal("");
    }

    setShowModal(false);
    setIsLoading(false);
  };

  const modalSize = isFormBody ? "330px" : "330px";
  const textAlignment = isFormBody ? "text-left" : "text-center";

  return (
    <>
      <Modal
        size="sm"
        isOpen={showModal}
        className={`modal-dialog-centered [&>div]:w-[${modalSize}]`}
      >
        {isFormBody && (
          <ModalHeader
            className="!border-b-[1px] !border-gray-200 mb-2 text-xl"
            close={<Close onClick={() => setShowModal(false)} />}
          >
            {headerText}
          </ModalHeader>
        )}
        <ModalBody className={`flex flex-col items-center w-[${modalSize}]`}>
          {!isFormBody && <p className="mb-[2px]">{headerText}</p>}
          <p className={`text-[13px] ${textAlignment} text-gray-800 mb-0`}>
            {bodyTextArr.map((text, index) => (
              <span key={index}>
                {text}
                {index < bodyTextArr.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </span>
            ))}
          </p>
          {isFormBody && (
            <div className="w-full">
              <FormProvider {...methods}>
                <Form>
                  <Label className="mt-6" for="reason">
                    Reason for removal
                  </Label>
                  <Input
                    id="reason"
                    type="textarea"
                    rows="3"
                    {...computeProps("reason", {
                      required: "Reason for removal is required",
                    })}
                    invalid={!!errors.reason}
                  />
                  {errors.reason && (
                    <FormFeedback>{errors.reason.message}</FormFeedback>
                  )}
                </Form>
              </FormProvider>
            </div>
          )}
        </ModalBody>

        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setShowModal(false);
                  setModal("");
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                onClick={() => {
                  trigger().then((isValid) => {
                    if (isValid) {
                      onSubmit();
                    }
                  });
                }}
                disabled={errors.reason || isLoading}
              >
                {isLoading && <Spinner size="sm" className="btn-icon-prefix" />}
                {actionButtonText}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}
    </>
  );
};

ReversalModal.propTypes = {
  modal: PropTypes.string,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default ReversalModal;
