import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";

import {
  getFilterValue,
  getStatusColor,
  getStatusValue,
} from "modules/GlobalMassBalance/utils";

import moment from "moment";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

import {NUMBER_OF_DECIMALS} from "constants/common";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import ActionDropdown from "../ActionDropdown";
import "./index.scss";

const mandateCertificateColumnsSAF = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  navigateToAllocation,
  currentFilterValues,
  siteReferenceId,
  divisionId,
  division,
) => [
  {
    header: "Incoming",
    id: "MandateIncoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.inboundRecords?.physicalReceiptDate;
        },
        cell: ({row}) => {
          return (
            row?.original.inboundRecords?.physicalReceiptDate &&
            moment(row?.original.inboundRecords?.physicalReceiptDate).format(
              dateFormat,
            )
          );
        },
      },
      {
        header: "Document",
        accessorKey: "documentName",
        key: "documentName",
        visible: true,
        size: 500,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.inboundRecords?.documentName || "";
        },
        cell: ({cell, row}) => {
          const {certificateOutboundEuId} = row.original.inboundRecords;
          const hideSendBtn = true;
          const tooltipId = `documentName${row?.id}`;
          const pathName = certificateOutboundEuId
            ? appendParamsToUrl(
                "/mass-balance/outgoing-view",
                [selectedCountry, division, certificateOutboundEuId],
                {
                  page: "mandate-certificate",
                  redirectTo: appendParamsToUrl(
                    "/mandate-certificate",
                    [selectedCountry, division],
                    {
                      location: currentFilterValues.mbLocationGroupName,
                      period: currentFilterValues.periodName,
                      outboundType: currentFilterValues.outboundTypeName,
                    },
                  ),
                  hideSendBtn: "true",
                },
              )
            : appendParamsToUrl(
                "/document-manager/incoming",
                [
                  selectedCountry,
                  "detailedView",
                  row?.original?.inboundRecords?.certificateInboundEuId,
                ],
                {siteReferenceId, divisionId},
              );
          return (
            <>
              <Link to={pathName} className="link-dark" id={tooltipId}>
                <u className="flex">
                  {row?.original.inboundRecords?.documentName && <FilePdf24 />}
                  <ColumnHighlight
                    value={row?.original.inboundRecords?.documentName}
                    columnFilter={getFilterValue(cell, "documentName")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {row?.original.inboundRecords?.documentName ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.inboundRecords?.rawMaterial;
        },
        cell: ({row}) => {
          return row?.original.inboundRecords?.rawMaterial;
        },
      },
      {
        header: "Country of origin",
        accessorKey: "originCountryName",
        key: "originCountryName",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.inboundRecords?.originCountryName;
        },
        cell: ({row}) => {
          return row?.original.inboundRecords?.originCountryName;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotal",
        key: "ghgTotal",
        visible: true,
        dataType: "number",
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.inboundRecords?.ghgTotal?.toString();
        },
        cell: ({row}) => {
          return getFormattedNumberWithCheck(
            row?.original?.inboundRecords?.ghgTotal,
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Retired quantity (in) m³",
        accessorKey: "retiredQty",
        key: "retiredQty",
        visible: true,
        disabled: true,
        dataType: "number",
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.inboundRecords?.retiredQty?.toString();
        },
        cell: ({row}) => {
          return getFormattedNumberWithCheck(
            row?.original?.inboundRecords?.retiredQty,
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
  {
    header: "Outgoing",
    id: "MandateOutgoing",
    columns: [
      {
        header: "Quantity(out) m³",
        accessorKey: "quantityOutM3",
        key: "quantityOutM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecord?.quantityOutM3?.toString();
        },
        cell: ({row}) => {
          return getFormattedNumberWithCheck(
            row?.original?.outboundRecord?.quantityOutM3,
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.outboundRecord?.outboundType;
        },
        cell: ({row}) => {
          return row?.original.outboundRecord?.outboundType;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.outboundRecord?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecord?.recipient;
        },
      },
      {
        header: "Document",
        accessorKey: "documentOutgoing",
        key: "documentOutgoing",
        visible: true,
        accessorFn: (row) => {
          return row?.outboundRecord?.documentName || "";
        },
        cell: ({cell, row}) => {
          const isInprogress =
            row?.original?.outboundRecord?.documentName === "IN PROGRESS";
          return isInprogress ? (
            "Document processing…"
          ) : (
            <Link
              to={appendParamsToUrl(
                "/mass-balance/outgoing-view",
                [
                  selectedCountry,
                  division,
                  row?.original?.outboundRecord?.certificateOutboundEuId,
                ],
                {
                  page: "mandate-certificate",

                  redirectTo: appendParamsToUrl(
                    "/mandate-certificate",
                    [selectedCountry, division],
                    {
                      location: currentFilterValues.mbLocationGroupName,
                      period: currentFilterValues.periodName,
                      outboundType: currentFilterValues.outboundTypeName,
                    },
                  ),
                },
              )}
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecord?.documentName && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecord?.documentName}
                  columnFilter={getFilterValue(cell, "documentName")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.outboundRecord?.status;
        },
        cell: ({row}) => {
          const currentStatus = row?.original?.outboundRecord?.status;

          const bgColor = getStatusColor(currentStatus);
          return (
            <span
              className={`whitespace-nowrap uppercase status-badge-mass-balance disabled:text-white ${bgColor}`}
            >
              {getStatusValue(currentStatus)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return (
            <ActionDropdown
              row={row}
              // table={table}
              navigateToAllocation={navigateToAllocation}
              currentFilterValues={currentFilterValues}
            />
          );
        },
      },
    ],
  },
];

export default mandateCertificateColumnsSAF;
