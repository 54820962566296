import PropTypes from "prop-types";

const SectionWrapper = ({children, tableTitle}) => (
  <div data-test="section-wrapper" className="bp-core flex flex-col">
    <div className="section-header">
      <div data-test="section-title" className="section-titles">
        {tableTitle}
      </div>
    </div>
    <div>{children}</div>
  </div>
);

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tableTitle: PropTypes.string.isRequired,
};

export default SectionWrapper;
