import PropTypes from "prop-types";
import {Input} from "reactstrap";

// Helper function to get the unique ID
const getUniqueIdNumber = (type, data) => {
  return type === "Incoming"
    ? data?.original?.certificate_inbound_eu_id?.trim()
    : data?.original?.purchase_layer_id?.trim();
};

// Helper function to get the contract number
const getContractNumber = (type, data) => {
  return type === "Incoming"
    ? data?.original?.contract_number?.trim()
    : data?.original?.deal_number?.trim();
};

// Helper function to get the balance group
const getBalanceGroup = (type, data) => {
  return type === "Incoming"
    ? data?.original?.incoming_balance_group_name?.trim()
    : data?.original?.purchase_balance_group_name?.trim();
};

// Helper function to get the location group
const getLocationGroup = (type, data) => {
  return type === "Incoming"
    ? data?.original?.incoming_location_group_name?.trim()
    : data?.original?.purchase_location_group_name?.trim();
};

// Helper function to get the UOM
const getUOM = (type, data) => {
  return type === "Incoming" && data?.original?.product_qty_uom?.trim();
};

const Checkbox = ({row, table, type, otherRows, otherData}) => {
  const isOtherRows = Object.keys(otherRows).length > 0;
  const isOtherData = Object.keys(otherRows)
    .map((index) => Number(index))
    .map((index) => otherData[index]);

  const otherDataBalanceGroup =
    type === "Incoming"
      ? isOtherData?.find((item) => item?.purchase_balance_group_name?.trim())
          ?.purchase_balance_group_name
      : isOtherData?.find((item) => item?.incoming_balance_group_name?.trim())
          ?.incoming_balance_group_name;

  const otherDataLocationGroup =
    type === "Incoming"
      ? isOtherData?.find((item) => item?.purchase_location_group_name?.trim())
          ?.purchase_location_group_name
      : isOtherData?.find((item) => item?.incoming_location_group_name?.trim())
          ?.incoming_location_group_name;

  const selectedOtherData =
    type === "Incoming"
      ? isOtherData?.find((item) => item?.deal_number)?.deal_number?.trim()
      : isOtherData
          ?.find((item) => item?.contract_number)
          ?.contract_number?.trim();

  const isSelected = row.getIsSelected();
  const isSomeRowsSelected = table.getSelectedRowModel().rows?.length > 0;

  const selectedRowData = table
    .getSelectedRowModel()
    .rows?.find((item) => getUniqueIdNumber(type, item));

  const selectedRowContractNumber = getContractNumber(type, selectedRowData);
  const rowContractNumber = getContractNumber(type, row);

  const selectedRowBalanceGroup = getBalanceGroup(type, selectedRowData);
  const rowBalanceGroup = getBalanceGroup(type, row);

  const selectedRowLocationGroup = getLocationGroup(type, selectedRowData);
  const rowLocationGroup = getLocationGroup(type, row);

  const selectedRowUOM = getUOM(type, selectedRowData);
  const rowUOM = getUOM(type, row);

  const handleChange = row.getToggleSelectedHandler();

  // Utility function to check if a value is available (not undefined, null, or empty)
  const isValueAvailable = (value) => {
    return value !== undefined && value !== null && value !== "";
  };

  const checkWithinSameTable =
    isSomeRowsSelected &&
    !isSelected &&
    ((isValueAvailable(selectedRowContractNumber) &&
      isValueAvailable(rowContractNumber) &&
      selectedRowContractNumber !== rowContractNumber) ||
      (isValueAvailable(rowBalanceGroup) &&
        isValueAvailable(selectedRowBalanceGroup) &&
        rowBalanceGroup !== selectedRowBalanceGroup) ||
      (isValueAvailable(rowLocationGroup) &&
        isValueAvailable(selectedRowLocationGroup) &&
        rowLocationGroup !== selectedRowLocationGroup) ||
      (type === "Incoming" &&
        isValueAvailable(selectedRowUOM) &&
        isValueAvailable(rowUOM) &&
        selectedRowUOM !== rowUOM));

  const checkWithOtherTable =
    (isOtherRows &&
      isValueAvailable(rowBalanceGroup) &&
      isValueAvailable(otherDataBalanceGroup) &&
      rowBalanceGroup !== otherDataBalanceGroup) ||
    (isValueAvailable(rowLocationGroup) &&
      isValueAvailable(otherDataLocationGroup) &&
      rowLocationGroup !== otherDataLocationGroup) ||
    (isValueAvailable(selectedOtherData) &&
      isValueAvailable(rowContractNumber) &&
      selectedOtherData !== rowContractNumber);

  // Combine the results of both checks
  const isDisabled = checkWithinSameTable || checkWithOtherTable;

  return (
    <Input
      type="checkbox"
      checked={isSelected}
      onChange={handleChange}
      disabled={isDisabled}
    />
  );
};

Checkbox.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  type: PropTypes.string,
  otherRows: PropTypes.object,
  otherData: PropTypes.array,
};

export default Checkbox;
