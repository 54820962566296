export default [
  {
    label: "BP - Cherry point Feedstock Tracking",
    to: "/product-metrics",
    "data-test": "bp-shipment-tracking-report-tab",
  },
  {
    label: "Ceemas MOT Validation",
    to: "/product-metrics/ceemas-mot-validation",
    "data-test": "tab-ceemas-mot-validation",
  },
  {
    label: "BVC Report",
    to: "/reporting/bvc-report",
    "data-test": "tab-bvc-report",
  },
  // {
  //   label: "MOT Manual Adjustment",
  //   to: "/product-metrics/mot-manual-adjustment",
  //   "data-test": "tab-mot-manual-adjustment",
  // },
  {
    label: "SAF Power BI Report",
    to: "/saf/dashboard",
    "data-test": "saf-power-bi-report",
  },
];
