export const checkValidParameter = (value) => value || "";

export const checkValidDataList = (value) => value && value[0];

export const checkValidArray = (value) => value ?? [];

export const checkDataReturnEmpty = (check, value) => (check ? [] : value);

export const checkFileTooLarge = (value, maxSize) =>
  value.length > 0 && value[0].file.size > maxSize;

export const checkVisibleWarningModal = (csvUploadWarrnings, csvUploadErrors) =>
  !!csvUploadWarrnings.length && !csvUploadErrors.length;

export const checkVisibleErrorModal = (csvUploadErrors) =>
  !!csvUploadErrors.length;

export const getFileUploadButtonStyle = (acceptedFiles, comment, isCsvValid) =>
  acceptedFiles?.length && comment && isCsvValid
    ? "hover:opacity-50 bg-bp-green-800"
    : "disabled:opacity-50 cursor-not-allowed bg-gray-800";

export const checkFileUploadButtonDisable = (
  acceptedFiles,
  comment,
  isCsvValid,
) => !(acceptedFiles?.length && comment && isCsvValid);

export const checkFileType = (filesType) =>
  filesType === "csv" ? ".csv" : "application/pdf";

export const getPreviewURL = (acceptedFiles, filesType) =>
  acceptedFiles?.length > 0 && filesType !== "csv"
    ? URL.createObjectURL(acceptedFiles[0])
    : "";

export const areValuesEqualIgnoreCase = (value1, value2) => {
  if (value1 == null || value2 == null) {
    return false;
  }
  return value1.toLowerCase() === value2.toLowerCase();
};

export default {checkValidParameter};
