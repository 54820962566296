import {COUNTRY_SPAIN} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import _ from "lodash";
import moment from "moment";
import {getFormattedNumberWithCheck} from "utils/numberUtil";

import outboundTypes from "constants/allocation";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {NABISY} from "constants/massBalance";
import mandateCertificateColumnsSAF from "modules/GlobalMandateCertificates/components/Table/mandateCertificateColumnsSAF";
import mbListColumnsCopro from "./components/MBListTable/Columns/mbListColumnsCopro";
import mbListColumnsSAF from "./components/MBListTable/Columns/mbListColumnsSAF";
import {
  COPROCESSED_RECORD,
  DATE_RANGE_SIZE,
  actionItems,
  massBalanceType,
  parentMapping,
  reversalEventNames,
  reversalEventTypes,
  status,
} from "./constants";

export const getFilterValue = (cell, filterId) => {
  const context = cell.getContext();
  const tableState = context ? context.table.getState() : null;
  const columnFilters = tableState ? tableState.columnFilters : [];

  const filterItem = columnFilters.find((item) => item.id === filterId);

  return filterItem ? filterItem.value : null;
};

export const getStatusColor = (statusValue) => {
  switch (statusValue) {
    case status.PENDING:
      return "bg-[#fffff0]";
    case status.AVAILABLE:
      return "bg-[#fffff0]";
    case status.AVAILABLE_CO:
      return "bg-[#fffff0]";
    case status.AVAILABLE_FROM_TRANSFER:
      return "bg-[#fffff0]";
    case status.ALLOCATED:
      return "bg-[#ecfafe]";
    case status.ALLOCATED_AND_SENT:
      return "bg-[#ecfafe]";
    case status.RESERVED:
      return "bg-[#ecfafe]";
    case status.TRANSFERRED:
      return "bg-[#ecfafe]";
    case status.TRANSFERRED_AND_SENT:
      return "bg-[#ecfafe]";
    case status.RETIRED:
      return "bg-[#ecfafe]";
    case status.WRITE_OFF:
      return "bg-[#ededed]";
    case status.CARRIED_OVER:
      return "bg-[#ededed]";
    case status.INVALID_PURCHASE:
      return "bg-[#ffedf0]";
    case status.INVALID_OUTGOING:
      return "bg-[#ffedf0]";
    case status.RETIRED_FOR_SNS:
      return "bg-[#ecfafe]";
    case status.CARRY_OVER:
      return "bg-[#ededed]";
    default:
      return null;
  }
};

export const getStatusValue = (statusValue) => {
  switch (statusValue) {
    case status.PENDING:
      return "Pending";
    case status.AVAILABLE:
      return "Available";
    case status.AVAILABLE_CO:
      return "Available from carry over";
    case status.AVAILABLE_FROM_TRANSFER:
      return "Available from transfer";
    case status.ALLOCATED:
      return "Allocated";
    case status.ALLOCATED_AND_SENT:
      return "Allocated & sent";
    case status.RESERVED:
      return "Reserved";
    case status.TRANSFERRED:
      return "Transferred";
    case status.TRANSFERRED_AND_SENT:
      return "Transferred & sent";
    case status.RETIRED:
      return "retired";
    case status.WRITE_OFF:
      return "Written off";
    case status.CARRIED_OVER:
      return "Carried over";
    case status.INVALID_PURCHASE:
      return "Invalid purchase";
    case status.INVALID_OUTGOING:
      return "Invalid Outgoing";
    case status.RETIRED_FOR_SNS:
      return "Retired for SNS";
    case status.CARRY_OVER:
      return "Carry over";
    default:
      return null;
  }
};

export const getUniqueListWithoutParent = (data, key) => {
  const allData = data?.map((item) => item[key]).filter(Boolean);
  const uniqueData = [...new Set(allData)];
  return uniqueData;
};

export const getUniqueLists = (data, keyPaths) => {
  const result = {};
  let newKeyPaths = keyPaths;

  // Ensure keyPaths is an array, even if a single string is passed
  if (typeof newKeyPaths === "string") {
    newKeyPaths = [newKeyPaths];
  }

  newKeyPaths.forEach((keyPath) => {
    const keys = keyPath.split(".");

    // Extract all values for the given keyPath using reduce and flatMap
    const values = _.flatMap(data, (item) =>
      keys.reduce((value, key) => {
        if (Array.isArray(value)) {
          return _.flatMap(value, (v) => v[key]);
        }
        return value ? value[key] : undefined;
      }, item),
    );

    // Handle cases where values can be objects or arrays
    const uniqueValues = _.uniqWith(values.filter(Boolean), _.isEqual);

    // Use the last part of the keyPath as the key for the result object
    const key = keys[keys.length - 1];
    result[key] = uniqueValues;
  });

  return result;
};

export const getKeyNames = (column) => {
  const parentKeyName = parentMapping[column?.parent?.id] || COPROCESSED_RECORD;
  const keyName = column.id;
  return {parentKeyName, keyName};
};

export function createDateFromDDMMYYYY(dateString, dateFormat = "DD/MM/YYYY") {
  const parts = dateString.split("/");
  // Ensure parts array has 3 elements
  if (parts.length !== 3) {
    throw new Error(
      "Invalid date format. Please provide date in DD/MM/YYYY format.",
    );
  }

  let day;
  let month;
  let year;

  switch (dateFormat) {
    case "DD/MM/YYYY":
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
      year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    case "MM/DD/YYYY":
      month = parseInt(parts[0], 10) - 1; // Month is 0-indexed in JavaScript
      day = parseInt(parts[1], 10);
      year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    case "YYYY/MM/DD":
      year = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
      day = parseInt(parts[2], 10);
      return new Date(year, month, day);
    default:
      day = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
      year = parseInt(parts[2], 10);
      return new Date(year, month, day);
  }
}

export const customDateFilterFn = (row, columnId, value, dateFormat) => {
  if (value && value.length === DATE_RANGE_SIZE && row.getValue(columnId)) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    const date = createDateFromDDMMYYYY(
      moment(row.getValue(columnId)).format(dateFormat),
      dateFormat,
    );
    return date >= startDate && date <= endDate;
  }
  return true;
};

export const customDateFilterFnOutgoing = (rowData, value, dateFormat) => {
  if (value && value.length === DATE_RANGE_SIZE && rowData) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    const date = createDateFromDDMMYYYY(
      moment(rowData).format(dateFormat),
      dateFormat,
    );
    return date >= startDate && date <= endDate;
  }
  return true;
};

const {
  LINK_DOCUMENT,
  VIEW_DETAILS,
  UNLINK_DOCUMENT,
  ALLOCATE,
  EDIT_RESERVATION,
  GENERATE_DOCUMENT,
  REMOVE_ALLOCATION,
  REMOVE_DOCUMENT,
  REMOVE_FROM_BALANCE,
  REMOVE_OUTGOING,
  REMOVE_PURCHASE,
  REMOVE_RESERVATION,
  REMOVE_WRITE_OFF,
  RESERVE,
  RETIRE_FOR_MANDATE,
  TRANSFER,
  UNRETIRE_FOR_MANDATE,
  WRITE_OFF,
  REMOVE_TRANSFER,
  RETIRE_FOR_MANDATE_SNS,
  UNRETIRE_FOR_VOLUME_SNS,
} = actionItems;

export const actionConfig = {
  [status.PENDING + DIV_CODE_SAF]: {
    visible: [LINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
    enabled: [LINK_DOCUMENT],
  },
  [status.PENDING + DIV_CODE_COPRO]: {
    visible: [
      LINK_DOCUMENT,
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [LINK_DOCUMENT],
  },
  [status.AVAILABLE + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      REMOVE_FROM_BALANCE,
      VIEW_DETAILS,
      ALLOCATE,
      WRITE_OFF,
    ],
    enabled: [
      UNLINK_DOCUMENT,
      REMOVE_FROM_BALANCE,
      VIEW_DETAILS,
      ALLOCATE,
      WRITE_OFF,
    ],
  },
  [status.AVAILABLE + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      RETIRE_FOR_MANDATE_SNS,
    ],
    enabled: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      RETIRE_FOR_MANDATE_SNS,
    ],
  },
  [status.AVAILABLE + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
  },
  [status.RESERVED + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      ALLOCATE,
      EDIT_RESERVATION,
      REMOVE_RESERVATION,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, ALLOCATE, EDIT_RESERVATION, REMOVE_RESERVATION],
  },
  [status.ALLOCATED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      GENERATE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION, GENERATE_DOCUMENT],
  },
  [status.ALLOCATED + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION],
  },
  // This will be used when reversals are implemented
  // [status.ALLOCATED + DIV_CODE_GF]: {
  //   visible: [VIEW_DETAILS, REMOVE_ALLOCATION, RETIRE_FOR_MANDATE, WRITE_OFF],
  //   enabled: [VIEW_DETAILS, REMOVE_ALLOCATION],
  // },
  [status.ALLOCATED + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.RETIRED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      UNRETIRE_FOR_MANDATE,
      GENERATE_DOCUMENT,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE, GENERATE_DOCUMENT],
  },
  // This will be used when reversals are implemented
  // [status.RETIRED + DIV_CODE_GF]: {
  //   visible: [VIEW_DETAILS, REMOVE_ALLOCATION, UNRETIRE_FOR_MANDATE, WRITE_OFF],
  //   enabled: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE],
  // },
  [status.RETIRED + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE],
  },
  [status.RETIRED_FOR_SNS + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, UNRETIRE_FOR_VOLUME_SNS],
    enabled: [VIEW_DETAILS, UNRETIRE_FOR_VOLUME_SNS],
  },
  [status.TRANSFERRED + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS],
  },
  [status.GENERATED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      REMOVE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION, REMOVE_DOCUMENT],
  },
  [status.WRITE_OFF + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, REMOVE_WRITE_OFF],
    enabled: [VIEW_DETAILS, REMOVE_WRITE_OFF],
  },
  [status.WRITE_OFF + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      REMOVE_WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, REMOVE_WRITE_OFF],
  },
  [status.WRITE_OFF + DIV_CODE_GF]: {
    visible: [
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      RETIRE_FOR_MANDATE,
      REMOVE_WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, REMOVE_WRITE_OFF],
  },
  [status.AVAILABLE_CO + DIV_CODE_SAF]: {
    visible: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
    enabled: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
  },
  [status.AVAILABLE_CO + DIV_CODE_COPRO]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
      RETIRE_FOR_MANDATE_SNS,
    ],
    enabled: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
      RETIRE_FOR_MANDATE_SNS,
    ],
  },
  // This will be used when reversals are implemented
  // [status.AVAILABLE_CO + DIV_CODE_GF]: {
  //   visible: [
  //     VIEW_DETAILS,
  //     ALLOCATE,
  //     RETIRE_FOR_MANDATE,
  //     WRITE_OFF,
  //     REMOVE_FROM_BALANCE,
  //   ],
  //   enabled: [
  //     VIEW_DETAILS,
  //     ALLOCATE,
  //     RETIRE_FOR_MANDATE,
  //     WRITE_OFF,
  //     REMOVE_FROM_BALANCE,
  //   ],
  // },
  [status.AVAILABLE_CO + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
  },
  [status.CARRIED_OVER + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.CARRIED_OVER + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.CARRIED_OVER + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.INVALID_PURCHASE + DIV_CODE_SAF]: {
    visible: [REMOVE_PURCHASE],
    enabled: [REMOVE_PURCHASE],
  },
  [status.INVALID_OUTGOING + DIV_CODE_SAF]: {
    visible: [REMOVE_OUTGOING],
    enabled: [REMOVE_OUTGOING],
  },
  [status.INVALID_PURCHASE + DIV_CODE_COPRO]: {
    visible: [REMOVE_PURCHASE],
    enabled: [REMOVE_PURCHASE],
  },
  [status.AVAILABLE_FROM_TRANSFER + DIV_CODE_SAF]: {
    visible: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
    enabled: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
  },
  [status.AVAILABLE_FROM_TRANSFER + DIV_CODE_COPRO]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
      RETIRE_FOR_MANDATE_SNS,
    ],
    enabled: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
      RETIRE_FOR_MANDATE_SNS,
    ],
  },
  [status.AVAILABLE_FROM_TRANSFER + DIV_CODE_GF]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      RETIRE_FOR_MANDATE,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
    enabled: [
      VIEW_DETAILS,
      ALLOCATE,
      RETIRE_FOR_MANDATE,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
  },
  [status.ALLOCATED_AND_SENT + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      REMOVE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION, REMOVE_DOCUMENT],
  },
  [status.ALLOCATED_AND_SENT + DIV_CODE_COPRO]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION],
  },
  [status.TRANSFERRED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_TRANSFER,
      GENERATE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_TRANSFER, GENERATE_DOCUMENT],
  },
  [status.TRANSFERRED_AND_SENT + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, REMOVE_TRANSFER, REMOVE_DOCUMENT],
    enabled: [VIEW_DETAILS, REMOVE_TRANSFER, REMOVE_DOCUMENT],
  },
};

export const reversalConfig = {
  [status.AVAILABLE + UNLINK_DOCUMENT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.ACTUALIZATION,
    headerText: "Unlink document",
    bodyTextArr: [
      "All purchase quantity linked to this document will become unlinked and will need to be relinked to the correct document.",
      "Unlinking the document will make the certificate available again for relinking to the purchase in Incoming documents.",
    ],
    actionButtonText: "Confirm unlink",
  },
  [status.ALLOCATED + REMOVE_ALLOCATION + outboundTypes.VOLUNTARY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.ALLOCATION,
    headerText: "Remove allocation",
    bodyTextArr: [
      "Removing the allocation will make the quantity available again and remove any other incoming document allocations associated with the outgoing transaction.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED + REMOVE_ALLOCATION + outboundTypes.EXPORT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.ALLOCATION,
    headerText: "Remove export allocation",
    bodyTextArr: [
      "Removing allocation will make quantity available again.",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status: <i>removed.</i>
      </>,
      "You need to email the recipient to notify them of the invalid allocation.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED + REMOVE_ALLOCATION]: {
    eventType: reversalEventTypes.REMOVE,
    eventName: reversalEventNames.ALLOCATION,
    headerText: "Remove allocation",
    bodyTextArr: ["Removing allocation will make quantity available again."],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE + REMOVE_FROM_BALANCE]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_BALANCE,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Unlinking the document will make the certificate available again for relinking to the purchase in Incoming documents.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE + REMOVE_FROM_BALANCE + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_BALANCE,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Unlinking the document will make the certificate available again for relinking to the purchase in Incoming documents.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE_CO + REMOVE_FROM_BALANCE]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.CARRY_FORWARD,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Removing the incoming document will remove all quantities from the current balance and return them to the previous balance.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE_CO + REMOVE_FROM_BALANCE + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.CARRY_FORWARD,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Removing the incoming document will remove all quantities from the current balance and return them to the previous balance.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.RESERVED + REMOVE_RESERVATION]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.RESERVED,
    headerText: "Remove reservation",
    bodyTextArr: ["Are you sure you want to remove reservation?"],
    actionButtonText: "Confirm removal",
  },
  [status.WRITE_OFF + REMOVE_WRITE_OFF]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.WRITE_OFF,
    headerText: "Remove write off",
    bodyTextArr: ["Removing write off will make quantity available again."],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED + REMOVE_DOCUMENT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.OUTGOING_DOCUMENTS,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing document you will need to generate document again.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED + REMOVE_DOCUMENT + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.NABISY_DOCUMENT,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing the document will also remove any outgoing documents generated against the incoming document.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
      "Please contact the Nabisy account to ensure that the incoming document is available again before regenerating the outgoing document.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED_AND_SENT + REMOVE_DOCUMENT + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.NABISY_DOCUMENT,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing the document will also remove any outgoing documents generated against the incoming document.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
      "Please contact the Nabisy account to ensure that the incoming document is available again before regenerating the outgoing document.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.TRANSFERRED + REMOVE_DOCUMENT + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.NABISY_DOCUMENT,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing the document will also remove any outgoing documents generated against the incoming document.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
      "Please contact the Nabisy account to ensure that the incoming document is available again before regenerating the outgoing document.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.TRANSFERRED_AND_SENT + REMOVE_DOCUMENT + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.NABISY_DOCUMENT,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing the document will also remove any outgoing documents generated against the incoming document.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
      "Please contact the Nabisy account to ensure that the incoming document is available again before regenerating the outgoing document.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED_AND_SENT + REMOVE_DOCUMENT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.OUTGOING_DOCUMENTS,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing document you will need to generate document again.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
    ],
    actionButtonText: "Confirm removal",
  },
  [status.ALLOCATED_AND_SENT + REMOVE_ALLOCATION]: {
    eventType: reversalEventTypes.REMOVE,
    eventName: reversalEventNames.ALLOCATION,
    headerText: "Remove allocation",
    bodyTextArr: ["Removing allocation will make quantity available again."],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE_FROM_TRANSFER + REMOVE_FROM_BALANCE]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.LOCATION_TRANSFER,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Removing the incoming document will remove all quantities from the current balance and return them to the sending location mass balance.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE_FROM_TRANSFER + REMOVE_FROM_BALANCE + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.LOCATION_TRANSFER,
    headerText: "Remove from balance",
    bodyTextArr: [
      "Removing the incoming transfer will remove whole quantity from the current balance and return it to the sending location mass balance.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.RETIRED_FOR_SNS + UNRETIRE_FOR_VOLUME_SNS]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.UNRETIRE_FOR_VOLUME_SNS,
    headerText: "Unretire for mandate SNS",
    bodyTextArr: [
      "Unretiring for volume SNS will make the purchase available for allocation.",
    ],
    actionButtonText: "Confirm unretire",
  },
  [status.RETIRED + UNRETIRE_FOR_MANDATE]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.UNRETIRE_FOR_MANDATE,
    headerText: "Unretire for mandate",
    bodyTextArr: [
      "Unretiring for mandate will make the purchase available for allocation.",
    ],
    actionButtonText: "Confirm unretire",
  },
  [status.RETIRED + REMOVE_DOCUMENT + NABISY]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.NABISY_DOCUMENT,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing the document will also remove any outgoing documents generated against the incoming document.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
      "Please contact the Nabisy account to ensure that the incoming document is available again before regenerating the outgoing document.",
    ],
    actionButtonText: "Confirm removal",
  },
  [status.TRANSFERRED + REMOVE_DOCUMENT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.OUTGOING_DOCUMENTS,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing document you will need to generate document again.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
    ],
    actionButtonText: "Confirm removal",
  },
  [status.TRANSFERRED_AND_SENT + REMOVE_DOCUMENT]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.OUTGOING_DOCUMENTS,
    headerText: "Remove document",
    bodyTextArr: [
      "Removing document you will need to generate document again.",
      <>
        The generated document will remain available in outgoing documents with
        the status: <i>removed.</i>
      </>,
    ],
    actionButtonText: "Confirm removal",
  },
  [status.AVAILABLE + REMOVE_PURCHASE]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_PURCHASE,
    headerText: "Remove purchase",
    bodyTextArr: [
      "The purchase is invalid. Removing the purchase will also remove its allocation to outgoing transaction and unlink it from the document.",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
  [status.AVAILABLE + REMOVE_PURCHASE + massBalanceType.TRS]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_PURCHASE,
    headerText: "Remove purchase",
    bodyTextArr: [
      "The purchase is invalid. Removing the purchase will also remove its allocation to outgoing transaction and unlink it from the document.",
      "If the purchase quantity has been carried over to a subsequent balance, this quantity will be removed from that balance.",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status of <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
  [status.ALLOCATED_AND_SENT + REMOVE_OUTGOING]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_OUTGOING,
    headerText: "Remove outgoing transaction",
    bodyTextArr: [
      "The outgoing transaction is invalid. Removing the invalid outgoing transaction will also remove its allocation on the purchase. ",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
  [status.ALLOCATED + REMOVE_OUTGOING]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_OUTGOING,
    headerText: "Remove outgoing transaction",
    bodyTextArr: [
      "The outgoing transaction is invalid. Removing the invalid outgoing transaction will also remove its allocation on the purchase. ",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
  [status.ALLOCATED_AND_SENT +
  REMOVE_OUTGOING +
  outboundTypes.LOCATION_TRANSFER]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_OUTGOING,
    headerText: "Remove outgoing transaction",
    bodyTextArr: [
      "The outgoing transaction is invalid. Removing the invalid outgoing transaction will also remove its allocation on the purchase.",
      "If the outgoing transaction has been transferred to another mass location balance, this quantity will be removed from the receiving location mass balance.",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
  [status.ALLOCATED + REMOVE_OUTGOING + outboundTypes.LOCATION_TRANSFER]: {
    eventType: reversalEventTypes.REVERT,
    eventName: reversalEventNames.REMOVE_OUTGOING,
    headerText: "Remove outgoing transaction",
    bodyTextArr: [
      "The outgoing transaction is invalid. Removing the invalid outgoing transaction will also remove its allocation on the purchase.",
      "If the outgoing transaction has been transferred to another mass location balance, this quantity will be removed from the receiving location mass balance.",
      <>
        If a document has been generated, it will remain available in outgoing
        documents with the status <i>removed.</i>
      </>,
    ],
    actionButtonText: "Remove",
  },
};

export const linkAction = (
  division,
  setModal,
  actionName,
  row,
  table,
  navigate,
  navigateToAllocation,
  country,
) => {
  if (actionName === VIEW_DETAILS) {
    table.resetExpanded();
    setTimeout(() => {
      row.getToggleExpandedHandler()();
      row.highlight();
    }, 100);
    return;
  }

  if (actionName === LINK_DOCUMENT) {
    navigate(
      `/link-to-purchase/${country}/${division}?selectedId=${row.original.inboundRecord.purchaseLayerId}`,
    );
    return;
  }

  if (
    actionName === ALLOCATE &&
    (division === DIV_CODE_SAF ||
      (division === DIV_CODE_GF && country === COUNTRY_SPAIN))
  ) {
    navigateToAllocation(row?.original?.inboundRecord?.mbInboundQtyEuId);
    return;
  }

  if (actionName === WRITE_OFF) {
    setModal(actionName);
    return;
  }

  setModal(division + actionName);
};

export const checkFilter = (
  data,
  filterData,
  isOutgoingFilter,
  OUTGOING_COLUMN_FILTER_IDS,
  OUTGOING_COLUMN_DATE_FILTER_IDS,
) => {
  if (isOutgoingFilter) {
    return data.some((record) =>
      record.outboundRecords.some((outboundRecord) =>
        filterData.every((filter) => {
          const key = filter?.id;
          const value = filter?.value;
          // Check for string match filters
          if (OUTGOING_COLUMN_FILTER_IDS.includes(key)) {
            return outboundRecord[key]
              ?.toString()
              .toLowerCase()
              .includes(value?.toLowerCase());
          }
          // Check for date filters
          if (OUTGOING_COLUMN_DATE_FILTER_IDS.includes(key)) {
            return customDateFilterFnOutgoing(outboundRecord[key], key, value);
          }
          // If the filter doesn't match any known conditions, consider it passed (true)
          return true;
        }),
      ),
    );
  }
  return true;
};

export const checkFilterMandate = (
  data,
  filterData,
  isIncomingFilter,
  INCOMING_COLUMN_FILTER_IDS,
  INCOMING_COLUMN_DATE_FILTER_IDS,
) => {
  if (isIncomingFilter) {
    return data.some((record) =>
      record.inboundRecords.some((inboundRecord) =>
        filterData.every((filter) => {
          const key = filter?.id;
          const value = filter?.value;
          // Check for string match filters
          if (INCOMING_COLUMN_FILTER_IDS.includes(key)) {
            return inboundRecord[key]
              ?.toString()
              .toLowerCase()
              .includes(value?.toLowerCase());
          }
          // Check for date filters
          if (INCOMING_COLUMN_DATE_FILTER_IDS.includes(key)) {
            return customDateFilterFnOutgoing(inboundRecord[key], key, value);
          }
          // If the filter doesn't match any known conditions, consider it passed (true)
          return true;
        }),
      ),
    );
  }
  return true;
};

export const getQtyandUom = (rowData, divisionCode) => {
  const is_m3 =
    rowData?.original?.outboundRecords?.quantityOutM3 !== null &&
    divisionCode?.toLowerCase() !== DIV_CODE_COPRO?.toLowerCase();
  const quantity = getFormattedNumberWithCheck(
    is_m3
      ? rowData?.original?.outboundRecords?.quantityOutM3
      : rowData?.original?.outboundRecords?.quantityOutMt,
    NUMBER_OF_DECIMALS,
  );
  const quantity_uom = is_m3 ? "m³" : "mt";

  return {quantity, quantity_uom};
};

export const getAvailQtyandUom = (rowData, divisionCode) => {
  const is_m3 =
    rowData?.original?.outboundRecords?.quantityOutM3 !== null &&
    divisionCode?.toLowerCase() !== DIV_CODE_COPRO?.toLowerCase();
  const quantity = getFormattedNumberWithCheck(
    is_m3
      ? rowData?.original?.outboundRecords?.quantityOutM3
      : rowData?.original?.outboundRecords?.quantityOutMt,
    NUMBER_OF_DECIMALS,
  );
  let quantity_available = quantity;
  if (rowData?.original?.outboundRecords?.status === "RESERVED") {
    const availableRecord = rowData?.original?.outboundAllRecords?.find(
      (x) => x.status === "AVAILABLE",
    );
    const availableQuantity = getFormattedNumberWithCheck(
      is_m3 ? availableRecord?.quantityOutM3 : availableRecord?.quantityOutMt,
      NUMBER_OF_DECIMALS,
    );

    quantity_available += availableQuantity;
  }
  const quantity_uom = is_m3 ? "m³" : "mt";

  return {quantity: quantity_available, quantity_uom};
};

export const isInTolerance = (closingStock, physicalStock) => {
  const greaterQuantity = Math.max(physicalStock, closingStock);
  const lesserQuantity = Math.min(physicalStock, closingStock);
  const difference =
    ((greaterQuantity - lesserQuantity) / greaterQuantity) * 100;
  return difference <= 0.5;
};

export const isNumberAvailable = (value) => {
  return (
    value !== null && value !== undefined && value !== "TDV" && value !== "DDV"
  );
};

export const getMandateCertificateColumns = (
  country,
  division,
  dateFormat,
  decimalFormat,
  navigateToAllocation,
  currentFilterValues,
  siteReferenceId,
  divisionId,
) => {
  switch (division) {
    case DIV_CODE_SAF:
      return mandateCertificateColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        navigateToAllocation,
        currentFilterValues,
        siteReferenceId,
        divisionId,
        division,
      );
    default:
      return mandateCertificateColumnsSAF(dateFormat, decimalFormat, country);
  }
};

export const getMBListColumns = (country, division) => {
  switch (division) {
    case DIV_CODE_SAF:
      return mbListColumnsSAF(country, division);
    case DIV_CODE_COPRO:
      return mbListColumnsCopro(country, division);
    default:
      return mbListColumnsSAF(country, division);
  }
};
