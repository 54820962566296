import {useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  Download24,
  Filter24,
  TriangleRightSolid24,
} from "@bphxd/ds-core-react/lib/icons";
import {
  COUNTRY_GERMANY,
  COUNTRY_NORWAY,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
} from "constants/countryDetails";
import {
  ExportAnnualAPI,
  ExportSelectedRowsAPI,
} from "graphql/docManager/ExportOutgoingDetails";
import {OUTGOING_DOCS} from "graphql/docManager/outgoingDocuments";
import {SendCertificateEmailAPI} from "graphql/docManager/sendCertificateDetails";
import Feature from "modules/common/FeatureFlag/Feature";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import moment from "moment";
import {useAppSetting} from "providers/appSetting/context.js";
import {useUserSettings} from "providers/userSettings";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {downloadFile} from "utils/helpers/downloadFile";

import {
  getDivisionDataByCountry,
  getDivisionDetailsById,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import DocumentTable from "../Table/DocumentTableWithFilter";

import getColumnsSAF from "./Columns/ColumnsSAF";
import getColumnsSpain from "./Columns/ColumnsSpain";

const OutgoingDocTableView = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const [searchParams] = useSearchParams();
  const pageIndexNumber = searchParams.get("pageNo") ?? 0;

  const [highlightedRows, setHighlightedRows] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pageIndex, setPageIndex] = useState(pageIndexNumber);

  const divisionIdQuery = searchParams.get("divisionId");
  const page = searchParams.get("page");

  const sortBy = searchParams.get("sortBy");
  const siteReferenceId = siteReferenceData?.siteReferenceId;
  const [sortedData, setSortedData] = useState(undefined);
  const {country: selectedCountry} = useParams();

  const divisionData = useMemo(() => {
    if (appSetting?.divisionData) {
      if (divisionIdQuery) {
        return getDivisionDetailsById(divisionIdQuery);
      }
      return getDivisionDataByCountry(countryName);
    }
    return null;
  }, [divisionIdQuery, countryName, appSetting?.divisionData]);

  const handleSendCertificate = useCallback(
    async (certificateOutboundEuId, mbOutboundQtyEuId) => {
      await SendCertificateEmailAPI({
        event: {
          userId: account?.username,
          fileData: [{certificateOutboundEuId, mbOutboundQtyEuId}],
          siteReferenceId: siteReferenceData?.siteReferenceId,
          divisionId: divisionData?.divisionId,
        },
      });
      setHighlightedRows([certificateOutboundEuId]);
      setRowSelection({});
      toast.success("Certificate sent");
    },
    [
      account?.username,
      divisionData?.divisionId,
      siteReferenceData?.siteReferenceId,
    ],
  );
  const documentManager1_4FeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DOC_MANAGER_1_4,
  );
  const tableCols = useMemo(() => {
    switch (countryName) {
      case COUNTRY_NORWAY:
      case COUNTRY_SWEDEN:
      case COUNTRY_GERMANY:
        return getColumnsSAF(
          dateFormat,
          decimalFormat,
          handleSendCertificate,
          countryName,
          divisionData?.divisionCode,
          divisionData?.divisionId,
          documentManager1_4FeatureFlag,
        );
      case COUNTRY_SPAIN:
      default:
        return getColumnsSpain(
          dateFormat,
          decimalFormat,
          handleSendCertificate,
          countryName,
          divisionData?.divisionCode,
          divisionData?.divisionId,
          documentManager1_4FeatureFlag,
        );
    }
  }, [
    dateFormat,
    decimalFormat,
    handleSendCertificate,
    countryName,
    divisionData,
    documentManager1_4FeatureFlag,
  ]);

  const [columns, setColumns] = useState(tableCols);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const {data, loading} = useQuery(OUTGOING_DOCS, {
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      sdNumber: "",
      certificateOutboundEuId: "",
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      page === "incoming",

    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const tableData = loading
    ? []
    : data?.bioLcGetCertificateOutboundData?.documents;

  const outgoingResponseData = data && tableData;
  useEffect(() => {
    if (outgoingResponseData) {
      const outgoingDetails = outgoingResponseData ?? [];

      // include parent details into all split details
      let unsortedData = outgoingDetails.map(({splitDetails, ...rest}) => ({
        ...rest,
        state: rest.state?.toLowerCase(),
        splitDetails: splitDetails?.map((split) => ({
          ...split,
          sdNumber: rest.sdNumber,
          certificateInboundEuId: rest.certificateInboundEuId,
          certificateNumber: rest.certificateNumber,
          rawMaterialType: rest.rawMaterialType,
          supplierName: rest.supplierName,
          recipientReceiptAddress: rest.recipientReceiptAddress,
          productType: rest.productType,
          balance: rest.balance,
          ghgTotal: rest.ghgTotal,
          countryCode: rest.countryCode,
          certificationSystem: rest.certificationSystem,
          supplierDispatchAddress: rest.supplierDispatchAddress,
          dateOfIssuance: rest.dateOfIssuance,
          ghgEmissionPercentageIn: rest.ghgEmissionPercentageIn,
          bvAuditCreatedDatez: rest.bvAuditCreatedDatez,
          mbLocation: rest.mbLocation,
          mbLocationCode: rest.mbLocationCode,
          mbLocationGroupName: rest.mbLocationGroupName,
          mbBalanceGroupName: rest.mbBalanceGroupName,
          materialCode: rest.materialCode,
          mbcycle: rest.mbcycle,
          contractNumber: rest.contractNumber,
        })),
      }));

      if (sortBy) {
        unsortedData = unsortedData.sort((a, b) => {
          if (a.state === sortBy && b.state !== sortBy) {
            return -1;
          }
          if (a.state !== sortBy && b.state === sortBy) {
            return 1;
          }
          return 0;
        });
      }

      setSortedData(unsortedData);
    }
  }, [outgoingResponseData, sortBy]);

  const handleExportSelected = async (selectedRows) => {
    const selectedRecords = Object.keys(selectedRows)
      .map((index) => Number(index))
      .map((index) => sortedData[index]);

    const selectedIds = selectedRecords.map(
      ({certificateOutboundEuId}) => certificateOutboundEuId,
    );

    const response = await ExportSelectedRowsAPI({
      divisionId: divisionData?.divisionId,
      siteReferenceId,
      certificateOutboundEuIds: selectedIds,
    });

    if (
      response?.data?.bioLcGetOutgoingDocsExportApi &&
      response?.data?.bioLcGetOutgoingDocsExportApi?.statusCode === 200
    ) {
      downloadFile(response?.data?.bioLcGetOutgoingDocsExportApi?.downloadUrl);
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  const handleAnnualExport = async (year) => {
    const response = await ExportAnnualAPI({
      divisionId: divisionData?.divisionId,
      siteReferenceId,
      year,
    });
    if (
      response?.data?.bioLcGetOutgoingDocsExportYearApi &&
      response?.data?.bioLcGetOutgoingDocsExportYearApi?.statusCode === 200
    ) {
      downloadFile(
        response?.data?.bioLcGetOutgoingDocsExportYearApi?.downloadUrl,
      );
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  const uniqYears = useMemo(() => {
    const years = sortedData?.map((item) => {
      return (
        item.dateOfIssuance &&
        new Date(moment(item.dateOfIssuance))?.getFullYear()
      );
    });
    const uniqueYears = [
      ...new Set(
        years?.filter(
          (year) => year !== undefined && !Number.isNaN(year) && year !== "",
        ),
      ),
    ];
    return uniqueYears?.sort((a, b) => b - a);
  }, [sortedData]);

  return (
    <>
      <div className=" bg-white flex -flex-row justify-between w-full px-4 py-5">
        <div className="flex flex-none flex-row items-center ">
          <div className="mr-2">
            <CustomizeTable
              columns={columns}
              showApplyButton={false}
              onColumnChange={(cols) => setColumns(cols)}
            />
          </div>
          {(selectedCountry === COUNTRY_SWEDEN ||
            selectedCountry === COUNTRY_NORWAY ||
            selectedCountry === COUNTRY_GERMANY) && (
            <Feature flag={FEATURE_FLAGS.DOC_MANAGER_1_4}>
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle color="standard-tertiary rounded-0" caret>
                    Export
                    <Download24 className="btn-icon-suffix" />
                  </DropdownToggle>
                  <DropdownMenu tag="ul">
                    <li>
                      <DropdownItem
                        href="#"
                        onClick={() => handleExportSelected(rowSelection)}
                        disabled={Object.keys(rowSelection)?.length === 0}
                      >
                        {`Export selected (${
                          Object.keys(rowSelection)?.length
                        })`}
                      </DropdownItem>
                    </li>
                    <UncontrolledDropdown direction="end" tag="li">
                      <DropdownToggle
                        tag="button"
                        caret
                        className="dropdown-item"
                      >
                        Export annual data
                        <div className="dropdown-suffix x5-me-n1">
                          <TriangleRightSolid24 />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        {uniqYears?.map((year) => (
                          <DropdownItem
                            key={year}
                            onClick={() => handleAnnualExport(year)}
                          >
                            <Download24 className="btn-icon-prefix" />
                            <span>{year}</span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Feature>
          )}
        </div>
        <Button
          data-test="doc-manager-filter-btn"
          color="standard-tertiary rounded-0"
          onClick={() => setShowFilter(!showFilter)}
        >
          Filters
          <Filter24 className="btn-icon-suffix" />
        </Button>
      </div>
      <div className="border-secondary border-top w-full">
        <DocumentTable
          columns={filteredColumns}
          data={tableData ?? []}
          className="outgoing-docs-table-docmanager"
          highlightedRows={highlightedRows}
          loading={loading}
          filtering={filtering?.trim()}
          setFiltering={setFiltering}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          type="Outgoing"
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          pageIndex={pageIndex}
          showFilter={showFilter}
        />
      </div>
      <div>
        <Button
          className="rounded-0 mb-40"
          type=""
          disabled={Object.keys(rowSelection).length === 0}
          onClick={async () => {
            const selectedRecords = Object.keys(rowSelection)
              .map((index) => Number(index))
              .map((index) => tableData[index]);

            if (selectedRecords.map(({status}) => status).includes("REMOVED")) {
              toast.error(
                "Cannot send certificate for removed assignment, please remove those and try again.",
              );
              return;
            }

            if (selectedRecords?.length === 0) {
              toast.error(
                "Please select at least one certificate to send the email",
              );
              return;
            }

            const selectedIds = selectedRecords.map(
              ({certificateOutboundEuId, mbOutboundQtyEuId}) => {
                return {certificateOutboundEuId, mbOutboundQtyEuId};
              },
            );

            await SendCertificateEmailAPI({
              event: {
                userId: account?.username,
                fileData: selectedIds,
                siteReferenceId: siteReferenceData?.siteReferenceId,
                divisionId: divisionData?.divisionId,
              },
            });
            setHighlightedRows(
              selectedIds.map(
                ({certificateOutboundEuId}) => certificateOutboundEuId,
              ),
            );
            setRowSelection({});
            toast.success("Certificates sent");
          }}
        >
          Send certificate
        </Button>
      </div>
    </>
  );
};

export default OutgoingDocTableView;
