import {Remove24} from "@bphxd/ds-core-react/lib/icons";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import process from "process";
import PropTypes from "prop-types";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {
  getDDVNumberInputPattern,
  getDDVTDVNumberInputPattern,
  getNumberInputPattern,
  getPercentageInputPattern,
  getPositiveNumberInputPattern,
  handleNumber,
  handleNumberAndDDV,
  handleNumberDDVAndTDV,
  parseAndRoundToThreeDecimal,
} from "utils/numberUtil";

import {
  getQuantityKeyByCountryAndDivision,
  getUomKeyByCountryAndDivision,
} from "modules/DocManager/content/getDetailedViewConfig";
import Feature from "modules/common/FeatureFlag/Feature";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import CheckboxFlag from "../CheckboxNullable";
import Datepicker from "../Datepicker";
import {DDV, TDV, parseFormattedGhgValues} from "./utils";

const GHGSplit = ({
  field,
  index,
  countries,
  onRemoveSplit = () => {},
  status,
  isDisabled,
  tdvItems,
  configCenterTdv,
  setConfigCenterTdv,
  rawMaterial,
  selectedCountry,
  divisionCode,
  isLastSplit,
  handleLastSplit,
  splitInputAsQty,
  useSplitMode,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const [isSplitCheckedYes, setIsSplitCheckedYes] = useState(null);
  const quantityKey = useMemo(
    () => getQuantityKeyByCountryAndDivision(selectedCountry, divisionCode),
    [selectedCountry, divisionCode],
  );

  const unitOfMeasureKey = getUomKeyByCountryAndDivision(
    selectedCountry,
    divisionCode,
  );

  const {
    register,
    formState: {errors},
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    watch,
    trigger,
  } = useFormContext();

  const hideLtpButton =
    process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;
  const docManagerSplitsPercentageFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.DOC_MANAGER_SPLITS_PERCENTAGE,
  );

  const handlePercentageChange = (e) => {
    const {value} = e.target;
    if (!value || value.length === 0) return;
    const quantity = parseAndRoundToThreeDecimal(
      (convertToNumber(value, decimalFormat) / 100) *
        convertToNumber(getValues(`${quantityKey}`), decimalFormat),
    );
    setValue(
      `splitDetails.${index}.${quantityKey}`,
      formatNumber(quantity, decimalFormat, 0),
    );
    if (!isLastSplit) handleLastSplit(index);
  };

  const handleQuantityChange = (e) => {
    const {value} = e.target;
    if (!value || value.length === 0) return;
    const percentage = parseAndRoundToThreeDecimal(
      (convertToNumber(value, decimalFormat) /
        convertToNumber(getValues(`${quantityKey}`), decimalFormat)) *
        100,
    );
    setValue(
      `splitDetails.${index}.splitPercentage`,
      formatNumber(percentage, decimalFormat, 0),
    );
    if (!docManagerSplitsPercentageFeatureFlag) {
      trigger(`splitDetails.${index}.${quantityKey}`);
      return;
    }
    if (!isLastSplit) handleLastSplit(index);
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(`splitDetails.${index}.${name}`, options);
    return {innerRef: ref, ...props};
  };
  const changes = useWatch({
    name: [
      `splitDetails.${index}.ghgEec`,
      `splitDetails.${index}.ghgEl`,
      `splitDetails.${index}.ghgEp`,
      `splitDetails.${index}.ghgEtd`,
      `splitDetails.${index}.ghgEu`,
      `splitDetails.${index}.ghgEsca`,
      `splitDetails.${index}.ghgEccs`,
      `splitDetails.${index}.ghgEccr`,
      `splitDetails.${index}.defaultValueAppliedFlag`,
      "splitInputByQty",
    ],
  });

  useEffect(() => {
    const ghgTotal = getValues(`splitDetails.${index}.ghgTotal`);
    const flagValue = watch(`splitDetails.${index}.defaultValueAppliedFlag`);
    const parsedChanges = parseFormattedGhgValues(changes, decimalFormat);
    const total =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);

    if (flagValue) {
      // setValue(`splitDetails.${index}.ghgTotal`, TDV);
      setIsSplitCheckedYes(true);
      clearErrors(`splitDetails.${index}.ghgEec`);

      clearErrors(`splitDetails.${index}.ghgTotal`);
      clearErrors(`splitDetails.${index}.ghgTotalTdv`);
      clearErrors(`splitDetails.${index}.ghgNoValue`);

      if (tdvItems.length !== 1) {
        console.log(`Error tdvItems found: ${tdvItems}`);
        setError(`splitDetails.${index}.ghgTotalTdv`, {
          message:
            "Please double check the document, if all appears in order please contact BioVerse team.",
        });
        setValue(
          `splitDetails.${index}.ghgTotal`,
          "No TDV available for the selected raw material.",
        );
      } else if (decimalFormat === "European Style") {
        setConfigCenterTdv(tdvItems[0].defaultValue.replace(".", ","));
        setValue(`splitDetails.${index}.ghgTotal`, configCenterTdv);
      } else {
        setConfigCenterTdv(tdvItems[0].defaultValue.replace(",", "."));
        setValue(`splitDetails.${index}.ghgTotal`, configCenterTdv);
      }
    } else {
      if (status === "completed" || status === "ready_to_assign") {
        if (ghgTotal !== TDV && ghgTotal !== DDV) {
          setValue(
            `splitDetails.${index}.ghgTotal`,
            formatNumber(ghgTotal, decimalFormat, 0),
          );
        } else {
          setValue(`splitDetails.${index}.ghgTotal`, ghgTotal);
        }
      } else {
        setValue(
          `splitDetails.${index}.ghgTotal`,
          formatNumber(parseAndRoundToThreeDecimal(total), decimalFormat, 0),
        );
      }
      setIsSplitCheckedYes(false);
      clearErrors(`splitDetails.${index}.ghgTotalTdv`);
      if (
        parseFloat(convertToNumber(changes[0] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[1] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[2] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[3] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[4] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[5] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[6] ?? "0", decimalFormat)) === 0 &&
        parseFloat(convertToNumber(changes[7] ?? "0", decimalFormat)) === 0
      ) {
        setError(`splitDetails.${index}.ghgNoValue`, {
          type: "value",
          message: "At least one GHG emission must be greater than 0",
        });
      } else {
        clearErrors(`splitDetails.${index}.ghgNoValue`);
      }
    }
    trigger(`splitDetails.${index}.${quantityKey}`);
    trigger(`splitDetails.${index}.splitPercentage`);
  }, [
    changes,
    setValue,
    index,
    getValues,
    isSplitCheckedYes,
    status,
    clearErrors,
    setError,
    trigger,
    decimalFormat,
    setConfigCenterTdv,
    configCenterTdv,
    tdvItems,
    quantityKey,
    watch,
  ]);

  const unitOfMeasureValue =
    watch(unitOfMeasureKey) && watch(unitOfMeasureKey).toUpperCase() === "M3"
      ? "m³"
      : "MT";

  useEffect(() => {
    setValue("splitInputByQty", splitInputAsQty);
  }, [splitInputAsQty, setValue]);

  const positiveNumberInputPattern = useMemo(
    () => getPositiveNumberInputPattern(decimalFormat),
    [decimalFormat],
  );
  const numberInputPattern = useMemo(
    () => getNumberInputPattern(decimalFormat),
    [decimalFormat],
  );
  const percentageInputPattern = useMemo(
    () =>
      docManagerSplitsPercentageFeatureFlag
        ? getPercentageInputPattern(decimalFormat)
        : {},
    [decimalFormat, docManagerSplitsPercentageFeatureFlag],
  );
  const ddvNumberInputPattern = useMemo(
    () => getDDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  const tdvddvNumberInputPattern = useMemo(
    () => getDDVTDVNumberInputPattern(decimalFormat),
    [decimalFormat],
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center mb-5">
        <span className="!text-xl fw-semibold flex-1">
          Split {String.fromCharCode(65 + index)}
        </span>
        {!isDisabled && (
          <Button
            color="standard-quartenary"
            type="button"
            className="!pr-0"
            onClick={() => onRemoveSplit(index)}
          >
            <Remove24 />
          </Button>
        )}
      </div>
      <FormGroup className="mb-7">
        <Label for={`${field.id}originCountryName`} className="fw-normal">
          Country of origin of the origin raw material
        </Label>
        <Input
          type="select"
          id={`${field.id}originCountryName`}
          data-test={`${field.id}originCountryName`}
          {...computeProps("originCountryName", {
            required: "Please enter country of origin",
          })}
          invalid={
            !!errors.splitDetails?.[index]?.originCountryName && !isDisabled
          }
          disabled={isDisabled}
        >
          <option key="none" value="">
            None
          </option>
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </Input>
        {errors.splitDetails?.[index]?.originCountryName && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.originCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <div className="flex justify-between gap-5">
        <FormGroup className="w-100">
          <Label for={`${field.id}${quantityKey}`} className="fw-normal">
            Quantity {unitOfMeasureValue}
          </Label>
          <Input
            type="text"
            id={`${field.id}${quantityKey}`}
            data-test={`${field.id}${quantityKey}`}
            {...computeProps(quantityKey, {
              required: {
                value: splitInputAsQty,
                message: "Please enter a quantity",
              },
              ...positiveNumberInputPattern,
              onChange: (e) => {
                handleQuantityChange(e);
              },
            })}
            invalid={
              !!errors.splitDetails?.[index]?.[quantityKey] && !isDisabled
            }
            disabled={isDisabled}
            readOnly={
              docManagerSplitsPercentageFeatureFlag &&
              (isLastSplit || !splitInputAsQty)
            }
            className="read-only:filter read-only:brightness-95"
            onKeyDown={(e) => handleNumber(e)}
            maxLength={20}
          />
          {!isDisabled && !isLastSplit && splitInputAsQty && (
            <span className="text-xs text-gray-700">
              Unit of measure is derived from general information
            </span>
          )}
          {errors.splitDetails?.[index]?.[quantityKey] && (
            <FormFeedback>
              {errors.splitDetails?.[index]?.[quantityKey].message}
            </FormFeedback>
          )}
        </FormGroup>
        <Feature flag={FEATURE_FLAGS.DOC_MANAGER_SPLITS_PERCENTAGE}>
          {useSplitMode && (
            <FormGroup className="w-100">
              <Label for={`${field.id}splitPercentage`} className="fw-normal">
                Percentage of raw material %
              </Label>
              <Input
                type="text"
                id={`${field.id}splitPercentage`}
                data-test={`${field.id}splitPercentage`}
                {...computeProps("splitPercentage", {
                  required: {
                    value: !splitInputAsQty,
                    message: "Please enter a percentage",
                  },
                  ...percentageInputPattern,
                  onChange: (e) => {
                    handlePercentageChange(e);
                  },
                })}
                invalid={
                  !!errors.splitDetails?.[index]?.splitPercentage && !isDisabled
                }
                disabled={isDisabled}
                readOnly={isLastSplit || splitInputAsQty}
                onKeyDown={(e) => handleNumber(e)}
                className="read-only:filter read-only:brightness-95"
              />
              {errors.splitDetails?.[index]?.splitPercentage && (
                <FormFeedback>
                  {errors.splitDetails?.[index]?.splitPercentage.message}
                </FormFeedback>
              )}
            </FormGroup>
          )}
        </Feature>
      </div>
      <FormGroup className="py-3 flex items-center gap-5">
        <Label
          for={`$splitDetails.${index}.defaultValueAppliedFlag`}
          className="fw-normal !mb-0"
        >
          Total default value according to RED II applied
        </Label>
        <CheckboxFlag
          id={`splitDetails.${index}.defaultValueAppliedFlag`}
          name={`splitDetails.${index}.defaultValueAppliedFlag`}
          control={control}
          error={errors.defaultValueAppliedFlag}
          disabled={isDisabled}
          flex
        />
      </FormGroup>
      {!isSplitCheckedYes && (
        <>
          <div className="flex flex-row justify-between items-start gap-3">
            <FormGroup>
              <Label for={`${field.id}ghgEec`} className="fw-normal">
                Eec
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEec`}
                data-test={`${field.id}ghgEec`}
                {...computeProps("ghgEec", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEec && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
              {errors.splitDetails?.[index]?.ghgEec && (
                <FormFeedback>
                  {errors.splitDetails?.[index]?.ghgEec?.message}
                </FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Label for={`${field.id}ghgEl`} className="fw-normal">
                El
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEl`}
                data-test={`${field.id}ghgEl`}
                {...computeProps("ghgEl", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEl && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEp`} className="fw-normal">
                Ep
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEp`}
                data-test={`${field.id}ghgEp`}
                {...computeProps("ghgEp", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEp && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEtd`} className="fw-normal">
                Etd
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEtd`}
                data-test={`${field.id}ghgEtd`}
                {...computeProps("ghgEtd", ddvNumberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEtd && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumberAndDDV(e)}
                maxLength={20}
              />
              {errors.splitDetails?.[index]?.ghgEtd && (
                <FormFeedback>
                  {errors.splitDetails?.[index]?.ghgEtd?.message}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEu`} className="fw-normal">
                Eu
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEu`}
                data-test={`${field.id}ghgEu`}
                {...computeProps("ghgEu", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEu && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEsca`} className="fw-normal">
                Esca
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEsca`}
                data-test={`${field.id}ghgEsca`}
                {...computeProps("ghgEsca", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEsca && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEccs`} className="fw-normal">
                Eccs
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEccs`}
                data-test={`${field.id}ghgEccs`}
                {...computeProps("ghgEccs", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEccs && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
            <FormGroup>
              <Label for={`${field.id}ghgEccr`} className="fw-normal">
                Eccr
              </Label>
              <Input
                type="text"
                id={`${field.id}ghgEccr`}
                data-test={`${field.id}ghgEccr`}
                {...computeProps("ghgEccr", numberInputPattern)}
                invalid={!!errors.splitDetails?.[index]?.ghgEccr && !isDisabled}
                disabled={isDisabled}
                onKeyDown={(e) => handleNumber(e)}
                maxLength={20}
              />
            </FormGroup>
          </div>
          {errors.splitDetails?.[index]?.ghgNoValue && (
            <FormFeedback className=" !block">
              {errors.splitDetails?.[index]?.ghgNoValue.message}
            </FormFeedback>
          )}
        </>
      )}
      <FormGroup>
        <Label for={`${field.id}ghgTotal`} className="fw-normal">
          E = Total GHG emissions from supply and use of the fuel
          {divisionCode === DIV_CODE_COPRO ? " kgCO2/dry-t" : " gCO2eq/MJ"}
        </Label>
        <Input
          type="text"
          id={`${field.id}ghgTotal`}
          data-test={`${field.id}ghgTotal`}
          {...computeProps("ghgTotal", {
            required: "Please enter total GHG emissions",
            ...tdvddvNumberInputPattern,
          })}
          invalid={
            (!!errors.splitDetails?.[index]?.ghgTotal && !isDisabled) ||
            (!!errors.splitDetails?.[index]?.ghgTotalTdv &&
              getValues(`splitDetails.${index}.defaultValueAppliedFlag`))
          }
          disabled={isDisabled || isSplitCheckedYes}
          onKeyDown={(e) => handleNumberDDVAndTDV(e)}
          maxLength={20}
        />
        {errors.splitDetails?.[index]?.ghgTotal && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.ghgTotal?.message}
          </FormFeedback>
        )}
        {errors.splitDetails?.[index]?.ghgTotalTdv && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.ghgTotalTdv?.message}
          </FormFeedback>
        )}
        {getValues(`splitDetails.${index}.defaultValueAppliedFlag`) &&
          !errors.splitDetails?.[index]?.ghgTotalTdv && (
            <span className="text-xs text-gray-700">
              TDV for {rawMaterial} has been applied
            </span>
          )}
      </FormGroup>
      {hideLtpButton !== "false" && (
        <FormGroup>
          <Label for={`${field.id}physicalReceiptDate`} className="fw-normal">
            Date of physical receipt
          </Label>
          <Datepicker
            id={`${field.id}physicalReceiptDate`}
            name={`splitDetails.${index}.physicalReceiptDate`}
            control={control}
            error={errors.splitDetails?.[index]?.physicalReceiptDate}
            rules={{
              required: "Please add physical receipt date",
            }}
            placeholder="Please add date if available"
            disabled={isDisabled}
          />
          {errors.splitDetails?.[index]?.physicalReceiptDate && (
            <FormFeedback>
              {errors.splitDetails?.[index]?.physicalReceiptDate?.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
    </div>
  );
};
GHGSplit.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  isCheckedYes: PropTypes.bool,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onRemoveSplit: PropTypes.func,
  status: PropTypes.string,
  isDisabled: PropTypes.bool,
  tdvItems: PropTypes.array,
  configCenterTdv: PropTypes.number,
  setConfigCenterTdv: PropTypes.func,
  rawMaterial: PropTypes.string,
  selectedCountry: PropTypes.string,
  divisionCode: PropTypes.string,
  isLastSplit: PropTypes.bool,
  handleLastSplit: PropTypes.func,
  splitInputAsQty: PropTypes.bool,
  useSplitMode: PropTypes.bool,
};
export default GHGSplit;
