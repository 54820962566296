import {DragHandleBars24, Down24} from "@bphxd/ds-core-react/lib/icons";
import {DndContext, useDraggable, useDroppable} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import PropTypes from "prop-types";
import {useState} from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import {Row} from "antd";

const Droppable = (props) => {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    border: isOver ? "1px dashed green" : undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
};

Droppable.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

const DropdownCustomizeItem = ({column, onChange}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: column.header,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div ref={setNodeRef} style={style}>
      <DropdownItem toggle={false}>
        <Row className="gap-2 flex-nowrap w-100" align="middle">
          <DragHandleBars24 {...attributes} {...listeners} />
          <FormGroup className="form-check-reverse flex-grow-1" switch>
            <Label check>{column.header}</Label>
            {!column.defaultOption && (
              <Input
                id="switch-alt-example-1"
                type="switch"
                checked={!column.visible}
                onChange={(e) =>
                  onChange({...column, visible: !e.target.checked})
                }
              />
            )}
          </FormGroup>
        </Row>
      </DropdownItem>
    </div>
  );
};

DropdownCustomizeItem.propTypes = {
  column: PropTypes.object,
  onChange: PropTypes.func,
};

const FilterBTN = ({columns, onColumnChange}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleColumnChange = (column) => {
    onColumnChange(columns.map((c) => (c.key === column.key ? column : c)));
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (active.id === over.id) {
      return;
    }
    const reorderedColumns = [...columns];
    const activeIndex = reorderedColumns.findIndex(
      (c) => c.header === active.id,
    );
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.header === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);
    onColumnChange(reorderedColumns);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="tertiary" className="rounded-0 ms-0 border" caret>
        Customize Columns
        <Down24 className="btn-icon-suffix" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-scroll">
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          {columns.map(
            (column) =>
              column.header !== "" && (
                <Droppable key={column.header} id={column.header}>
                  <DropdownCustomizeItem
                    column={column}
                    onChange={handleColumnChange}
                  />
                </Droppable>
              ),
          )}
        </DndContext>
      </DropdownMenu>
    </Dropdown>
  );
};

FilterBTN.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
};

export default FilterBTN;
