import {gql} from "@apollo/client";

export const GET_GENERATED_DOC_FILE = gql`
  query bioLcGetIncomingDocFileApi(
    $siteReferenceId: String
    $divisionId: String
    $generatedFileName: String
  ) {
    bioLcGetIncomingDocFileApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        generatedFileName: $generatedFileName
      }
    ) {
      downloadUrl
      error
      statusCode
    }
  }
`;

export default GET_GENERATED_DOC_FILE;
