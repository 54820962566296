import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import {
  ACTIVE_BATCH_FIELDS,
  ACTIVE_BATCH_KEYS,
  ACTIVE_BATCH_LABELS,
  WARNINGS,
} from "modules/co-processing/constants/shipments";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import tw from "twin.macro";
import BatchesVolumeBar from "./BatchesVolumeBar";
import ShipmentIdForm from "./ShipmentIdForm";

const ActiveBatchLabel = tw.div`pl-2 min-w-[120px] w-[120px]`;
const ActiveBatchField = tw.div`flex items-center pb-3 text-[13px] font-[400]`;
const ActiveBatchValue = tw.div`text-[#111111] pl-3 text-opacity-[0.64]`;
const SubLabel = tw.div`text-[11px] text-gray-500 mt-1`;
const SubValue = tw.div`text-[11px] text-gray-500 mt-1`;

const ActiveBatchCard = ({
  data,
  handleChange: propHandleChange,
  handleErrors,
  handleWarnings,
  handleLoading,
  refreshData,
  readonly = false,
}) => {
  const getShipments = (batchData) => {
    const shipments = batchData?.shipments ?? [];

    if (batchData.remaining_volume > 0 && batchData.ending_inventory == null) {
      return [...shipments, {shipment_id: "", shipment_volume: 0}];
    }
    return shipments || [];
  };

  const [shipments, setShipments] = useState(getShipments(data));
  const [userInputShipments, setUserInputShipments] = useState([]);
  const [endingInventory, setEndingInventory] = useState(
    data?.estimated_ending_inventory,
  );
  const [totalShipmentsVolume, setTotalShipmentsVolume] = useState(() =>
    shipments.reduce(
      (acc, shipment) => acc + (shipment.shipment_volume || 0),
      0,
    ),
  );

  useEffect(() => {
    if (data.missing_c14) {
      handleWarnings(
        ACTIVE_BATCH_KEYS.MISSING_C14,
        WARNINGS.MISSING_C14,
        data.batch_id,
      );
    }
  }, [handleWarnings, data]);

  const transfers = useMemo(() => data?.transfers || [], [data]);

  const hasInventoryTransfers = useMemo(
    () => transfers.length > 0,
    [transfers],
  );

  const hasCommittedShipments = useMemo(
    () =>
      data?.shipments?.length > 0 &&
      data.shipments.every(
        (shipment) => shipment?.shipment_status?.toLowerCase() === "committed",
      ),
    [data],
  );

  const transferVolume = useMemo(() => {
    return transfers.reduce(
      (acc, transfer) => acc + transfer.shipment_volume,
      0,
    );
  }, [transfers]);

  const remainingVolume = useMemo(
    () =>
      data.total_volume -
      totalShipmentsVolume -
      endingInventory -
      transferVolume,
    [data, totalShipmentsVolume, endingInventory, transferVolume],
  );

  useEffect(() => {
    if (remainingVolume > 0) {
      setShipments((prevShipments) => {
        const isLastShipmentEmpty =
          prevShipments.length > 0 &&
          !prevShipments[prevShipments.length - 1].shipment_id &&
          !prevShipments[prevShipments.length - 1].shipment_volume;
        return isLastShipmentEmpty
          ? prevShipments
          : [...prevShipments, {shipment_id: "", shipment_volume: 0}];
      });
    }
  }, [data, handleErrors, remainingVolume]);

  const handleInputChange = (event) => {
    const input = event?.target?.value;
    const updateValue = parseFloat(input) || 0;
    setEndingInventory(updateValue);
    propHandleChange(event, {batch_id: data.batch_id});
  };

  const handleShipmentChange = (shipmentId, index) => {
    const newShipmentList = [...userInputShipments];
    newShipmentList[index] = shipmentId;
    setUserInputShipments(newShipmentList);
    propHandleChange(
      {target: {name: ACTIVE_BATCH_KEYS.SHIPMENT_ID, value: newShipmentList}},
      {batch_id: data.batch_id},
    );
  };

  const updateTotalVolume = (newVolume) => {
    const updatedVolume = totalShipmentsVolume + newVolume;

    if (newVolume <= remainingVolume) {
      setTotalShipmentsVolume(updatedVolume);
      const newShipments = [
        ...shipments,
        {shipment_id: "", shipment_volume: 0},
      ];
      setShipments(newShipments);
    }
  };

  const getBatchItemValue = (batchInfo, key, metric, isInputField = false) => {
    const value = batchInfo?.[key];

    if (key === ACTIVE_BATCH_KEYS.TANK_CERTIFIED_DATE) {
      return value ? displayDate(value) : "N/A";
    }

    if (key === ACTIVE_BATCH_KEYS.REMAINING_VOLUME) {
      return remainingVolume != null
        ? `${remainingVolume.toLocaleString()} ${metric}`
        : "N/A";
    }

    if (key === ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV) {
      const endingInventoryValue =
        batchInfo[ACTIVE_BATCH_KEYS.USER_INPUT__ENDING_INV];
      if (isInputField) {
        return endingInventoryValue;
      }
      return endingInventoryValue != null
        ? `${endingInventoryValue.toLocaleString()} ${metric}`
        : "N/A";
    }

    return value != null ? `${value.toLocaleString()} ${metric ?? ""}` : "N/A";
  };

  const renderBatchFields = (batch) => {
    const value = getBatchItemValue(data, batch.key, batch.metric);

    if (batch.key === ACTIVE_BATCH_KEYS.TOTAL_VOLUME && hasInventoryTransfers) {
      return (
        <div
          data-test="copro-active-batch-fields"
          className="flex items-start mb-3 text-[13px] font-[400] bg-gray-50 w-5/6 rounded-lg"
        >
          <div className="flex flex-col min-w-[120px] w-[120px] p-3">
            <div>{batch.label}</div>
            <SubLabel>{ACTIVE_BATCH_LABELS.INVENTORY_TRANSFER}</SubLabel>
          </div>
          <div className="p-3 flex flex-col">
            <div>{value}</div>
            <SubValue>- {transferVolume.toLocaleString()} bbl</SubValue>
          </div>
        </div>
      );
    }
    return (
      <ActiveBatchField data-test="copro-active-batch-fields">
        <ActiveBatchLabel>{batch.label}</ActiveBatchLabel>
        {batch.type === "text" || hasCommittedShipments ? (
          <ActiveBatchValue>{value}</ActiveBatchValue>
        ) : (
          <ActiveBatchValue>
            <MetricInput
              value={getBatchItemValue(data, batch.key, batch.metric, true)}
              details={batch}
              showMetric={!!batch.metric}
              maxValue={remainingVolume + endingInventory}
              onChange={handleInputChange}
              reportErrors={handleErrors}
              setChecking={handleLoading}
            />
          </ActiveBatchValue>
        )}
      </ActiveBatchField>
    );
  };

  return (
    <div data-test="copro-active-batch-card">
      <BatchesVolumeBar
        totalAmount={data?.total_volume}
        remainingAmount={data?.remaining_volume}
        endingAmount={data?.estimated_ending_inventory ?? null}
        transferAmount={transferVolume}
        shipments={shipments}
      />
      <div className="flex flex-col md:flex-row">
        <div className="pb-10 w-full md:w-1/2 px-2">
          {ACTIVE_BATCH_FIELDS.map((batch, index) => (
            <div key={index}>{renderBatchFields(batch)}</div>
          ))}
        </div>
        <ShipmentIdForm
          batchId={data?.batch_id}
          shipments={shipments}
          transfers={transfers}
          handleShipmentChange={handleShipmentChange}
          handleErrors={handleErrors}
          handleLoading={handleLoading}
          refreshData={refreshData}
          remainingVolume={remainingVolume}
          updateTotalVolume={updateTotalVolume}
          isReadOnly={readonly}
        />
      </div>
    </div>
  );
};

ActiveBatchCard.propTypes = {
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleWarnings: PropTypes.func,
  handleLoading: PropTypes.func,
  refreshData: PropTypes.func,
  readonly: PropTypes.bool,
};

export default ActiveBatchCard;
