import {BATCH_BAR_LABELS} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";

const BatchesVolumeBar = ({
  totalAmount,
  remainingAmount,
  endingAmount = 0,
  transferAmount = 0,
  shipments = [],
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({
    left: "50%",
    transform: "translateX(-50%)",
  });
  const tooltipRef = useRef(null);
  const barRef = useRef(null);

  const allocatedVolume = shipments.reduce(
    (acc, shipment) => acc + shipment.shipment_volume,
    0,
  );

  const totalUsed =
    allocatedVolume + transferAmount + endingAmount + remainingAmount;
  const scale = totalAmount / totalUsed;

  const remainingPercentage = ((remainingAmount * scale) / totalAmount) * 100;
  const endingPercentage = ((endingAmount * scale) / totalAmount) * 100;
  const allocatedPercentage = ((allocatedVolume * scale) / totalAmount) * 100;
  const transferPercentage = ((transferAmount * scale) / totalAmount) * 100;

  useEffect(() => {
    const tooltip = tooltipRef.current;
    const bar = barRef.current;

    if (tooltip && bar) {
      const tooltipRect = tooltip.getBoundingClientRect();

      if (tooltipRect.right > window.innerWidth) {
        setTooltipPosition({
          top: "25px",
          left: "auto",
          right: "0",
          transform: "none",
        });
      } else if (tooltipRect.left <= 13) {
        setTooltipPosition({
          top: "25px",
          left: "0",
          right: "auto",
          transform: "none",
        });
      } else {
        setTooltipPosition({
          top: "25px",
          left: "50%",
          transform: "translateX(-50%)",
        });
      }
    }
  }, [allocatedPercentage, endingPercentage, remainingPercentage]);

  return (
    <div
      data-test="active-batch-volume-bar-container"
      className="w-full rounded shipments-visual-bar-container"
    >
      <div
        ref={barRef}
        data-test="active-batch-volume-bar"
        className="relative w-full h-5 bg-gray-300 rounded-lg"
      >
        <div
          className="absolute h-full solid rounded-l-lg"
          data-test="active-batch-shipment-volume-bar"
          style={{width: `${allocatedPercentage}%`}}
        ></div>
        <div
          className="absolute h-full bg-yellow-400 rounded-lg"
          data-test="active-batch-transfer-volume-bar"
          style={{
            left: `${allocatedPercentage}%`,
            width: `${transferPercentage}%`,
          }}
        >
          <div className="group relative w-full h-full overflow-visible">
            <div
              ref={tooltipRef}
              className="absolute hidden group-hover:block bg-white border border-gray-300 shadow-lg rounded py-2 px-2 whitespace-nowrap text-center"
              style={tooltipPosition}
            >
              <span className="font-bold">{`${BATCH_BAR_LABELS.TRANSFER_VOLUME}: `}</span>
              <span>{transferAmount?.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <div
          className="absolute h-full bg-green-400 striped rounded-lg"
          data-test="active-batch-ending-volume-bar"
          style={{
            left: `${allocatedPercentage + transferPercentage}%`,
            width: `${endingPercentage}%`,
          }}
        >
          <div className="group relative w-full h-full overflow-visible">
            <div
              ref={tooltipRef}
              className="absolute hidden group-hover:block bg-white border border-gray-300 shadow-lg rounded py-2 px-2 z-10 whitespace-nowrap text-center"
              style={tooltipPosition}
            >
              <span className="font-bold">{`${BATCH_BAR_LABELS.ENDING_INVENTORY}: `}</span>
              <span>{endingAmount?.toLocaleString()}</span>
            </div>
          </div>
        </div>

        <div
          className="absolute h-5/6 bg-gray-300 rounded-lg"
          data-test="active-batch-remaining-volume-bar"
          style={{
            left: `${
              allocatedPercentage + endingPercentage + transferPercentage
            }%`,
            width: `${remainingPercentage}%`,
          }}
        ></div>
      </div>

      <div className="mt-2 flex justify-between text-gray-800">
        <div>
          <span className="font-bold">{`${BATCH_BAR_LABELS.ALLOCATED_VOLUME}: `}</span>
          <span>{`${allocatedVolume?.toLocaleString()}`}</span>
        </div>
        <div>
          <span className="font-bold">{`${BATCH_BAR_LABELS.REMAINING_VOLUME}: `}</span>
          <span>{remainingAmount?.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

BatchesVolumeBar.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  remainingAmount: PropTypes.number.isRequired,
  endingAmount: PropTypes.number,
  transferAmount: PropTypes.number,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      shipment_volume: PropTypes.number.isRequired,
    }),
  ),
};

export default BatchesVolumeBar;
