import {Card} from "reactstrap";
import Layout from "../../components/Layout";
import "./index.scss";

const SafDashboard = () => {
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Reporting", link: "/product-metrics"},
    {text: "SAF Power BI report", link: "/SAFLandingPage"},
  ];
  return (
    <Layout title="SAF Power BI report" breadcrumbs={breadcrumbItems}>
      <div className="w-full flex flex-row">
        <div className=" flex flex-col w-1/2">
          <div className="px-7 pb-2 h2font-weight-light Dark-Default-Body-Paragraphs-P-Lead-egular-Left">
            <p>
              {" "}
              From here you can access the R7 SAF Reporting solution, with key
              functionalities: <br /> Compliance Monitor: Facilitating
              comprehensive compliance tracking, covering both mandatory
              obligations, but also voluntary offers made to aviation customers.{" "}
              <br />
              Asset Valuation: Tracks and evaluates the financial asset position
              for over- or under-compliance per mandate and voluntary balance.
              <br /> Performance Reporting: Enables detailed Performance
              analysis and drill-down to transactional granularity, empowering
              users with actionable insights. <br />
              Interim Matching & Allocation Functionality: Offers interim
              matching of Proof of Sustainability (POS) to physical transactions
              in SAP, along with commercial allocation of SAF to mandated or
              voluntary balance.
            </p>
          </div>
          <div className="px-7 h2font-weight-light Dark-Default-Body-Paragraphs-P-Lead-egular-Left">
            {`
In future you'll be able access the dashboard for each country available within BioVerse,
which will contain the SAF Document Manager and Mass Balance for that country.
has context menu`}
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <div className="flex flex-col m-10 align-items-center">
            <Card
              tag="a"
              href="/powerbireport"
              className="Background hover-shadow text-decoration-none mb-4"
              color="white"
            >
              <span className="Report-Actualisati">
                Compliance & Performance Monitor
              </span>
              <span className="Report-Actualisati">(Power BI)</span>
            </Card>
            <Card
              tag="a"
              href="/powerbiactualization"
              className="Background hover-shadow text-decoration-none"
              color="white"
            >
              <span className="Report-Actualisati">
                Actualisation & Allocation
              </span>
              <span className="Report-Actualisati">(Power BI)</span>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SafDashboard;
