import tw from "twin.macro";
import {useState, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
import {Button, Label, Input, Alert} from "reactstrap";
import {useNavigate, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {Check24, Remove24, Alert24} from "@bphxd/ds-core-react/lib/icons";
import {Datepicker} from "@bphxd/ds-core-react";
import {useQuery, useLazyQuery} from "@apollo/client";
import {COPRO_US_COFA_API} from "graphql/coprocessing/receipts";
import {useDateRange} from "modules/co-processing/context/DateRangeContext";
import {RECEIPT_INPUTS, OPERATIONS} from "../../constants/receipts";
import {
  COPRO_URL,
  BUTTONS,
  ERRORS,
  SUCCESS_SAVE,
} from "../../constants/coProcessing";
import {formatDatePickerValueForReceiptsForm} from "../../helpers/dateHelper";
import LoadingSpinner from "../Shared/LoadingSpinner";

import {
  setInitialData,
  setInitialErrors,
  getErrorState,
  getUpdates,
  getPDFErrors,
} from "../../helpers/formBuilder";
import "../index.scss";

export function getConcentrationValues(formData) {
  const concentrationKeys = [
    "tallow_concentration",
    "choice_white_grease_concentration",
    "carinata_oil_concentration",
    "corn_oil_concentration",
    "soy_concentration",
    "canola_oil_concentration",
    "used_cooking_oil_concentration",
  ];

  return concentrationKeys.reduce((total, key) => {
    const value = parseFloat(formData[key] || 0);
    return total + (Number.isNaN(value) ? 0 : value);
  }, 0);
}

const FormButtons = tw.div`py-5 flex justify-end`;
const MissingReceiptsForm = ({id}) => {
  const [formData, setFormData] = useState([]);
  const [pdfErrors, setPdfErrors] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorState, setErrorState] = useState(true);
  const [submissionErrorState, setSubmissionErrorState] = useState(false);

  const navigate = useNavigate();
  const {state} = useLocation();
  const {dateRange} = useDateRange();

  const {loading, data} = useQuery(COPRO_US_COFA_API, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      op: OPERATIONS.GET,
      cofa_id: id,
    },
    onCompleted: () => {
      const response = data?.bioLcCofaUsDetailsApi?.body?.data;
      if (response) {
        setFormData(setInitialData(response, RECEIPT_INPUTS));
        setErrors(setInitialErrors(response, RECEIPT_INPUTS));
        setPdfErrors(getPDFErrors(response, RECEIPT_INPUTS));
      } else {
        toast.error(ERRORS.FAILED_LOAD);
        navigate(-1, {state: {dateRange: state?.dateRange || dateRange}});
      }
    },
  });

  const concentrationValues = getConcentrationValues(formData);

  const handleChange = useCallback(
    (event) => {
      const {name, value} = event.target;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevData) => ({
        ...prevData,
        [name]: !value,
      }));
      setErrorState(getErrorState(errors, name, value));
    },
    [errors],
  );

  const options = useMemo(
    () => ({
      defaultDate: formData.tested_datetime,
      onChange: (selectedDate) =>
        handleChange({
          target: {
            value: formatDatePickerValueForReceiptsForm(selectedDate[0]),
            name: "tested_datetime",
          },
        }),
    }),
    [formData.tested_datetime, handleChange],
  );

  const [submitQuery, {data: dataRes, errors: errorsRes}] = useLazyQuery(
    COPRO_US_COFA_API,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length < 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        }
        if (dataRes === null || concentrationValues !== 100 || errorState) {
          setSubmissionErrorState(true);
          setTimeout(() => {
            setSubmissionErrorState(false);
          }, 6000);
        }
        if (!errorState && concentrationValues === 100) {
          toast.success(SUCCESS_SAVE);
          navigate(-1, {state: {dateRange: state?.dateRange || dateRange}});
        }
      },
    },
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedValues = getUpdates(pdfErrors, formData);
    submitQuery({
      variables: {
        op: OPERATIONS.UPDATE,
        cofa_id: id,
        updates: updatedValues,
      },
    });
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <hr className="my-0" />
          <div data-test="copro-missing-receipts-form" className="pt-8">
            {RECEIPT_INPUTS.map((input) => {
              return input.key === "tested_datetime" ? (
                <div
                  data-test="copro-form-label"
                  key={`${input.key}-field`}
                  className="mb-4"
                >
                  <Label for={input.key}>{input.label}</Label>
                  <Datepicker
                    data-test="copro-form-datepicker"
                    name={input.key}
                    options={options}
                    invalid={errors[input.key]}
                    required
                  />
                  {errors[input.key] && (
                    <span className="information-missing-text">
                      {ERRORS.INFORMATION_MISSING}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  {input.key === "tallow_concentration" && (
                    <div
                      className="pb-4 flex flex-col"
                      data-test="copro-concentration-counter"
                    >
                      <span className="pt-4 mb-2 font-normal text-base">
                        TOTAL CONCENTRATION:
                        <span
                          className={
                            concentrationValues !== 100
                              ? "invalid-total-concentration"
                              : "valid-total-concentration"
                          }
                        >
                          {concentrationValues}%
                        </span>
                      </span>
                      {concentrationValues !== 100 && (
                        <span className="text-[#AA4A44] text-xs">
                          Must add up to 100%
                        </span>
                      )}
                    </div>
                  )}
                  <div
                    key={`${input.key}-field`}
                    className={`mb-4 ${input?.className}`}
                  >
                    <Label data-test="copro-form-label" for={input.key}>
                      {input.label}
                    </Label>
                    <Input
                      data-test="copro-form-input"
                      name={input.key}
                      id={input.key}
                      type={input.data_type}
                      value={formData[input.key]}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                      invalid={errors[input.key]}
                      required
                    />
                    {errors[input.key] && (
                      <span className="information-missing-text">
                        {ERRORS.INFORMATION_MISSING}
                      </span>
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <FormButtons>
            <Button
              className="show rounded-0 mr-2"
              data-test="copro-cancel-btn"
              color="tertiary"
              outline
              type="button"
              onClick={() =>
                navigate(COPRO_URL, {
                  state: {dateRange: state?.dateRange || dateRange},
                })
              }
            >
              <Remove24 className="btn-icon-prefix" />
              {BUTTONS.CANCEL}
            </Button>
            <Button
              className="show primary-btn rounded-0"
              data-test="copro-save-btn"
              color="standard-primary"
              type="submit"
              onClick={handleSubmit}
            >
              <Check24 className="btn-icon-prefix" />
              {BUTTONS.SAVE}
            </Button>
          </FormButtons>
          <div className="flex justify-end">
            <Alert
              color="danger"
              isOpen={submissionErrorState}
              fade
              toggle={() => setSubmissionErrorState(false)}
            >
              <div className="flex flex-row">
                <Alert24 className="btn-icon-prefix text-danger" />
                <span>Cannot save data due to improper or missing fields.</span>
              </div>
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
};

MissingReceiptsForm.propTypes = {
  id: PropTypes.string,
};
export default MissingReceiptsForm;
