import {useQuery} from "@apollo/client";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";

import {TailLeft24} from "@bphxd/ds-core-react/lib/icons";
import GET_GENERATED_DOC_FILE from "graphql/docManager/bioLcGetIncomingDocFileApi";
import {startCase} from "lodash";
import {Button, Spinner} from "reactstrap";

const DocumentGeneratedViewPage = () => {
  const navigate = useNavigate();
  const {appSetting} = useAppSetting();

  const {country, division, generatedFileName} = useParams();

  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const backButtonLabel = "Mass balance";

  const {data, loading} = useQuery(GET_GENERATED_DOC_FILE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      divisionId: divisionData?.divisionId,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      generatedFileName: `${generatedFileName}.pdf`,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionData?.divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: backButtonLabel, link: redirectTo},
    {
      text: generatedFileName,
    },
  ];

  const downloadUrl = data?.bioLcGetIncomingDocFileApi?.downloadUrl;

  const title = "Completed";
  const subtitle = "This document generation is completed.";

  if (
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined
  ) {
    return (
      <div
        className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100"
        style={{
          height: "calc(100vh - 352px)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col view-doc__max-scroll bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={title}
            breadcrumbs={breadcrumbItems}
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-[36px] px-7 mt-[-16px]">
                <span className="mb-2 text-[16px] font-light">{subtitle}</span>
              </div>
            }
          >
            <div className="flex justify-end m-7">
              <Button
                color="tertiary"
                className="show btn-bg-br-gray mr-3"
                style={{
                  borderRadius: "0px",
                }}
                type="button"
                onClick={() => navigate(redirectTo)}
              >
                <TailLeft24 className="btn-icon-prefix" />
                Back
              </Button>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {downloadUrl === null || downloadUrl?.includes("file-not-found") ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={downloadUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentGeneratedViewPage;
