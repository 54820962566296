import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {
  Alert24,
  TriangleDown16,
  TriangleUp16,
} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import SpinnerLoading from "modules/common/SpinnerLoading";
import PropTypes from "prop-types";
import {Fragment, useState} from "react";
import {styled} from "twin.macro";

const SortButton = ({column}) => {
  const isSorted = column.getIsSorted();
  const isAsc = isSorted === "asc";
  const isDesc = isSorted === "desc";

  return (
    <button
      type="button"
      className="position-relative ms-2"
      onClick={column.getToggleSortingHandler()}
    >
      <div className="position-relative ms-2">
        <div className="react-table-sort position-absolute top-50 translate-middle-y">
          <TriangleDown16
            className={`position-absolute bottom-0 ${
              isDesc ? "" : "opacity-20"
            }`}
          />
          <TriangleUp16
            className={`position-absolute top-0 ${isAsc ? "" : "opacity-20"}`}
          />
        </div>
      </div>
    </button>
  );
};

const TableWrapper = ({loading, data, children, noDataMessage}) => (
  <WrapperDiv>
    {loading ? (
      <div className="h-80">
        <CenteredMessage>
          <SpinnerLoading />
        </CenteredMessage>
      </div>
    ) : data && data.length > 0 ? (
      <ScrollableDiv>
        {typeof children === "function" ? children(data) : children}
        <ShadowDiv />
      </ScrollableDiv>
    ) : (
      <div className="h-80">
        <CenteredMessage>{noDataMessage}</CenteredMessage>
      </div>
    )}
  </WrapperDiv>
);

TableWrapper.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
TableWrapper.defaultProps = {noDataMessage: "No data available"};

const HistoricalTable = ({columns, loading = false, data = []}) => {
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 10});

  const table = useReactTable({
    columns,
    data,
    state: {pagination},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  return (
    <>
      <TableWrapper loading={loading} data={data}>
        <StyledTable data-test="historical-table">
          <thead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Fragment key={header.id}>
                      <th
                        style={{
                          userSelect: "none",
                          width: header.getSize(),
                        }}
                      >
                        <div
                          style={{width: header.getSize()}}
                          className="flex align-items-center justify-center w-full mx-auto"
                        >
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </div>
                          {header.column.columnDef.meta?.hideHeader ? null : (
                            <SortButton column={header.column} />
                          )}
                        </div>
                      </th>
                    </Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="bg-white">
            {data &&
              table.getRowModel().rows.map((row) => (
                <tr key={row?.id} className="whitespace-nowrap">
                  {row.getVisibleCells().map((cell) => {
                    const meta = cell.column.columnDef?.meta || {};
                    const cellStyles = {
                      textAlign: meta?.type === "number" ? "right" : "center",
                      ...meta.style,
                    };
                    const isBatchCommittedColumn =
                      cell.column.columnDef?.accessorKey === "batch_committed";
                    const shouldRenderIcon =
                      isBatchCommittedColumn && cell.getValue() === "No";

                    const batchCommittedCellStyle = {
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                      ...meta.style,
                    };

                    return (
                      <td
                        key={cell?.id}
                        style={
                          isBatchCommittedColumn
                            ? batchCommittedCellStyle
                            : cellStyles
                        }
                      >
                        {flexRender(
                          cell.column.columnDef?.cell,
                          cell.getContext(),
                        )}
                        {shouldRenderIcon ? (
                          <Alert24 className="text-warning" />
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
      <div className="pt-3 pb-24 w-full">
        <ReactTablePaginationRow
          {...{
            rowCount: table.getPrePaginationRowModel().rows.length,
            pageLength: table.getState().pagination.pageSize,
            canPreviousPage: table.getCanPreviousPage(),
            canNextPage: table.getCanNextPage(),
            pageCount: table.getPageCount(),
            gotoPage: table.setPageIndex,
            nextPage: table.nextPage,
            previousPage: table.previousPage,
            setPageSize: table.setPageSize,
            pageIndex: table.getState().pagination.pageIndex,
            pageSize: table.getState().pagination.pageSize,
          }}
        />
      </div>
    </>
  );
};
HistoricalTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

SortButton.propTypes = {column: PropTypes.object};

export default HistoricalTable;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-top-width: 1.5px;
  border-color: black;
  margin-bottom: 20px;
  font-size: 0.9em;

  th {
    color: black;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }

  td {
    padding: 10px 16px;
  }
  tr:last-child td {
    border-bottom: 0; // Removing bottom border from the last row
  }
`;

const ScrollableDiv = styled.div`
  overflow-x: auto;
  width: 100%;
  height: 100%;
  min-height: max-content;
`;

const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShadowDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(17, 17, 17, 0.16) 100%
  );
  pointer-events: none; // Makes the shadow non-interactive
`;

const CenteredMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
