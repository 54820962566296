import {Close} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";

import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const RejectSuccessModal = ({
  handleNavigatetoListView,
  setRejectSuccessModal,
  rejectSuccessModal,
}) => {
  const {register} = useFormContext();

  const handleSubmit = () => {
    handleNavigatetoListView();
    setRejectSuccessModal(false);
  };

  return (
    <div>
      <Modal
        size="sm"
        isOpen={rejectSuccessModal}
        className="modal-dialog-centered mb-action-modal-344"
      >
        <ModalHeader
          className=" mb-5 text-xl"
          close={
            <Close
              onClick={() => {
                setRejectSuccessModal(false);
                handleNavigatetoListView();
              }}
            />
          }
        ></ModalHeader>
        <ModalBody className=" pt-0 text-center">
          <>
            <p className="fw-bold mb-4">Document is rejected</p>
            <p className="text-xs text-center">
              Please contact the Nabisy account to resolve any issues and
              retrieve the document again.
            </p>
          </>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-12 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default fw-bold"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

RejectSuccessModal.propTypes = {
  handleNavigatetoListView: PropTypes.func,
  setRejectSuccessModal: PropTypes.func,
  rejectSuccessModal: PropTypes.bool,
};

export default RejectSuccessModal;
