import {Datepicker} from "@bphxd/ds-core-react";
import {CircleClose24} from "@bphxd/ds-core-react/lib/icons";
import {orderBy, uniqBy} from "lodash";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";

const LinkToPurchaseFilter = ({
  defaultValues,
  filterDynamicData = [],
  handleFilterSubmit,
  isAllAvailable = false,
  selectedDivisionId,
}) => {
  const [balanceType, setBalanceType] = useState();
  const [locationGroup, setLocationGroup] = useState();
  const [periodValue, setPeriodValue] = useState();
  const [periodLabel, setPeriodLabel] = useState();
  const [balanceGroup, setBalanceGroup] = useState();
  const [divisionId, setDivisionId] = useState(selectedDivisionId);
  const GF_ACTUALIZATION = useFeatureFlag(FEATURE_FLAGS.GF_ACTUALIZATION);

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setDivisionId(defaultValues?.ltpDivisionId || null);
      setBalanceGroup(defaultValues?.ltpBalanceGroupId || null);
      setBalanceType(defaultValues?.ltpBalanceTypeId || null);
      setLocationGroup(defaultValues?.ltpLocationGroupId || null);
      setPeriodLabel(defaultValues.dateLabel ? defaultValues.dateLabel : []);
      setPeriodValue(
        defaultValues.ltpDateFrom && defaultValues.ltpDateTo
          ? [defaultValues.ltpDateFrom, defaultValues.ltpDateTo]
          : [],
      );
    }
  }, [
    filterDynamicDataResult,
    defaultValues?.ltpBalanceTypeId,
    defaultValues?.ltpLocationGroupId,
    defaultValues?.ltpBalanceGroupId,
    defaultValues,
  ]);

  const divisionList = useMemo(() => {
    if (filterDynamicDataResult?.length > 0) {
      const divisionItems = filterDynamicDataResult?.map((item) => ({
        divisionCode: item?.division?.divisionCode,
        divisionId: item?.division?.divisionId,
        divisionName: item?.division?.divisionName,
      }));
      let orderedList = orderBy(
        uniqBy(divisionItems, "divisionId"),
        "divisionCode",
        "desc",
      );
      if (!GF_ACTUALIZATION) {
        orderedList = orderedList.filter((item) => item.divisionCode !== "GF");
      }
      return orderedList;
    }
    return [];
  }, [filterDynamicDataResult, GF_ACTUALIZATION]);

  const typeList = useMemo(() => {
    if (filterDynamicDataResult?.length > 0) {
      const typeItems = filterDynamicDataResult
        ?.filter((item) => item?.division?.divisionId === divisionId)
        ?.map((item) => ({
          divisionCode: item?.division?.divisionCode,
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbBalanceTypeId: item?.mbBalanceType?.mbBalanceTypeId,
        }));
      let orderedList = orderBy(
        uniqBy(typeItems, "mbBalanceTypeId"),
        "mbBalanceTypeCode",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbBalanceTypeCode: "All", mbBalanceTypeId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [filterDynamicDataResult, isAllAvailable, divisionId]);

  const locationList = useMemo(() => {
    if (
      filterDynamicDataResult?.length > 0 &&
      (balanceType || isAllAvailable)
    ) {
      const locationItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.division?.divisionId === divisionId &&
            item?.mbBalanceType?.mbBalanceTypeId === balanceType,
        )
        ?.map((item) => ({
          divisionCode: item?.division?.divisionCode,
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbLocationGroupId: item?.mbLocationGroup?.mbLocationGroupId,
        }));
      let orderedList = orderBy(
        uniqBy(locationItems, "mbLocationGroupId"),
        "mbLocationGroupName",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbLocationGroupName: "All", mbLocationGroupId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [balanceType, filterDynamicDataResult, isAllAvailable, divisionId]);

  const balanceList = useMemo(() => {
    if (
      filterDynamicDataResult?.length > 0 &&
      ((balanceType && locationGroup) || isAllAvailable)
    ) {
      const balanceItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.division?.divisionId === divisionId &&
            item?.mbBalanceType?.mbBalanceTypeId === balanceType &&
            item?.mbLocationGroup?.mbLocationGroupId === locationGroup,
        )
        ?.map((item) => ({
          divisionCode: item?.division?.divisionCode,
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodName: item?.mbPeriod?.mbPeriodName,
          mbBalanceGroupName: item?.mbBalanceGroup?.mbBalanceGroupName,
          mbBalanceGroupId: item?.mbBalanceGroup?.mbBalanceGroupId,
        }));
      let orderedList = orderBy(
        uniqBy(balanceItems, "mbBalanceGroupId"),
        "mbBalanceGroupName",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbBalanceGroupName: "All", mbBalanceGroupId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [
    filterDynamicDataResult,
    balanceType,
    locationGroup,
    isAllAvailable,
    divisionId,
  ]);

  useEffect(() => {
    if (
      balanceType &&
      !typeList.find((item) => item?.mbBalanceTypeId === balanceType)
    ) {
      if (typeList?.length > 0) {
        setBalanceType(typeList[0]?.mbBalanceTypeId);
      } else {
        setBalanceType(undefined);
      }
    }
  }, [balanceType, typeList]);

  useEffect(() => {
    if (
      locationGroup &&
      !locationList.find((item) => item?.mbLocationGroupId === locationGroup)
    ) {
      if (locationList?.length > 0) {
        setLocationGroup(locationList[0]?.mbLocationGroupId);
      } else {
        setLocationGroup(undefined);
      }
    }
  }, [locationList, locationGroup]);

  useEffect(() => {
    if (
      balanceGroup &&
      !balanceList.find((item) => item?.mbBalanceGroupId === balanceGroup)
    ) {
      if (balanceList?.length > 0) {
        setBalanceGroup(balanceList[0]?.mbBalanceGroupId);
      } else {
        setBalanceGroup(undefined);
      }
    }
  }, [balanceList, balanceGroup]);

  const onSubmitFilter = () => {
    const divisionCode = divisionList.find(
      (item) => item.divisionId === divisionId,
    )?.divisionCode;

    const balanceTypeCode = typeList.find(
      (item) => item.mbBalanceTypeId === balanceType,
    )?.mbBalanceTypeCode;

    const balanceGroupName = balanceList.find(
      (item) => item.mbBalanceGroupId === balanceGroup,
    )?.mbBalanceGroupName;

    const locationGroupName = locationList.find(
      (item) => item.mbLocationGroupId === locationGroup,
    )?.mbLocationGroupName;

    handleFilterSubmit(
      divisionCode,
      balanceTypeCode,
      locationGroupName,
      periodValue,
      balanceGroupName,
      periodLabel,
    );
  };

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="division">Division</Label>
          <Input
            type="select"
            id="division"
            data-test="division"
            placeholder="Please choose from list"
            className="!w-40 bg-white"
            value={divisionId || selectedDivisionId}
            onChange={(e) => {
              setDivisionId(e.target.value);
              setPeriodValue([]);
              setPeriodLabel(null);
              setBalanceType(null);
            }}
          >
            {divisionList?.map((division) => (
              <option key={division.divisionId} value={division.divisionId}>
                {division.divisionName}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbBalanceTypeId">Type</Label>
          <Input
            type="select"
            id="type"
            data-test="type"
            value={balanceType}
            onChange={(e) => {
              setBalanceType(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-40 bg-white"
          >
            {typeList?.length > 0 &&
              typeList?.map((type) => (
                <option
                  key={type?.mbBalanceTypeId}
                  value={type?.mbBalanceTypeId}
                >
                  {type?.mbBalanceTypeCode}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbLocationGroupId">Location</Label>
          <Input
            type="select"
            id="mbLocationGroupId"
            data-test="mbLocationGroupId"
            value={locationGroup}
            onChange={(e) => {
              setLocationGroup(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {locationList?.length > 0 &&
              locationList?.map((location) => (
                <option
                  key={location?.mbLocationGroupId}
                  value={location?.mbLocationGroupId}
                >
                  {location?.mbLocationGroupName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbBalanceGroupId">Balance</Label>
          <Input
            type="select"
            id="balance"
            data-test="balance"
            value={balanceGroup}
            onChange={(e) => {
              setBalanceGroup(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {balanceList?.length > 0 &&
              balanceList?.map((balance) => (
                <option
                  key={balance?.mbBalanceGroupId}
                  value={balance?.mbBalanceGroupId}
                >
                  {balance?.mbBalanceGroupName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbPeriodId">Period</Label>
          <div className="filter-datepicker-container">
            <Datepicker
              className="!w-60 bp-datepicker-bp-core !placeholder-[#111111a3]  bg-white "
              placeholder="Select date"
              id="dateRange"
              name="dateRange"
              allowEmpty={[true]}
              resetOptions="true"
              defaultValue={periodLabel}
              options={{
                allowInput: false,
                dateFormat: "Y-m-d",
                defaultDate: periodLabel,
                showMonths: 1,
                enableTime: false,
                mode: "range",
                onClose: (selectedDates, date) => {
                  const dateArr = date.split(" thru ");
                  if (selectedDates.length === 2 && dateArr.length === 1) {
                    setPeriodValue([date, date]);
                    setPeriodLabel(selectedDates);
                  } else if (dateArr.length === 2) {
                    setPeriodValue(date.split(" thru "));
                    setPeriodLabel(selectedDates);
                  }
                },
              }}
            />
            {periodValue?.length > 0 && (
              <div className="!w-[24px] mr-[10px]">
                <CircleClose24
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => {
                    setPeriodValue([]);
                    setPeriodLabel(null);
                  }}
                />
              </div>
            )}
          </div>
        </FormGroup>
        <FormGroup className="flex flex-col-reverse">
          <Button
            data-test="filter-submit-button"
            color="standard-tertiary rounded-0"
            type="button"
            form="mb-filter-form"
            onClick={() => onSubmitFilter()}
          >
            Apply
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

LinkToPurchaseFilter.propTypes = {
  defaultValues: PropTypes.object,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
  isAllAvailable: PropTypes.bool,
  selectedDivisionId: PropTypes.string,
};

export default LinkToPurchaseFilter;
