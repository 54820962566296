export const ISCC_EU = "ISCC EU";
export const ISCC_PLUS = "ISCC PLUS";
export const REDCERT_GMBH = "REDcert GmbH";
export const ISCC_EU_VERTEX = "ISCC EU Vertex";
export const CERT_2BSvs = "2BSvs";
export const SNVS_CEPSA = "SNVS Cepsa";
export const SNVS_VERTEX = "SNVS Vertex";
export const ISCC_CEPSA = "ISCC Cepsa";

export default {ISCC_EU};
