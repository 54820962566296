import {Alert24} from "@bphxd/ds-core-react/lib/icons";
import {CONFIG_CENTER_URL} from "modules/SAF-dashboard/constants/common";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";

const Warning = ({row}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const balanceGroup = row?.original?.purchase_balance_group_name;
  const locationGroup = row?.original?.purchase_location_group_name;
  const plantCode = row?.original?.plant_code;
  const materialCode = row?.original?.material_code;

  const headerData =
    (!locationGroup && "New plant code") ||
    (!balanceGroup && "New material code");

  const bodyData = `The new ${!locationGroup ? "plant" : "material"} code ${
    !locationGroup ? plantCode : materialCode
  } is missing in the Configuration centre. 
  Please add the missing code in the `;

  return (
    <div>
      <Alert24
        className="cursor-pointer"
        color="#e64949"
        id={`popover-${row?.id}`}
        onClick={() => setOpen(!open)}
      />
      <Popover
        fade
        flip
        placement="left"
        target={`popover-${row?.id}`}
        isOpen={open}
      >
        <PopoverHeader>{headerData}</PopoverHeader>
        <PopoverBody>
          {bodyData}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              navigate({pathname: CONFIG_CENTER_URL});
            }}
            onKeyDown={() => {}}
          >
            configuration centre
          </span>
        </PopoverBody>
      </Popover>
    </div>
  );
};

Warning.propTypes = {
  row: PropTypes.object,
};

export default Warning;
