import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button} from "reactstrap";

import {useQuery} from "@apollo/client";
import {NUMBER_OF_DECIMALS} from "constants/common";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import MANDATE_CERTIFICATES_API from "graphql/mandate/bioLcGetMandateCertificateAPI";
import MANDATE_TOP_FILTERS from "graphql/mandate/bioLcGetMandateFiltersApi";
import {isEmpty, startCase} from "lodash";
import CustomizeTable from "modules/GlobalMassBalance/components/CustomizeTable/CustomizeTable";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {getMandateCertificateColumns} from "modules/GlobalMassBalance/utils";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import MandateFilter from "../components/MandateFilter";
import DocumentTable from "../components/Table";

const MandateCertificate = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [periodName, setPeriodName] = useState();
  const [mbTypeCode, setMbTypeCode] = useState();
  const [locationGroupName, setLocationGroupName] = useState();
  const [balanceGroupName, setBalanceGroupName] = useState();
  const [outboundTypeName, setOutboundTypeName] = useState();
  const [totalRetiredQty, setTotalRetiredQty] = useState(0);
  const [totalQtyOut, setTotalQtyOut] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();

  const {country, division} = useParams();
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const period = searchParams.get("period");
  const outboundType = searchParams.get("outboundType");

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mandate certificates"},
  ];

  const [currentFilterValues, setCurrentFilterValues] = useState({});
  const navigate = useNavigate();

  const {data: filterData, loading: filterLoading} = useQuery(
    MANDATE_TOP_FILTERS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const {data, loading} = useQuery(MANDATE_CERTIFICATES_API, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
      outMovementType: currentFilterValues?.outboundTypeId,
      period: currentFilterValues?.periodId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      currentFilterValues?.mbLocationGroupId === undefined ||
      currentFilterValues?.outboundTypeId === undefined ||
      currentFilterValues?.periodId === undefined,
  });

  const tableData = useMemo(
    () => data?.bioLcGetMandateCertificateAPI?.mandateCertificateData || [],
    [data?.bioLcGetMandateCertificateAPI?.mandateCertificateData],
  );

  useEffect(() => {
    const calculateSum = (data, field, isOutbound = false) => {
      if (data.length > 0) {
        let totalSum = 0;

        data.forEach((record) => {
          const selectedRecords = isOutbound
            ? [record.outboundRecord]
            : record.inboundRecords;

          totalSum += selectedRecords.reduce(
            (sum, item) => sum + parseFloat(item[field]),
            0,
          );
        });

        return Number(totalSum.toFixed(NUMBER_OF_DECIMALS));
      }
      return 0;
    };

    const sumRetired = calculateSum(tableData, "retiredQty");
    const sumQtyAvailableData = tableData.filter(
      (row) => row.outboundRecord.status === "ALLOCATED",
    );
    const sumQtyAvailable = calculateSum(
      sumQtyAvailableData,
      "quantityOutM3",
      true,
    );
    setTotalRetiredQty(sumRetired);
    setTotalQtyOut(sumQtyAvailable);
  }, [tableData]);

  const mbTableLoading = useMemo(() => {
    return (
      loading ||
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      currentFilterValues?.mbLocationGroupId === undefined ||
      currentFilterValues?.outboundTypeId === undefined ||
      currentFilterValues?.periodId === undefined
    );
  }, [
    loading,
    siteReferenceData?.siteReferenceId,
    divisionData?.divisionId,
    currentFilterValues?.mbLocationGroupId,
    currentFilterValues?.outboundTypeId,
    currentFilterValues?.periodId,
  ]);

  const filterDynamicData = useMemo(
    () => filterData?.bioLcGetMandateFiltersApi?.results,
    [filterData?.bioLcGetMandateFiltersApi?.results],
  );

  const navigateToAllocation = useCallback(
    (selectedId) => {
      let redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}`;
      if (selectedId) {
        redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}?selectedId=${selectedId}`;
      }
      navigate(redirectUrl);
    },
    [
      country,
      divisionCode,
      mbTypeCode,
      locationGroupName,
      periodName,
      balanceGroupName,
      navigate,
    ],
  );

  const tableCols = useMemo(
    () =>
      getMandateCertificateColumns(
        country,
        divisionCode,
        dateFormat,
        decimalFormat,
        navigateToAllocation,
        currentFilterValues,
        siteReferenceData?.siteReferenceId,
        divisionData?.divisionId,
      ),
    [
      country,
      divisionCode,
      dateFormat,
      decimalFormat,
      navigateToAllocation,
      currentFilterValues,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
    ],
  );
  const [columns, setColumns] = useState(tableCols);

  const selectedData = useMemo(() => {
    const selectedLocation =
      filterDynamicData?.mbLocationGroups?.find(
        (item) => item.name === location,
      ) || filterDynamicData?.mbLocationGroups?.[0];
    const selectedPeriod =
      filterDynamicData?.periods?.find((item) => item.name === period) ||
      filterDynamicData?.periods?.[0];
    const selectedOutboundType =
      filterDynamicData?.outboundTypes?.find(
        (item) => item.name === outboundType,
      ) || filterDynamicData?.outboundTypes?.[0];

    return {
      selectedLocation,
      selectedPeriod,
      selectedOutboundType,
    };
  }, [filterDynamicData, location, period, outboundType]);

  useEffect(() => {
    if (period) {
      setPeriodName(period);
    }
    if (location) {
      setLocationGroupName(location);
    }
    if (outboundType) {
      setOutboundTypeName(outboundType);
    }
  }, [location, outboundType, period]);

  useEffect(() => {
    if (!isEmpty(selectedData)) {
      setCurrentFilterValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbLocationGroupId: selectedData?.selectedLocation?.id,
          mbLocationGroupName: selectedData?.selectedLocation?.name,
          periodId: selectedData?.selectedPeriod?.id,
          periodName: selectedData?.selectedPeriod?.name,
          outboundTypeId: selectedData?.selectedOutboundType?.id,
          outboundTypeName: selectedData?.selectedOutboundType?.name,
        };
      });
    }
  }, [selectedData]);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols]);

  const filteredColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      columns: column.columns.filter(({visible}) => visible),
    }));
  }, [columns]);

  const extractKeys = useCallback((filteredColumns) => {
    return filteredColumns.flatMap((group) =>
      group.columns.map((col) => col.key),
    );
  }, []);

  const updateColumnFilter = useCallback(
    (filteredColumns, columnFilter) => {
      const keys = extractKeys(filteredColumns);
      // Filter the columnFilter to include only items whose id is in the extracted keys
      return columnFilter.filter((filterItem) => keys.includes(filterItem.id));
    },
    [extractKeys],
  );

  // useEffect hook to update the column filter state whenever filteredColumns or updateColumnFilter changes
  useEffect(() => {
    setColumnFilter((prevColumnFilter) => {
      // Update the column filter using the updateColumnFilter function
      const updatedColumnFilter = updateColumnFilter(
        filteredColumns,
        prevColumnFilter,
      );

      // Only update the state if the filters have actually changed
      if (
        JSON.stringify(prevColumnFilter) !== JSON.stringify(updatedColumnFilter)
      ) {
        return updatedColumnFilter; // Return the updated column filter
      }

      // Return the previous state if nothing has changed to avoid re-render
      return prevColumnFilter;
    });
  }, [filteredColumns, updateColumnFilter]);

  const handleFilterSubmit = async (
    locationGroup,
    periodValue,
    outboundType,
  ) => {
    const selectedLocation = filterDynamicData?.mbLocationGroups?.find(
      (item) => item.id === locationGroup,
    );
    const selectedPeriod = filterDynamicData?.periods?.find(
      (item) => item.id === periodValue,
    );
    const selectedOutboundType = filterDynamicData?.outboundTypes?.find(
      (item) => item.id === outboundType,
    );
    const redirectUrl = `/mandate-certificate/${country}/${divisionCode}?location=${selectedLocation?.name}&period=${selectedPeriod?.name}&outboundType=${selectedOutboundType?.name}`;
    navigate(redirectUrl);
  };

  const handleNavigate = async () => {
    const redirectUrl = `/mandate-allocation/${country}/${divisionCode}/${selectedData?.selectedLocation?.name}/${selectedData?.selectedPeriod?.name}/${selectedData?.selectedOutboundType?.name}`;
    navigate(redirectUrl);
  };

  const footerValue = division.toUpperCase() === DIV_CODE_COPRO ? "MT" : "m³";

  return (
    <Layout title="Mandate certificates" breadcrumbs={breadcrumbItems}>
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MandateFilter
              currentFilterValues={currentFilterValues}
              filterDynamicData={filterDynamicData}
              handleFilterSubmit={handleFilterSubmit}
            ></MandateFilter>
          </div>
          {divisionCode !== DIV_CODE_COPRO && (
            <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
              <Button
                data-test="allocate-outgoing-button"
                color="standard-tertiary rounded-0"
                type="button"
                onClick={handleNavigate}
              >
                Allocate for customer mandates
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* Customise columns, table, aggregate view */}
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full h-[calc(100vh-530px)] bg-white overflow-y-auto overflow-x-auto">
          <DocumentTable
            data={tableData}
            columns={filteredColumns}
            loading={mbTableLoading}
            showFilter={showFilter}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
        </div>
        <div className="w-full flex items-center h-20 border-t-2 border-t-[#eee] bg-white">
          <div className="w-1/2 text-end mr-4 h-7">
            <span className="text-gray-700 mr-[10px]">Retired quantity</span>
            <span>
              {getFormattedNumberWithCheck(totalRetiredQty, NUMBER_OF_DECIMALS)}{" "}
              {footerValue}
            </span>
          </div>
          <div className="w-1/2 text-end mr-4 h-7">
            <span className="text-gray-700 mr-[10px]">Allocated quantity</span>
            <span>
              {getFormattedNumberWithCheck(totalQtyOut, NUMBER_OF_DECIMALS)}{" "}
              {footerValue}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MandateCertificate;
