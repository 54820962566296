import PropTypes from "prop-types";
import React from "react";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";
import {massBalanceType, mbStatus} from "../constants";

const ClosingPopover = ({open, type, periodStatus}) => {
  return (
    <Popover fade placement="top" target="mass-balance-close-btn" isOpen={open}>
      <PopoverHeader>
        {type === massBalanceType.TR &&
          periodStatus === mbStatus.OPEN &&
          "Close a trader only mass balance"}
        {type === massBalanceType.TR &&
          periodStatus === mbStatus.CLOSED &&
          "Re-open a trader only mass balance"}
        {type === massBalanceType.TRS &&
          periodStatus === mbStatus.OPEN &&
          "Close a trader with storage mass balance"}
        {type === massBalanceType.TRS &&
          periodStatus === mbStatus.CLOSED &&
          "Re-open a trader with storage mass balance"}
        {type === massBalanceType.CPP &&
          periodStatus === mbStatus.OPEN &&
          "To close a co-processing mass balance"}
        {type === massBalanceType.CPP &&
          periodStatus === mbStatus.CLOSED &&
          "To re-open a mass balance"}
      </PopoverHeader>
      <PopoverBody>
        {type === massBalanceType.TR && periodStatus === mbStatus.OPEN && (
          <>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              1. The mass balance period should have ended
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              2. All purchases are actualized
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              3. Outgoing transactions are allocated to purchases
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              4. Outgoing documents have been generated
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              5. The closing balance must be zero
            </div>
          </>
        )}
        {type === massBalanceType.TR && periodStatus === mbStatus.CLOSED && (
          <div className="h-[19.5px] font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]]">
            No audits have taken place
          </div>
        )}
        {type === massBalanceType.TRS && periodStatus === mbStatus.OPEN && (
          <>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              1. The mass balance period should have ended
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              2. Preceding mass balances have been closed
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              3. All purchases are actualized
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              4. Outgoing transactions are allocated to purchases
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              5. Outgoing documents have been generated
            </div>
          </>
        )}
        {type === massBalanceType.TRS && periodStatus === mbStatus.CLOSED && (
          <>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              1. Subsequent mass balances are open
            </div>
            <div className="font-roboto text-[13px] font-normal leading-[1.5] tracking-[0.2px] text-[rgba(17, 17, 17, 0.64)]">
              2. No audits have taken place
            </div>
          </>
        )}
        {type === massBalanceType.CPP && periodStatus === mbStatus.OPEN && (
          <>
            <div>1. The mass balance period should have ended</div>
            <div>2. Preceding mass balances have been closed</div>
            <div>3. All purchases are actualized</div>
            <div>
              4. Physical stock is within 0.5% tolerance of the closing position
            </div>
          </>
        )}
        {type === massBalanceType.CPP && periodStatus === mbStatus.CLOSED && (
          <>
            <div>1. Subsequent mass balances are open</div>
            <div>2. No audits have taken place</div>
          </>
        )}
      </PopoverBody>
    </Popover>
  );
};

ClosingPopover.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  periodStatus: PropTypes.string,
};

export default ClosingPopover;
