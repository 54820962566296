import {MbCertGenMutationAPI} from "graphql/MassBalance/Actions/bioLcMBGenerateOutgoingCertificateRequest";
import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import PropTypes from "prop-types";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import React, {useCallback, useMemo, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";
import appendParamsToUrl from "utils/helpers/appendParamsToUrl";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const SafPosGenDocConfirmModal = ({
  isVisibleModal,
  setModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisibleModal);
  const [isLoading, setIsLoading] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);

  const {appSetting, setAppSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const redirectUrl = appendParamsToUrl("/mass-balance", [
    siteReferenceData?.siteName,
    divisionCode,
    currentFilterValues?.mbBalanceTypeCode,
    currentFilterValues?.mbLocationGroupName,
    currentFilterValues?.mbPeriodName,
    currentFilterValues?.mbBalanceGroupName,
  ]);

  const isReceipientPPOSExists =
    rowData?.original?.outboundRecords?.isReceipientPPOSExist !== null;

  const canGenerateDocument = useMemo(() => {
    const allRecords = rowData?.original?.outboundAllRecords;
    if (allRecords) {
      return allRecords.every((record) => record?.status !== "AVAILABLE");
    }
    return false;
  }, [rowData]);

  let header;
  if (isReceipientPPOSExists && !canGenerateDocument) {
    header = "You cannot generate documents";
  } else {
    header = "Generate document";
  }

  let body;
  if (isReceipientPPOSExists && !canGenerateDocument) {
    body = (
      <p className="text-[13px] text-center text-gray-800 mb-0">
        The incoming document must be fully allocated before you can generate
        outgoing documents.
      </p>
    );
  } else if (isReceipientPPOSExists) {
    body = (
      <>
        <p className="text-[13px] text-center text-gray-800 mb-0">
          Please confirm you wish to generate an outgoing document.
        </p>
        <br />
        <p className="text-[13px] text-center text-gray-800 mb-0">
          Outgoing documents will be generated for all allocations made against
          the incoming purchase.
        </p>
        <br />
        <p className="text-[13px] text-center text-gray-800 mb-0">
          Please note - for any recipient that doesn’t have a Nabisy account a
          PoS (instead of PPoS) will be generated.
        </p>
      </>
    );
  } else {
    body = (
      <p className="text-[13px] text-center text-gray-800 mb-0">
        Please confirm you wish to generate an outgoing document.
      </p>
    );
  }

  const backToMassBalanceButton = useMemo(
    () => ({
      id: 1,
      text: "Back to mass balance",
      buttonClassName: "!text-[#111]",
      action: () => {
        setShowResponseModal(false);
        setModal("");
      },
    }),
    [setModal],
  );

  const handleSuccess = useCallback(() => {
    setResponseHeader("Document processing");
    setResponseBody(
      "Your documents are now being generated. We will alert you as soon as it’s ready.",
    );
    setResponseFooter([backToMassBalanceButton]);
    setShowResponseModal(true);
  }, [backToMassBalanceButton]);

  const handleFailure = useCallback(() => {
    let body;
    if (isReceipientPPOSExists) {
      body =
        "We are currently unable to retrieve documents from Nabisy. Please try again later. If the issue persists, contact our support team for assistance.";
    } else {
      body =
        "We are currently unable to generate document. Please try again later. If the issue persists, contact our support team for assistance.";
    }
    setResponseHeader("Documents cannot be generated");
    setResponseBody(body);
    setResponseFooter([backToMassBalanceButton]);
    setShowResponseModal(true);
  }, [backToMassBalanceButton, isReceipientPPOSExists]);

  const onSubmit = async () => {
    setIsLoading(true);
    const mbOutboundQtyEuId = !isReceipientPPOSExists
      ? rowData?.original?.outboundRecords?.mbOutboundQtyEuId
      : undefined;
    const mbInboundQtyEuId = isReceipientPPOSExists
      ? rowData?.original?.inboundRecord?.mbInboundQtyEuId
      : undefined;
    const {data} = await MbCertGenMutationAPI({
      event: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbOutboundQtyEuId,
        mbInboundQtyEuId,
      },
    });

    const statusCode = data?.bioLcMBGenerateOutgoingCertificate?.statusCode;

    if (statusCode === 500) {
      handleFailure();
    }
    if (statusCode === 201) {
      setAppSetting({
        ...appSetting,
        subscriptionDataGenerateCertificate: {
          transactionId:
            data?.bioLcMBGenerateOutgoingCertificate?.transactionId,
          country: siteReferenceData?.siteName,
          division: divisionCode,
          redirectTo: redirectUrl,
        },
      });
      handleSuccess();
      client.refetchQueries({
        include: [
          "bioLcGetMassBalanceTransactionData",
          "bioLcGetMassBalanceCounts",
        ],
      });
    }

    setIsModalVisible(false);
    setIsLoading(false);
  };

  let button1;
  if (isReceipientPPOSExists && !canGenerateDocument) {
    button1 = (
      <Button
        color="darker-tertiary"
        className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
        onClick={() => {
          onSubmit();
        }}
        disabled={isLoading}
      >
        {isLoading && <Spinner size="sm" className="btn-icon-prefix" />}
        Carry over
      </Button>
    );
  } else {
    button1 = (
      <Button
        color="darker-tertiary"
        className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
        onClick={() => {
          setIsModalVisible(false);
          setModal("");
        }}
      >
        Cancel
      </Button>
    );
  }

  let button2;
  if (isReceipientPPOSExists && !canGenerateDocument) {
    button2 = (
      <Button
        color="darker-tertiary"
        className="border-0 rounded-0 py-4 bg-transparent text-default"
        onClick={() => {
          setIsModalVisible(false);
          setModal("");
        }}
      >
        OK
      </Button>
    );
  } else {
    button2 = (
      <Button
        color="darker-tertiary"
        className="border-0 rounded-0 py-4 bg-transparent text-default"
        onClick={() => {
          onSubmit();
        }}
        disabled={isLoading}
      >
        {isLoading && <Spinner size="sm" className="btn-icon-prefix" />}
        Generate
      </Button>
    );
  }

  return (
    <>
      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered [&>div]:w-[330px]"
      >
        <ModalBody className="flex flex-col items-center w-[330px]">
          <p className="mb-[2px]">{header}</p>
          {body}
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">{button1}</div>
            <div className="col-6 d-grid">{button2}</div>
          </div>
        </ModalFooter>
      </Modal>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}
    </>
  );
};

SafPosGenDocConfirmModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  setModal: PropTypes.func,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default SafPosGenDocConfirmModal;
